.change-api {
  &__link {
    word-break: break-word;
  }

  &__button {
    display: block;
    border: none;
    outline: none;
    padding: 0;
    color: #1e84d7;
    font-size: 12px;
    margin-top: 8px;

    &:hover {
      text-decoration: none;
      color: #ff1f39;
      cursor: pointer;
    }
  }

  &__warning {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  &__content {
    width: 220px;
    background-color: #efedef;
    display: block;
    padding: 7px;
    position: fixed;
    bottom: 20px;
    left: 10px;
    font-size: 10px;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    text-align: left;
    opacity: 0.7;
    z-index: 20;
    border: #cdcdcd;

    &:hover {
      opacity: 1;
    }
  }

  &__controls {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;

    .elo-input,
    .field {
      width: 100%;
    }

    .field {
      margin-bottom: 0;
    }
  }
}
