@import 'src/ui-kit/styles/colors';

.quiz {
  font-family: var(--elo-font-sans-serif);

  .intro {
    font-size: 18px;
    font-weight: 500;
    color: $elo-black-semi;
  }

  .quiz-form {
    .question {
      margin-top: 20px;
      border-bottom: solid 1px $elo-grey-light-20;

      .question-title {
        font-size: 18px;
        font-weight: 500;
        color: $elo-black-semi;
        font-family: var(--elo-font-sans-serif);
      }

      .choices {
        padding: 10px 0 10px 0;
        font-size: 13px;

        .choice {
          margin-bottom: 8px;

          .fr-toolbar {
            .fr-btn-grp {
              margin: 0 3px 0 12px;
            }
          }
        }
      }
    }

    button {
      display: unset;
      font-size: 12px;
      padding: 9px 20px 7px;
      min-height: unset;
      border-radius: 17px;
      margin-top: 30px;
    }

    .elo-btn {
      margin-top: 30px;
    }
  }
}

.quiz-result-modal {
  .fields-container + .elo-modal__bottom-buttons {
    margin-top: 30px;
    & {
      button {
        font-size: 12px;
        min-width: 104px;
        padding: 9px 20px 7px 20px;
        min-height: unset;
        border-radius: 17px;

        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }

  &__description {
    font-family: var(--elo-font-sans-serif);
    font-size: 13px;
    line-height: 1.54;
    color: $elo-black-semi;
    margin-top: 3px;
  }

  &__result-icon {
    font-size: 72px;

    &.fa-smile {
      color: $elo-green;
    }

    &.fa-frown {
      color: $elo-red;
    }
  }

  .quiz-points {
    font-size: 36px;
    margin-top: 15px;
    font-weight: bold;

    &__gained {
      color: $elo-red;
    }

    &__gained--success {
      color: $elo-green;
    }

    &__separator {
      color: $elo-grey;
      margin: 0 2px;
    }

    &__all {
      color: $elo-grey-dark;
    }
  }

  &__message {
  }
}

.quiz-results {
  &__close {
    position: absolute;
    right: 0;
    top: 20px;
    i.fa-times {
      font-size: 25px;
      color: $elo-black-semi;
    }
  }

  > .quiz-results__questions {
    padding-left: 20px;
    margin-top: 20px;
  }

  &__questions {
    list-style-type: decimal;

    hr {
      margin-left: -20px;
    }
  }

  &__questions {
    font-size: 18px;
    font-weight: bold;
    color: $elo-black-semi;
  }

  &__choices {
    list-style-type: upper-alpha;
    font-weight: normal;
  }

  &__choice {
    position: relative;
    font-size: 13px;
    color: $elo-grey-dark;
    margin: 15px 0;

    &--answer {
      font-weight: bold;
      color: $elo-black-semi;
    }
  }

  .fa-times {
    color: $elo-red;
  }

  .fa-check {
    color: $elo-green;
  }

  .fa-times,
  .fa-check {
    position: absolute;
    left: -45px;
    font-size: 20px;
  }

  &__points-container {
    margin-left: 20px;
    font-size: var(--elo-font-size-xs);
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &__points {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    color: $elo-white;

    &--wrong {
      background-color: $elo-red;
    }

    &--correct {
      background-color: $elo-green;
    }
  }

  &__points-label {
    color: $elo-grey;
    margin-left: 10px;
  }

  &__choice-container {
    display: flex;
    align-items: center;
  }

  button {
    display: unset;
    font-size: 12px;
    padding: 9px 20px 7px;
    min-height: unset;
    border-radius: 17px;
  }
}
