@import 'src/ui-kit/styles/colors';

.card-widget-container {
  margin: 0 auto;
  max-width: 500px;
  border-radius: 6px;
  box-shadow: 0 2px 10px 1px rgba($elo-black, 0.15);

  .card-header {
    border-radius: 6px 6px 0 0;
    position: relative;
    height: 57px;
    padding: 0 19px 0;
    background-color: $elo-white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      opacity: 1;
      height: 20px;
      width: auto;
    }
  }

  .card-widget {
    background-color: $elo-grey-light-20;
    font-size: 13px;
    padding: 40px 15px;
    position: relative;

    #card-errors {
      color: $elo-red-60;
    }
  }
}
