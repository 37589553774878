@import 'src/ui-kit/styles/colors';

@-webkit-keyframes circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-spinner {
  &__loader,
  &__loader::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  &__loader {
    margin: 30px auto;
    font-size: 6px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid var(--spinner-border-color);
    border-right: 1.1em solid var(--spinner-border-color);
    border-bottom: 1.1em solid var(--spinner-border-color);
    border-left: 1.1em solid var(--spinner-solid-border-color);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: circle 1.1s infinite linear;
    animation: circle 1.1s infinite linear;

    &--10-margin {
      margin: 10px auto;
    }

    &--15-margin {
      margin: 15px auto;
    }

    &--no-margin {
      margin: 0 auto;
    }

    &--x-small {
      font-size: 2px;
    }

    &--small {
      font-size: 3px;
    }

    &--medium {
      font-size: 7px;
    }

    &--large {
      font-size: 12px;
    }

    &--white {
      border-top: 1.1em solid rgba($elo-white, 0.2);
      border-right: 1.1em solid rgba($elo-white, 0.5);
      border-bottom: 1.1em solid rgba($elo-white, 0.7);
      border-left: 1.1em solid $elo-white;
    }
  }

  &__medium-container {
    max-width: 720px;
    width: 100%;
  }
}
