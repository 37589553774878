@import 'src/ui-kit/styles/colors';

$light-blue: #66c7e2;
$light-orange: #feffb4;

.comments-nav {
  .nav-item .nav-link {
    padding: 10px 30px 5px 30px;

    &.active {
      border-color: $elo-grey-light $elo-grey-light $elo-white;
    }
  }
}

.comments-form {
  margin-top: 20px;

  & > span {
    font-family: var(--elo-font-sans-serif);
    font-size: 13px;
    color: $elo-grey-dark;
    margin-left: 60px;
  }

  button {
    margin: 30px 10px;
    max-width: calc(100% - 20px);

    i {
      font-size: 17px;
      vertical-align: middle;
      margin-right: 8px;
    }
  }

  .comment-form {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .comment-text-area {
    position: relative;
    width: 100%;
    padding: 5px 0 0;
    z-index: 0;
  }

  .mobile-comment-form {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 22;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: $elo-white;
    box-shadow: 0 -2px 10px 0 rgba($elo-black, 0.2);

    .mobile-comment-container {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .round-image-36 {
        margin-right: 20px;
        align-self: baseline;
      }

      .fr-toolbar .fr-command.fr-btn i {
        margin: 8px 0;
      }
    }

    .mobile-post-button {
      align-self: flex-end;

      button {
        margin: 0;
        height: 40px;
        text-transform: unset;
      }
    }

    .fa-times-circle {
      font-size: 20px;
      color: $elo-blue;
      position: absolute;
      right: 4px;
      top: -8px;
    }
  }

  .outline-grey {
    margin-right: 10px;
  }

  .comment-buttons-container {
    display: flex;
    justify-content: flex-end;
  }
}

.comment-list {
  .comment-container {
    display: flex;
    justify-content: left;
    padding: 25px 0;
    border-top: solid 1px $elo-grey-light-20;

    .sender {
      &--pointer {
        .round-image-36 {
          cursor: pointer;
        }
      }
    }

    .comment-content {
      width: 100%;
      padding: 0 0 0 20px;

      .comment-body-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .comment-author {
          .comment-name {
            font-family: var(--elo-font-sans-serif);
            font-size: 13px;
            color: $elo-black-semi;
            margin-right: 25px;
            cursor: pointer;
          }

          .comment-time {
            font-family: var(--elo-font-sans-serif);
            font-size: var(--elo-font-size-xs);
            color: $elo-grey;
          }
        }

        i {
          color: $elo-grey-40;
          font-size: 17px;

          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }

        .own-comment-buttons i {
          color: $elo-blue;
          margin-left: 20px;
          font-size: 16px;
        }
      }

      .comment-body {
        font-family: Montserrat Reg;
        font-size: 13px;
        color: $elo-black-semi;
        word-break: break-word;

        &__container {
          .field {
            margin-top: 10px;
          }

          .elo-btn-container {
            margin-top: 20px;
          }

          @media (max-width: 991px) {
            .fr-toolbar .fr-command.fr-btn i {
              margin: 8px 0;
            }
          }
        }
        p {
          margin-bottom: 0;
        }
      }
    }

    .deleted-comment-placeholder {
      font-family: Montserrat Reg;
      font-size: 13px;
      color: $elo-black-semi;
    }
  }

  .deleted-comment {
    display: none;
  }

  @keyframes highlighting {
    0% {
      background-color: $elo-white;
    }
    40% {
      background-color: $elo-white;
    }
    50% {
      background-color: $light-orange;
    }
    100% {
      background-color: $elo-white;
    }
  }

  .highlight-comment {
    animation: highlighting 4s;
  }

  .subcomments {
    margin-left: 35px;
  }

  .reply-form {
    margin-left: 30px;
    border-top: solid 1px $elo-grey-light-20;
    margin-top: 0;
    padding-top: 20px;
  }

  &--with-bottom-padding {
    padding-bottom: 110px;
  }
}

.discussion {
  display: inline-block;
  font-family: var(--elo-font-sans-serif);
  font-size: 18px;
  font-weight: 500;
  color: $elo-black-semi;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .lesson-comments {
    margin: 15px !important;
  }
}
