@import 'src/ui-kit/styles/colors';

.admin-mode {
  width: 200px;
  background-color: $elo-grey-light-40;
  display: block;
  padding: 7px;
  position: fixed;
  bottom: 20px;
  right: 10px;
  font-size: 10px;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba($elo-black, 0.5);
  text-align: left;
  opacity: 0.7;
  z-index: 20;
  border: $elo-grey-40;

  &:hover {
    opacity: 1;
  }

  .back-to-admin {
    color: $elo-blue;

    &:hover {
      text-decoration: none;
      color: $elo-red-60;
      cursor: pointer;
    }
  }
}
