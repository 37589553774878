@import 'src/ui-kit/styles/colors';

.fields-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--right {
    justify-content: right;
  }

  .field:last-child {
    margin-bottom: 30px;
  }

  &--bottom-5 {
    margin-bottom: 5px;
  }

  &--bottom-20 {
    margin-bottom: 20px;

    &--without-last {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &--bottom-25 {
    margin-bottom: 25px;

    &--without-last {
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }

  &--bottom-30 {
    margin-bottom: 30px;
  }

  &--bottom-10 {
    margin-bottom: 10px;
  }

  &--top-20 {
    margin-top: 20px;
  }

  &--evenly {
    & > div {
      flex-grow: 1;
      flex-basis: 0;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-auto {
      & > div {
        flex-grow: 1;
        flex-basis: 20%;
        margin-right: 30px;

        @media (max-width: 1500px) {
          flex-basis: auto;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &--long {
    > .field {
      width: 100%;
    }
  }

  &--wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &--row-reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  &--with-error {
    .field {
      &:first-child {
        &.field--error {
          margin-right: 3%;
          width: 82%;
          & + .field {
            width: 15%;
          }
        }
      }
      &:last-child {
        &.field--error {
          margin-left: 3%;
          width: 82%;

          & ~ .field {
            width: 15%;
          }
        }
      }
    }
  }

  &--long-and-short {
    .field {
      &:first-child {
        width: 82%;
      }
      &:last-child {
        width: 15%;
        &.field--error {
          margin-left: 3%;
          width: 50%;
        }
      }
    }

    &--normal {
      .field {
        &:first-child {
          width: 82.8%;
        }
      }
    }
  }

  &--short-and-long {
    .field {
      &:first-child {
        width: 15%;
        &.field--error {
          margin-right: 3%;
          width: 33%;
        }
      }
      &:last-child {
        width: 82%;
      }
    }
  }

  &__grow-1 {
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__grow-2 {
    flex-grow: 2;
    flex-basis: 0;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &--centered {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  &--bold {
    .elo-select-field {
      &__option,
      &__value-container,
      &__placeholder {
        font-family: var(--elo-font-sans-serif), serif;
        text-transform: uppercase;
      }
    }
  }

  &--two-fields {
    .field {
      width: 48%;
    }

    @media (max-width: 576px) {
      flex-wrap: wrap;

      .field,
      div {
        width: 100%;
      }

      &.fields-container--without-margins {
        .field,
        div {
          width: 100%;
          margin-bottom: 30px !important;
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  &__grow-3 {
    flex-grow: 2;
    flex-basis: 0;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &--left-align {
    display: flex;
    justify-content: flex-start;
    line-height: 1;

    &--without-line-height {
      line-height: unset;
    }

    @media (max-width: 576px) {
      flex-wrap: wrap;
    }
  }

  &--center-align {
    display: flex;
    justify-content: center;
  }

  &--top-align {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &--bottom-align {
    display: flex;
    align-items: flex-end;
  }

  &--dashed {
    padding: 20px;
    border-radius: 6px;
    border: dashed 1px rgba($elo-black, 0.129);

    &--without-padding {
      padding: 0;
    }

    .field,
    .fields-container__vertical-separator {
      margin-bottom: 0;
    }
  }

  &--right-align {
    display: flex;
    justify-content: flex-end;

    .elo-btn:not(:last-child),
    .elo-button:not(:last-child) {
      margin-right: 15px;
    }
  }

  &__vertical-separator {
    margin: 0 30px 30px;
    color: $elo-grey-light;
    font-size: 24px;
    line-height: 1;
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    position: relative;
    height: auto;
    align-self: stretch;

    &::before {
      content: '';
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      left: 0;
      border-right: 1px solid $elo-grey-light;
    }

    &--no-margin {
      margin-bottom: 0;
    }
  }

  &__horisontal-separator {
    width: 100%;
    height: 1px;
    background-color: $elo-grey-light;
    margin-bottom: 30px;

    &--full-margin {
      margin-bottom: 30px;
    }

    &--with-top-margin {
      margin-top: 30px;
    }

    &--no-margin {
      margin-bottom: 0;
    }

    &--top-margin {
      margin-top: 30px;
    }

    &--dark {
      background-color: $elo-grey-40;
    }

    &--bottom-20 {
      margin-bottom: 20px;
    }
  }

  &--with-two-separators {
    margin: 30px 0;
  }

  &__text-with-separators {
    margin: 0 16px;
    color: $elo-grey;
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
  }

  &--without-margins {
    .field {
      margin-bottom: 0 !important;
    }

    .elo-select {
      margin-bottom: 0;
    }

    div.elo-toggle {
      margin-bottom: 0;
    }

    .fields-container__vertical-separator {
      margin-bottom: 0;
    }
  }

  &--with-right-margins .field {
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }

  &--without-right-margin {
    margin-right: 0;

    .field {
      margin-right: 0;
    }
  }

  &--with-top-margins .field {
    margin-top: 30px;
  }

  &--margin-bottom-10 .field {
    margin-bottom: 10px;
  }

  &--margin-bottom-15 .field {
    margin-bottom: 15px;
  }

  .elo-toggle {
    margin-bottom: 30px;
  }

  .checkbox-field__label {
    margin-bottom: 0;
  }

  &__left-text {
    justify-content: flex-start;

    span {
      margin-right: 6px;
    }
    button {
      margin-right: 6px;
    }
  }

  &--20-margin-right {
    margin-right: 20px;
  }

  &--25-percentage {
    width: 25%;
    .field {
      width: 100%;
    }
  }

  &--30-percentage {
    width: 31%;
    .field {
      width: 100%;
    }
  }

  &--48-percentage {
    width: 48%;
    .field {
      width: 100%;
    }
  }

  &--49-percentage {
    width: 49%;
    .field {
      width: 100%;
    }
  }

  &--60-percentage {
    width: 61%;
    .field {
      width: 100%;
    }
  }

  &--65-percentage {
    @media (max-width: 1792px) {
      max-width: 53%;
    }
    @media (max-width: 1550px) {
      max-width: 65%;
    }
    .field {
      width: 100%;
    }
  }

  &--mobile-100 {
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  &--relative {
    position: relative;
  }

  &--absolute-right {
    position: absolute;
    right: 0;
  }

  & + .elo-modal__bottom-buttons {
    margin-top: 0;
  }

  &--one-line {
    align-items: flex-end;
    padding-top: 30px;
    padding-bottom: 20px;

    .field {
      margin-bottom: 0;
    }

    .elo-btn {
      margin-bottom: 5px;
    }
  }

  &--column {
    display: flex;
    flex-direction: column;

    &--top-30 {
      @media (max-width: 1500px) {
        margin-top: 30px;
      }
    }
  }

  &--max-550 {
    width: 100%;
    max-width: 550px;
    margin-right: 30px;
  }

  &--full-width {
    width: 100%;
  }

  &--padding-right-20 {
    padding-right: 20px;
  }

  .field-timer {
    width: min-content;

    input {
      width: 130px;
    }
  }
}
