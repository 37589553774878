@import 'src/ui-kit/styles/colors';

.payment_cancel-wrap {
  border-radius: 4px;
  background-color: $elo-white;
  padding: 30px;
  box-shadow: 0 5px 10px 0 rgba($elo-black, 0.1);
  position: relative;
}

.payment_cancel-title {
  font-family: var(--elo-font-sans-serif);
  font-size: 24px;
  font-weight: bold;
  line-height: 1.42;
  letter-spacing: normal;
  color: $elo-black-semi;
}

.payment_cancel-sub-title {
  font-family: var(--elo-font-sans-serif);
  font-size: 13px;
  line-height: 3.38;
  letter-spacing: normal;
  color: $elo-grey-dark;
}
