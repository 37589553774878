@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-500.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-500.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-600.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-600.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-900.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-900.woff') format('woff');
}
