.date-time-field {
  &__control-wrapper {
    position: relative;
    width: 100%;

    .date-time-field__control {
      box-sizing: border-box;
      padding: 13px 18px 13px;
      height: 44px;
      border: 1px solid var(--field-border-color);
      border-radius: 4px;
      background-color: var(--field-bg-color);
      line-height: 13px;

      // specific selector only for Chrome 29+
      @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
        line-height: 44px;
        //font-size: revert;
        font-size: 14px;
      }
    }

    .date-time-field__control:focus:not(&--error) {
      border: 1px solid var(--field-border-color-focus);
    }

    .date-time-field__control--error {
      border: 1px solid var(--field-border-color-error);
    }

    // Override Bootstrap styles
    .date-time-field__control::placeholder {
      color: var(--field-placeholder-font-color) !important;
      font-size: 14px !important;
      padding-top: 1px !important;
    }

    .fa-calendar-alt {
      position: absolute;
      top: 13px;
      right: 15px;
      font-size: 17px;
      color: var(--field-icon-color);
    }
  }

  .rdtPicker {
    color: var(--datepicker-font-color);

    .rdtTimeToggle,
    .rdtBtn,
    .rdtDay,
    .rdtMonth,
    .rdtYear,
    .rdtSwitch,
    .rdtPrev,
    .rdtNext {
      &:not(.rdtDisabled, .rdtActive):hover {
        background-color: var(--datepicker-hover-color);
      }
    }

    th {
      border-bottom: none;
      text-transform: uppercase;
    }

    th.rdtPrev {
      font-size: 25px;
      color: var(--datepicker-arrow-color);
    }

    th.rdtNext {
      font-size: 25px;
      color: var(--datepicker-arrow-color);
    }

    th.rdtSwitch {
      padding-top: 4px;
      font-family: var(--elo-font-sans-serif);
      font-size: 12px;
      font-weight: 500;
    }

    th.dow {
      font-family: var(--elo-font-sans-serif);
      font-size: 10px;
    }

    td.rdtDay {
      font-size: 12px;

      &:hover {
        border-radius: 20px;
      }
    }

    td.rdtActive {
      background-color: var(--datepicker-active-color);
      color: var(--datepicker-font-color);
      text-shadow: none;
      border-radius: 20px;
    }

    td.rdtToday::before {
      border-bottom-color: var(--datepicker-active-color);
    }
  }

  &--rdt-100 {
    .rdtPicker {
      width: 100%;
    }
  }

  &--mb-10 {
    margin-bottom: 10px;
  }
}
