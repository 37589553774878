@import './colors';

input {
  outline: 0;
}

.field {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 30px;

  &--no-margin {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__label {
    display: flex;
    align-items: flex-start;
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    margin-bottom: 7px;
    color: $elo-grey-dark;
    line-height: 1;
    font-size: 13px;

    .popover-info {
      vertical-align: middle;
    }

    .popover-info-container {
      margin-top: -4px;
      margin-bottom: -2px;
    }

    .fa-apple,
    .fa-google-play {
      font-size: 18px;
      color: $elo-grey-dark;
      margin-right: 5px;
    }

    .fa-google-play {
      font-size: 15px;
    }

    &--margin-bottom {
      margin-bottom: 30px;
    }

    &--margin-right {
      margin-right: 10px;
    }
  }

  .popover-info-container {
    height: 19px;
  }

  &__error {
    height: 15px;
    display: flex;
    margin-left: auto;
    color: var(--field-error-font-color);
    font-family: var(--elo-font-sans-serif);
    font-size: 12px;
    font-weight: normal;
    line-height: 1;

    .fas {
      font-size: 16px;
      margin-left: 7px;
      margin-top: -3px;
    }

    &--container {
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__control {
    width: 100%;
    font-family: var(--elo-font-sans-serif);
    color: var(--field-font-color);

    &::placeholder {
      padding-top: 12px;
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;

    .popover-info {
      pointer-events: auto;
      cursor: pointer;
    }
  }

  &--readonly {
    opacity: 0.5;
  }

  &--required {
    .field__label,
    .checkbox-field__label {
      span:first-child::after {
        content: '*';
        margin: -1px 2px;
        font-size: 15px;
        color: var(--field-required-font-color);
      }
    }
  }

  &--with-min-width {
    min-width: 150px;
  }

  &--error-height-auto {
    .field__error {
      height: auto;
    }
  }
}
