// This file is auto generated
// Do not make any changes here
:root {
  --elo-heading-h1-font-style: normal;
  --elo-heading-h1-font-weight: 600;
  --elo-heading-h1-font-size: 36px;
  --elo-heading-h1-line-height: 1.1;
  --elo-heading-h1-font-family: Inter, sans-serif;
  --elo-heading-h1: var(--elo-heading-h1-font-style) var(--elo-heading-h1-font-weight) var(--elo-heading-h1-font-size) /
    var(--elo-heading-h1-line-height) var(--elo-heading-h1-font-family);
  --elo-heading-h2-font-style: normal;
  --elo-heading-h2-font-weight: 400;
  --elo-heading-h2-font-size: 32px;
  --elo-heading-h2-line-height: 1.1;
  --elo-heading-h2-font-family: Inter, sans-serif;
  --elo-heading-h2: var(--elo-heading-h2-font-style) var(--elo-heading-h2-font-weight) var(--elo-heading-h2-font-size) /
    var(--elo-heading-h2-line-height) var(--elo-heading-h2-font-family);
  --elo-heading-h3-font-style: normal;
  --elo-heading-h3-font-weight: 400;
  --elo-heading-h3-font-size: 28px;
  --elo-heading-h3-line-height: 1.2;
  --elo-heading-h3-font-family: Inter, sans-serif;
  --elo-heading-h3: var(--elo-heading-h3-font-style) var(--elo-heading-h3-font-weight) var(--elo-heading-h3-font-size) /
    var(--elo-heading-h3-line-height) var(--elo-heading-h3-font-family);
  --elo-heading-h4-font-style: normal;
  --elo-heading-h4-font-weight: 400;
  --elo-heading-h4-font-size: 24px;
  --elo-heading-h4-line-height: 1.2;
  --elo-heading-h4-font-family: Inter, sans-serif;
  --elo-heading-h4: var(--elo-heading-h4-font-style) var(--elo-heading-h4-font-weight) var(--elo-heading-h4-font-size) /
    var(--elo-heading-h4-line-height) var(--elo-heading-h4-font-family);
  --elo-heading-h5-font-style: normal;
  --elo-heading-h5-font-weight: 400;
  --elo-heading-h5-font-size: 20px;
  --elo-heading-h5-line-height: 1.2;
  --elo-heading-h5-font-family: Inter, sans-serif;
  --elo-heading-h5: var(--elo-heading-h5-font-style) var(--elo-heading-h5-font-weight) var(--elo-heading-h5-font-size) /
    var(--elo-heading-h5-line-height) var(--elo-heading-h5-font-family);
  --elo-heading-h6-font-style: normal;
  --elo-heading-h6-font-weight: 400;
  --elo-heading-h6-font-size: 18px;
  --elo-heading-h6-line-height: 1.4;
  --elo-heading-h6-font-family: Inter, sans-serif;
  --elo-heading-h6: var(--elo-heading-h6-font-style) var(--elo-heading-h6-font-weight) var(--elo-heading-h6-font-size) /
    var(--elo-heading-h6-line-height) var(--elo-heading-h6-font-family);
  --elo-body-base-regular-font-style: normal;
  --elo-body-base-regular-font-weight: 400;
  --elo-body-base-regular-font-size: 16px;
  --elo-body-base-regular-line-height: 1.4;
  --elo-body-base-regular-font-family: Inter, sans-serif;
  --elo-body-base-regular: var(--elo-body-base-regular-font-style) var(--elo-body-base-regular-font-weight)
    var(--elo-body-base-regular-font-size) / var(--elo-body-base-regular-line-height)
    var(--elo-body-base-regular-font-family);
  --elo-body-base-medium-font-style: normal;
  --elo-body-base-medium-font-weight: 500;
  --elo-body-base-medium-font-size: 16px;
  --elo-body-base-medium-line-height: 1.4;
  --elo-body-base-medium-font-family: Inter, sans-serif;
  --elo-body-base-medium: var(--elo-body-base-medium-font-style) var(--elo-body-base-medium-font-weight)
    var(--elo-body-base-medium-font-size) / var(--elo-body-base-medium-line-height)
    var(--elo-body-base-medium-font-family);
  --elo-body-base-semibold-font-style: normal;
  --elo-body-base-semibold-font-weight: 600;
  --elo-body-base-semibold-font-size: 16px;
  --elo-body-base-semibold-line-height: 1.4;
  --elo-body-base-semibold-font-family: Inter, sans-serif;
  --elo-body-base-semibold: var(--elo-body-base-semibold-font-style) var(--elo-body-base-semibold-font-weight)
    var(--elo-body-base-semibold-font-size) / var(--elo-body-base-semibold-line-height)
    var(--elo-body-base-semibold-font-family);
  --elo-body-base-bold-font-style: normal;
  --elo-body-base-bold-font-weight: 900;
  --elo-body-base-bold-font-size: 16px;
  --elo-body-base-bold-line-height: 1.4;
  --elo-body-base-bold-font-family: Inter, sans-serif;
  --elo-body-base-bold: var(--elo-body-base-bold-font-style) var(--elo-body-base-bold-font-weight)
    var(--elo-body-base-bold-font-size) / var(--elo-body-base-bold-line-height) var(--elo-body-base-bold-font-family);
  --elo-body-sm-regular-font-style: normal;
  --elo-body-sm-regular-font-weight: 400;
  --elo-body-sm-regular-font-size: 14px;
  --elo-body-sm-regular-line-height: 1.5;
  --elo-body-sm-regular-font-family: Inter, sans-serif;
  --elo-body-sm-regular: var(--elo-body-sm-regular-font-style) var(--elo-body-sm-regular-font-weight)
    var(--elo-body-sm-regular-font-size) / var(--elo-body-sm-regular-line-height) var(--elo-body-sm-regular-font-family);
  --elo-body-sm-medium-font-style: normal;
  --elo-body-sm-medium-font-weight: 500;
  --elo-body-sm-medium-font-size: 14px;
  --elo-body-sm-medium-line-height: 1.5;
  --elo-body-sm-medium-font-family: Inter, sans-serif;
  --elo-body-sm-medium: var(--elo-body-sm-medium-font-style) var(--elo-body-sm-medium-font-weight)
    var(--elo-body-sm-medium-font-size) / var(--elo-body-sm-medium-line-height) var(--elo-body-sm-medium-font-family);
  --elo-body-sm-semibold-font-style: normal;
  --elo-body-sm-semibold-font-weight: 600;
  --elo-body-sm-semibold-font-size: 14px;
  --elo-body-sm-semibold-line-height: 1.5;
  --elo-body-sm-semibold-font-family: Inter, sans-serif;
  --elo-body-sm-semibold: var(--elo-body-sm-semibold-font-style) var(--elo-body-sm-semibold-font-weight)
    var(--elo-body-sm-semibold-font-size) / var(--elo-body-sm-semibold-line-height)
    var(--elo-body-sm-semibold-font-family);
  --elo-body-sm-bold-font-style: normal;
  --elo-body-sm-bold-font-weight: 900;
  --elo-body-sm-bold-font-size: 14px;
  --elo-body-sm-bold-line-height: 1.5;
  --elo-body-sm-bold-font-family: Inter, sans-serif;
  --elo-body-sm-bold: var(--elo-body-sm-bold-font-style) var(--elo-body-sm-bold-font-weight)
    var(--elo-body-sm-bold-font-size) / var(--elo-body-sm-bold-line-height) var(--elo-body-sm-bold-font-family);
  --elo-body-xs-regular-font-style: normal;
  --elo-body-xs-regular-font-weight: 400;
  --elo-body-xs-regular-font-size: 12px;
  --elo-body-xs-regular-line-height: 1.6;
  --elo-body-xs-regular-font-family: Inter, sans-serif;
  --elo-body-xs-regular: var(--elo-body-xs-regular-font-style) var(--elo-body-xs-regular-font-weight)
    var(--elo-body-xs-regular-font-size) / var(--elo-body-xs-regular-line-height) var(--elo-body-xs-regular-font-family);
  --elo-body-xs-medium-font-style: normal;
  --elo-body-xs-medium-font-weight: 500;
  --elo-body-xs-medium-font-size: 12px;
  --elo-body-xs-medium-line-height: 1.6;
  --elo-body-xs-medium-font-family: Inter, sans-serif;
  --elo-body-xs-medium: var(--elo-body-xs-medium-font-style) var(--elo-body-xs-medium-font-weight)
    var(--elo-body-xs-medium-font-size) / var(--elo-body-xs-medium-line-height) var(--elo-body-xs-medium-font-family);
  --elo-body-xs-semibold-font-style: normal;
  --elo-body-xs-semibold-font-weight: 600;
  --elo-body-xs-semibold-font-size: 12px;
  --elo-body-xs-semibold-line-height: 1.6;
  --elo-body-xs-semibold-font-family: Inter, sans-serif;
  --elo-body-xs-semibold: var(--elo-body-xs-semibold-font-style) var(--elo-body-xs-semibold-font-weight)
    var(--elo-body-xs-semibold-font-size) / var(--elo-body-xs-semibold-line-height)
    var(--elo-body-xs-semibold-font-family);
  --elo-body-xs-bold-font-style: normal;
  --elo-body-xs-bold-font-weight: 900;
  --elo-body-xs-bold-font-size: 12px;
  --elo-body-xs-bold-line-height: 1.6;
  --elo-body-xs-bold-font-family: Inter, sans-serif;
  --elo-body-xs-bold: var(--elo-body-xs-bold-font-style) var(--elo-body-xs-bold-font-weight)
    var(--elo-body-xs-bold-font-size) / var(--elo-body-xs-bold-line-height) var(--elo-body-xs-bold-font-family);
}
