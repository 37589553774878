@import 'src/ui-kit/styles/colors';

.popover {
  border: 0;
  .popover-body {
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    font-size: 12px;
    color: $elo-grey;
    font-style: normal;
    padding: 15px;
  }
}
.popover-info {
  margin-left: 6px;
  outline: none;
}

svg:hover,
svg:active {
  .popover-info.popover-info-circle {
    fill: $elo-grey;
  }
}

.tooltip {
  .tooltip-inner {
    background-color: var(--tooltip-bg-color);
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    font-size: 12px;
    color: var(--tooltip-font-color);
    font-style: normal;
    padding: 15px;
  }
  &.bs-tooltip-left .arrow::before {
    border-left-color: var(--tooltip-bg-color) !important;
  }
  &.bs-tooltip-right .arrow::before {
    border-right-color: var(--tooltip-bg-color) !important;
  }
  &.bs-tooltip-top .arrow::before {
    border-top-color: var(--tooltip-bg-color) !important;
  }
  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--tooltip-bg-color) !important;
  }
}

.collapse-block {
  .cb-header {
    border-radius: 4px;
    background-color: $elo-grey-light-40;
    padding: 15px 25px;
    .checkbox-switch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        align-items: center;
        display: flex;
      }
      .switcher-span {
        margin-right: 0;
      }
      label {
        margin-bottom: 0;
      }
      span {
        font-family: var(--elo-font-sans-serif);
        font-weight: 400;
        font-size: var(--elo-font-size-sm);
        text-align: left;
        color: $elo-grey-dark;
      }
      .switcher-input:checked ~ label {
        background: $elo-green;
      }
    }
  }
  .cb-body {
    border-radius: 0 0 4px 4px;
    background-color: $elo-grey-light-10;
    border: solid 1px $elo-grey-light;
    padding: 20px 25px 30px 25px;
  }
  &.disabled {
    .cb-header {
      opacity: 0.6;
    }
  }
  &.active {
    .cb-header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.book-option-init {
  display: inline-block;
  background: $elo-white;
  border: 1px solid $elo-grey-40;
  padding: 8px 12px;
}

.book-option-wrap {
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
  font-family: var(--elo-font-sans-serif);
  font-weight: 400;
  color: $elo-black-semi;
  .book-option-img {
    height: 84px;
    width: 84px;
    border-radius: 6px;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: inline-block;
  }
  .book-option-name {
    font-size: 16px;
    font-weight: 500;
  }
  .book-option-descr {
    font-size: 12px;
    color: rgba($elo-black, 0.56);
  }
  .book-option-actions {
    margin-top: 10px;
    .bo-free,
    .bo-price {
      margin-top: 10px;
      font-size: 10px;
      font-weight: 500;
      color: $elo-grey;
    }
  }
}

.checkbox-switch {
  display: flex;
  align-items: baseline;
  .centered {
    display: inherit;
    align-items: flex-end;
  }
}
