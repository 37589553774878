@import 'src/ui-kit/styles/colors';

.elo-pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  font-size: 14px;
  font-family: var(--elo-font-sans-serif);
  flex-wrap: wrap;

  &--navigation-only {
    justify-content: flex-end;
  }

  &--disabled {
    display: none;
  }

  &__pages-navigation {
    display: flex;

    &--disabled {
      display: none;
    }
  }

  &__items-number {
    min-width: 200px;
    margin: 0;
    color: $elo-black-semi;
    text-align: left;
  }
}

.elo-pagination {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  margin-left: 29px;
  padding: 0;

  &__page {
    margin-right: 4px;
    border-radius: 4px;

    &:hover {
      background-color: var(--elo-light-grey);
    }

    &--active {
      background-color: $elo-blue !important;
    }
  }

  &__page:last-child {
    margin-right: 0;
  }

  a.elo-pagination__link {
    color: $elo-blue;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    padding: 0 5px;
    height: 30px;
    outline: none;
    color: $elo-blue;
    border: 1px solid rgba($elo-black, 0.082);
    border-radius: 4px;
    text-decoration: none;

    &--active {
      color: $elo-white !important;
    }

    &:hover {
      text-decoration: none;
    }

    &:visited {
      color: $elo-black-semi;
    }

    &--next {
      width: 45px;
      font-size: 17px;
    }
  }
}

.elo-page-to-go-container {
  display: flex;
  height: 30px;
  margin-left: 29px;

  &__label {
    display: flex;
    align-items: center;
    margin-right: 4px;
    color: $elo-grey-dark;
  }

  &__input.elo-page-to-go-container__input--page {
    margin-right: 4px;
    width: 60px;
    height: 30px;
    border: 1px solid rgba($elo-black, 0.082);
    border-radius: 4px;
    color: $elo-black-semi !important;
    text-align: center;
  }

  &__button {
    display: flex;
    justify-content: center;
    height: 30px;
    color: var(--pagination-button-font-color);
    border: 1px solid var(--pagination-button-bg-color);
    border-radius: 4px;
    background-color: var(--pagination-button-bg-color);
    align-items: center;

    &:hover {
      background-color: var(--pagination-button-bg-color-hover);
    }

    &:active {
      background-color: var(--pagination-button-bg-color-active);
    }
  }
}

.elo-show-items {
  display: flex;
  align-items: center;

  &.elo-select-container {
    .elo-select-field {
      width: 80px !important;

      &__value-container {
        padding: 0;
        display: flex;
        justify-content: center;
      }

      &__dropdown-indicator {
        padding: 0;

        .fa-sort-down {
          padding: 0 6px 7px;
        }

        &--arrow-up-position {
          padding: 8px 6px 4px;
        }
      }

      &__control {
        min-height: 30px;
        border-color: rgba($elo-black, 0.082);
      }

      &__single-value {
        top: 55%;
        margin-left: 0;
        margin-right: 0;
      }

      &__option {
        padding: 8px;
        text-align: center;
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    margin-right: 4px;
    color: $elo-grey-dark;
  }

  .Select-control {
    height: 30px;
    border: 1px solid rgba($elo-black, 0.082) !important;
    box-shadow: none !important;

    .Select-placeholder {
      line-height: 26px;
    }

    .Select-value {
      line-height: 30px;
      padding-left: 0;
      padding-right: 15px;

      .Select-value-label {
        line-height: 26px;
      }
    }

    .Select-input {
      height: 26px;
    }
  }
}

@media (max-width: 925px), (min-width: 1000px) and (max-width: 1140px) {
  .elo-pagination-container {
    &__pages-navigation {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .elo-pagination,
  .elo-show-items,
  .elo-page-to-go-container {
    margin-left: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .elo-pagination-container {
    flex-direction: column;

    &__pages-navigation {
      align-items: center;
    }

    &__items-number {
      margin-bottom: 10px;
      text-align: center;
    }
  }
}
