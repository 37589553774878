@import '../../../styles/colors';
@import '../../../styles/fonts';

.collapsible-list-container {
  .collapsible-item-container:first-child {
    border-top: solid 1px $elo-grey-light;
  }

  .collapsible-subitem-container:last-child {
    border-bottom: 'none';
  }
}

.collapsible-item-container,
.collapsible-subitem-container {
  border-bottom: solid 1px $elo-grey-light;
  padding: 20px 0 0;
}

.collapsible-subitem-container {
  display: flex;
  margin: 0 30px 0 65px;
  padding: 20px 0;
}

.collapsible-subitems-list-container {
  background-color: $elo-grey-light-20;
  margin-left: -30px;
  width: calc(100% + 60px);
}

.collapsible-item {
  width: 100%;
  display: flex;
}

.collapsible-item,
.collapsible-subitem-container {
  font-family: var(--elo-font-sans-serif);
  font-weight: 400;
  font-size: 12px;
  color: $elo-grey-dark;

  .checkbox-field {
    width: 20px;
    height: 20px;
  }
}

.collapsible-item-controls,
.collapsible-subitem {
  margin-left: 15px;
}

.collapsible-subitem .collapsible-description {
  margin-bottom: 0;
}

.collapsible-item-name {
  font-family: var(--elo-font-sans-serif);
  font-weight: 500;
  font-size: var(--elo-font-size-lg);
  color: $elo-black-semi;
  line-height: 1.4;
}

.collapsible-subitem-name {
  font-family: var(--elo-font-sans-serif);
  font-weight: 500;
  font-size: var(--elo-font-size-sm);
  color: $elo-black-semi;
  text-transform: uppercase;
}

.collapsible-description {
  margin-bottom: 7px;
  font-family: var(--elo-font-sans-serif);
  font-weight: 400;
  font-size: 12px;
  color: $elo-grey-dark;
}

.collapsible-details-btn {
  background: none;
  border: 'none';
  padding: 0;
  line-height: 1;
}

@media (max-width: 576px) {
  .collapsible-subitems-list-container {
    margin-left: -15px;
    width: calc(100% + 30px);
  }

  .collapsible-subitem-container {
    display: flex;
    margin: 0 15px 0 50px;
    padding: 15px 0;
  }

  .collapsible-item-container {
    padding: 15px 0 0;
  }

  .collapsible-additional-info-toggle {
    margin-bottom: 15px;
  }

  .collapsible-subitem,
  .collapsible-item-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .collapsible-item-details {
    margin-bottom: 10px;
  }

  .collapsible-item-controls {
    width: 100%;
  }

  .collapsible-description {
    margin-bottom: 0;
  }

  .collapsible-item-name {
    line-height: 1.2;
  }
}
