@import 'src/ui-kit/styles/colors';

$option-size: 20px;
$explosion-distance: 2.5; // multiplied by $option-size
$explosion-duration: 0.85s;

@mixin keyframes($name) {
  @-moz-keyframes #{$name} {
    @content;
  }
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @-khtml-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

.custom-check {
  display: block;
  position: relative;
  padding-left: 34px;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.radio {
    line-height: 24px;
    input {
      position: absolute;
      opacity: 0;
    }

    /* Create a custom radio button */
    .custom-check-mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: $elo-white;
      border-radius: 50%;
      border: 3px solid $elo-grey-light;
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $elo-white;
      }
    }

    /* On mouse-over, add a grey background color */
    &:hover input:not(:disabled):not(:checked) ~ .custom-check-mark:before {
      background-color: $elo-grey-light;
    }

    /* When the radio button is checked, add a blue background */
    input:checked ~ .custom-check-mark {
      background-color: $elo-blue;
      border-color: $elo-blue;
      .boom {
        background: $elo-blue;
        content: '';
        display: block;
        position: relative;
        border-radius: 50%;
        z-index: 100;
      }
    }
  }
  &.checkbox {
    line-height: 22px;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom checkbox */
    .custom-check-mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      background-color: $elo-white;
      border: 1px solid $elo-grey-40;
      &:before {
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 8px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    /* On mouse-over, add a grey background color */
    // &:hover input:not(:disabled):not(:checked) ~ .custom-check-mark {
    //   background-color: #ccc;
    // }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .custom-check-mark {
      background-color: $elo-blue;
      border-color: $elo-blue;
      .boom {
        background: $elo-blue;
        content: '';
        display: block;
        position: relative;
        border-radius: 50%;
        z-index: 100;
      }
    }

    /* Show the custom-check-mark when checked */
    input:checked ~ .custom-check-mark:before {
      display: block;
    }
  }
}
