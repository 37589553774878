@import 'src/ui-kit/styles/colors';

.shop-header {
  height: 30px;
  background-color: $elo-grey-light;
  font-family: var(--elo-font-sans-serif);
  font-size: 12px;
  color: $elo-grey-dark;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__item {
    display: flex;
    padding-top: 5px;
    margin-right: 25px;

    @media (max-width: 768px) {
      margin-right: 15px;
    }

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:active {
      color: $elo-black-semi;
    }
  }

  &__link {
    color: $elo-grey-dark;

    &:hover,
    &:active {
      color: $elo-black-semi;
      text-decoration: none;
    }
  }

  &--right {
    display: flex;
  }

  &__contacts-tooltip {
    max-width: 300px;

    .popover-body {
      color: $elo-grey-dark;
      font-size: 14px;
      padding: 10px 20px;

      .fas {
        margin-right: 7px;
      }
    }
  }

  &__contacts::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 4px;
    margin-top: 7px;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  &__contacts--active::after {
    margin-top: 6px;
    content: '';
    border-top: 0;
    border-bottom: 0.3em solid;
  }

  &__user-info-row {
    margin: 10px 0;
  }

  &__locale {
    display: flex;
    margin: 5px 0 0 5px;
    cursor: pointer;

    &:hover,
    &:active {
      color: $elo-black-semi;
    }
  }

  &__locale-item {
    cursor: pointer;
    text-transform: capitalize;

    &--active {
      font-weight: bold;
      color: $elo-black-semi;
      cursor: default;
      pointer-events: none;
    }

    &:hover,
    &:active {
      color: $elo-black-semi;
    }
  }

  &__locale-separator {
    margin: 0 8px;
    cursor: default;
  }
}

.lang-switcher__lang {
  cursor: pointer;

  &:hover {
    color: $elo-black-semi;
  }
}
