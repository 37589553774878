@import 'src/ui-kit/styles/colors';

@mixin crossed-price {
  position: relative;
  white-space: nowrap;
  &:before {
    content: '';
    height: 1px;
    position: absolute;
    background: $elo-black-semi;
    width: 100%;
    left: 0;
    top: 5px;
  }

  &--windows:before {
    top: 7px;
  }
}

.preview-content,
.ticket-content {
  position: relative;
  .mr-150 {
    margin-right: 150px;
  }
  .header-price {
    .price {
      font-family: var(--elo-font-sans-serif);
      font-size: 18px;
      font-weight: 500;
      text-align: right;
      color: $elo-black-semi;
      display: block;
      float: right;

      i {
        font-family: var(--elo-font-sans-serif);
        font-weight: 400;
        font-size: 12px;
      }
      &.price-crossed {
        @include crossed-price;
        &:before {
          top: 16px;
        }
        &--windows:before {
          top: 13px;
        }
      }
    }
    .old-price {
      margin: 0 10px 8px 0;
      float: right;
    }
    .coupon-applied {
      font-size: 12px;
      font-weight: bold;
      color: $elo-green;
      text-align: right;
      i {
        margin-right: 5px;
      }
    }
    //@include media-breakpoint-down(sm) {
    @media (max-width: 767.98px) {
      text-align: center;
      .price {
        text-align: center;
      }
      .old-price {
        float: none;
      }
      .coupon-applied {
        order: 3;
        margin-top: 10px;
      }
    }
  }
}

.ticket-content {
  .header-price {
    .price {
      &.price-crossed {
        @include crossed-price;

        &:before {
          top: 6px;
        }

        &--windows:before {
          top: 10px;
        }
      }
    }
  }
}

.payment-plans {
  .radio-field {
    &__control {
      font-style: normal;
    }
  }
}
