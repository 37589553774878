@import './colors';

.custom-shop-product-lessons-tree {
  font-family: var(--elo-font-sans-serif);
  font-size: 14px;

  .row {
    height: 48px;
    border-radius: 4px;
    background-color: $elo-grey-light-20;
  }

  .list-group {
    transition: max-height 0.15s ease-out;

    .hidden {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.25s ease-in;
      visibility: hidden;
    }
  }

  .lesson {
    text-decoration: none;
  }

  .list-group-item {
    border: unset;
    border-radius: 0;
    background-color: $elo-grey-light-20;
    border-bottom: 2px solid $elo-white;
    color: $elo-black-semi;
    position: relative;
    padding: 0.75rem 1.25rem;

    a {
      cursor: pointer;
      color: $elo-black-semi;

      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }
    }

    .lesson-name {
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }

      i {
        margin-right: 0.5rem;
      }
    }
  }

  .children {
    .list-group-item {
      padding-left: 40px;
      background-color: $elo-grey-light-10;
      border-bottom: 2px solid $elo-grey-light;
    }

    .children .list-group-item {
      padding-left: 60px;
      border-bottom: 1px solid $elo-grey-light;
    }
  }
}
