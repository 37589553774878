@import 'src/ui-kit/styles/colors';

.button-input-group {
  display: flex;
  height: 36px;
  margin-left: 20px;

  .counter-button {
    background-color: $elo-white;
    width: 27px;
    padding: 6px 0px 3px 0px;
    border-top-left-radius: 2px;
    border: solid 1px $elo-grey-40;
    margin: 0;
    outline: none;
    &:active {
      background-color: $elo-grey-40;
    }
  }

  .button-count {
    background-color: transparent;
    border: 0;
    padding-top: 5px;

    &:focus {
      outline: none;
    }
  }

  .button-content {
    color: $elo-blue;

    &:focus {
      outline: none;
    }
  }

  .input-counter {
    border: 1px solid $elo-grey-40;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    color: $elo-grey;
    width: 38px;
    outline: 0;
    padding-top: 3px;

    &:focus {
      border: 1px solid $elo-blue;
    }
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
