@import 'src/ui-kit/styles/colors';

$side-bar-width: 295px;

.course-side-nav {
  width: $side-bar-width;
  height: auto;
  position: fixed;
  background: $elo-white;
  box-shadow: 6px 0 5px -6px rgba($elo-black, 0.11);
  font-size: 13px;
  overflow-y: auto;
  max-height: 100%;
  margin-top: -20px;

  &__close {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .menu-wrapper {
    width: 100%;
    display: inline-block;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background: $elo-white;
    height: initial !important;
    margin-bottom: 60px !important;

    .header {
      position: relative;
      background-color: $elo-white;
    }

    .progress-container {
      text-align: center;
      background-color: $elo-white;
      padding: 30px;

      .course-name {
        font-family: var(--elo-font-sans-serif);
        font-size: 16px;
        font-weight: bold;
        color: $elo-black-semi;
        text-align: left;
      }

      .progress-title {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        font-family: var(--elo-font-sans-serif);
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 5px;
        margin-top: 10px;

        .completed {
          color: $elo-grey-dark;
        }

        .percents {
          color: $elo-green;
        }
      }

      .progress {
        height: 4px;
      }
    }

    .menu-lessons-tree {
      .item-actions {
        padding: 0 8px;

        i {
          cursor: pointer;
          font-size: 16px;

          &.fa-check-circle {
            color: $elo-green;
          }

          &.fa-times-circle {
            color: $elo-red;
          }

          &.fa-question-circle {
            color: $elo-orange;
          }
        }
      }

      .category-wrap {
        .category-head {
          position: relative;
          cursor: pointer;
          background: $elo-grey-light-20;
          padding: 6px 0px;
          border-top: 1px solid rgba($elo-grey-40, 0.5);
          display: flex;
          align-items: center;

          .category-name {
            font-size: 14px;
            font-weight: bold;
          }

          &:hover {
            opacity: 0.8;
          }

          &.active {
            i.caret,
            i.caret.fa-caret-down {
              color: $elo-white;
              opacity: 0.5;
            }
          }

          i.caret {
            font-size: 20px;
            vertical-align: middle;

            &.fa-caret-down {
              color: $elo-grey-dark;
              margin: 0 0 0 9px;
            }

            &.fa-caret-right {
              color: $elo-blue;
              margin: 0 0 0 14px;
            }
          }

          .category-name {
            padding: 7px 9px;
            display: inline-block;
            font-size: 13px;
            font-weight: 500;
          }
        }

        .category-lessons {
          background: $elo-grey-light-20;
          padding-left: 0;

          .lesson-wrap {
            border: none;
          }

          & > .category-wrap {
            .lesson-wrap {
              padding-left: 40px;
            }

            .category-lessons {
              & > .category-wrap {
                .lesson-wrap {
                  padding-left: 60px;
                }
              }

              .category-head,
              .lesson-wrap {
                padding-left: 40px;
              }
            }
          }

          .category-head,
          .lesson-wrap {
            padding-left: 20px;
          }
        }
      }

      .lesson-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 6px 20px;
        border-top: 1px solid rgba($elo-grey-40, 0.5);
        cursor: pointer;
        margin-bottom: 0;

        .fa-lock {
          color: $elo-grey-40;
        }

        &:hover {
          background-color: rgba($elo-blue-60, 0.1);
        }

        &.active {
          background: $elo-blue;
          color: $elo-white;

          i {
            color: $elo-white;
          }
        }

        &.locked {
          color: $elo-grey-40;
          pointer-events: none;

          i {
            margin-right: 10px;
          }
        }

        &.require-quiz-finish {
          color: $elo-grey-40;
        }

        .lesson-name {
          padding: 7px 10px;
          font-family: Montserrat Reg;
          text-decoration: none;
          color: inherit;

          cursor: pointer;
          i {
            color: $elo-orange-60;
          }
        }
      }

      .quiz-item {
        padding: 13px 30px;
        background: $elo-blue;
        color: $elo-white;
      }
    }
  }
}

.course-preview__side-nav-btn {
  display: none;
}

@media (max-width: 991px) {
  .course-preview,
  .custom {
    &__side-nav-btn {
      display: block;
      position: absolute;
      margin-top: 20px;
      height: 150px;
      border-radius: 0px 20px 20px 0;
      font-size: 20px;
      background-color: $elo-blue;
      color: $elo-white;
      z-index: 1;
      cursor: pointer;
    }

    .course-side-nav {
      transition: height 0.3s;
      z-index: 22;
      overflow: auto;
      height: auto;
      display: none;
      top: 0;
      margin-top: 0;

      &__container {
        display: none;
        margin-top: -40px;

        &--active {
          display: block;
          position: absolute;
          width: 100%;
          height: 100vh;
          z-index: 1;
        }
      }

      &--active {
        display: block;
      }

      &--active-default {
        display: block;
        width: 100%;
        margin: 0;
      }

      &__close--active {
        display: block;
      }
    }
  }
}
