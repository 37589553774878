// shared lessons-tree

$menu-gray: #f0f0f0;
$menu-light-gray: #f8f8f8;
$menu-hover: #ddeaf5;
$menu-text: #4a4a4a;
$menu-hover-preview: #2b90d9;
$light-blue: #0095f5;
$white: #fff;

// namotto
.shop-product-lessons-tree {
  font-family: var(--elo-font-sans-serif);
  font-size: 14px;
  color: $menu-text;

  & > .category {
    & > .heading {
      font-size: 18px;
    }
  }

  .category {
    .heading {
      margin-bottom: 2px;
      border-radius: 4px;
      padding: 10px 20px;
      background: $menu-gray;

      font-size: 16px;
      font-weight: bold;
      cursor: pointer;

      &.no-lessons {
        cursor: default;
      }

      .styled-fa {
        font-size: 20px;
        margin-right: 10px;
      }
    }

    .lessons {
      padding-left: 20px;
    }
  }

  .lesson {
    margin-bottom: 2px;
    border-radius: 4px;
    padding: 5px 20px;
    min-height: 40px;

    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: center;

    font-size: 14px;
    background: $menu-light-gray;
    text-decoration: none;
    color: inherit;

    &.has-preview {
      cursor: pointer;

      .preview-btn {
        padding: 7px 15px;
        border-radius: 4px;

        color: $white;
        background: $menu-hover-preview;
        opacity: 0.5;
      }

      &:hover {
        color: $light-blue;
        background: $menu-hover;

        .preview-btn {
          opacity: 1;
        }
      }
    }

    &.no-preview {
      cursor: default;
    }
  }
}
