@import 'src/ui-kit/styles/colors';

.product-slider {
  &__no-cover {
    background-color: $elo-grey-light;
    text-align: center;
    &.with-border {
      border: 1px solid $elo-grey-40;
      border-radius: 4px;
    }
    i {
      color: $elo-grey-40;
      font-size: 50px;
      padding: 100px;
    }
  }

  &__covers_item {
    position: relative;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }

  &__remove-btn {
    width: 37px;
    height: 37px;
    padding: 11px 12px 11px 12.5px;
    box-shadow: 0 5px 10px 0 rgba($elo-black, 0.1);
    background-color: $elo-white;
    border-radius: 20px;
    position: absolute;
    top: -10px;
    right: 20px;
    cursor: pointer;
    z-index: 1;

    &:hover {
      opacity: 0.8;
    }

    .fas {
      color: $elo-grey;
      font-size: 15px;
    }
  }

  .slick-slide {
    padding: 0 15px;
  }

  .slick-prev {
    left: -15px;
  }

  .slick-next {
    right: -15px;
  }

  .slick-slider {
    min-height: 256px;

    .slick-list {
      min-height: 256px;
    }

    .slick-arrow::before {
      color: $elo-black;
    }

    .slider-arrow {
      position: absolute;
      top: 45%;
      width: 50px;
      height: 50px;
      background: rgba($elo-black, 0.6);
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      .fas {
        color: $elo-white;
        font-size: 38px;
        padding: 7px 16px;
      }
    }

    .arr-prev {
      left: 0;
      z-index: 1;

      .fas {
        padding: 7px 14px;
      }
    }

    .arr-next {
      right: 0;

      .fas {
        padding: 7px 18px;
      }
    }
  }
}
