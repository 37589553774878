@import 'src/ui-kit/styles/colors';

.elo-modal {
  font-family: var(--elo-font-sans-serif);
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;

  &__title {
    &--additional-info {
      font-family: var(--elo-font-sans-serif);
      font-weight: 400;
      font-size: 13px;
      font-style: normal;
    }
    &--icons {
      display: flex;
      align-items: center;

      i {
        font-size: 30px;
        color: $elo-grey-40;
        margin-right: 10px;
      }
    }
    &--selected-items {
      &__text {
        margin-left: 3px;
      }
    }
  }

  .elo-modal__bottom-buttons__left-block {
    display: flex;
    position: absolute;
  }

  &--small {
    max-width: 600px;
    margin: 200px auto;

    .elo-modal__bottom-buttons {
      text-align: center;
    }

    &-top {
      margin: 30px auto;

      &--100 {
        margin: 100px auto;
      }
    }

    @media (max-width: 600px) {
      max-width: 90%;
      width: 90%;
    }
  }

  &--medium {
    max-width: 730px;
    width: 730px;

    &--with-froala {
      max-width: 750px;
      width: 750px;
    }

    .elo-modal__bottom-buttons {
      text-align: right;
    }

    .elo-modal__bottom-buttons--centered {
      text-align: center;
    }

    @media (max-width: 730px) {
      max-width: 90%;
      width: 90%;
    }
  }

  &--large {
    max-width: 960px;
    width: 960px;

    .elo-modal__bottom-buttons {
      text-align: right;
    }

    .elo-modal__bottom-buttons--centered {
      text-align: center;
    }

    .elo-modal__message-text {
      margin: 0 0 25px;
      text-align: left;

      &--centered {
        text-align: center;
        padding: 0 50px;
      }
    }

    @media (max-width: 960px) {
      max-width: 90%;
      width: 90%;
    }

    @media (max-width: 565px) {
      .elo-modal__message-text {
        margin-bottom: 15px;
      }
    }
  }

  &--x-large {
    max-width: 1140px;
    width: 1140px;

    .elo-modal__bottom-buttons {
      text-align: right;
    }

    .elo-modal__bottom-buttons--centered {
      text-align: center;
    }

    @media (max-width: 1140px) {
      max-width: 90%;
      width: 90%;
    }
  }

  .modal-content {
    border: none;
  }

  .modal-header {
    border: 0;
    justify-content: center;
    padding: 50px 0 0 0;
  }

  .modal-title {
    font-family: var(--elo-font-sans-serif);
    color: $elo-black-semi;
    font-size: var(--elo-font-size-lg);
    line-height: 1.5;
    padding: 0 30px;
    font-weight: 600;
    text-align: center;
  }

  &__header {
    text-align: center;
    font-size: var(--elo-font-size-lg);
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
    color: $elo-black-semi;
    display: flex;
    align-items: center;

    &--right-side {
      color: $elo-grey;
      font-size: var(--elo-font-size-sm);
      position: absolute;
      right: 30px;
      top: 47px;
    }
  }

  &__content {
    text-align: center;
    font-size: var(--elo-font-size-sm);
    color: $elo-black-semi;
    margin: 15px 0;
  }

  &__message-text {
    margin: 0 20px 25px;
    text-align: center;
    color: $elo-grey-dark;
    font-size: var(--elo-font-size-sm);

    &--bold {
      font-family: var(--elo-font-sans-serif);
      font-weight: 500;
    }
  }

  &__notific {
    background-color: $elo-grey-light-30;
    border: solid 1px $elo-grey-light;
    border-radius: 4px;
    color: $elo-grey-dark;
    font-size: 13px;
    display: flex;
    font-style: normal;
    margin-top: 20px;
    padding: var(--elo-font-size-xs);
    width: 100%;
    text-align: center;

    &--left {
      text-align: left;
    }
  }

  &__action-row {
    display: flex;
    justify-content: center;
    font-size: var(--elo-font-size-sm);
    color: $elo-black-semi;
    padding: 10px 0;
    margin: 0 9px 0 5px;
    border-bottom: solid 1px rgba($elo-grey, 0.2);

    .elo-btn {
      display: flex;
    }

    .elo-btn i {
      font-size: 18px;
      margin-right: 3px;
      margin-top: -2px;
    }
  }

  &__top-description {
    text-align: center;
    color: $elo-grey;
    font-size: var(--elo-font-size-sm);
    margin-bottom: 30px;
  }

  &__total-row {
    font-family: var(--elo-font-sans-serif);
    color: $elo-black-semi;
    font-size: var(--elo-font-size-sm);
    margin: 30px 0;
    text-align: right;
    font-weight: 500;
    line-height: 1;
  }

  &__row-inactive {
    color: $elo-grey;
    margin-bottom: -10px;
  }

  .modal-body {
    padding: 30px;
    overflow: unset;
    max-height: 100%;

    &__text {
      margin: -15px 0 27px;
      text-align: center;
      color: $elo-grey-dark;
      font-size: var(--elo-font-size-sm);

      &--no-top-margin {
        margin-top: 0;
      }
    }
  }

  &--medium {
    .modal-body {
      padding-top: 10px;
      padding-bottom: 50px;
    }
  }

  &--body-p-t-10 {
    .modal-body {
      padding-top: 10px;
    }
  }

  &__warning-sign {
    color: $elo-orange;
    font-size: 30px;
    margin: 0 10px;
  }

  &__back-btn {
    color: var(--icon-button-color);
    cursor: pointer;
    position: absolute;
    left: 27px;
    top: 54px;

    &:hover {
      color: var(--icon-button-hover-color);
    }
  }

  &__close-btn {
    position: absolute;
    right: 10px;
    top: 8px;
    font-size: var(--elo-font-size-md);
    color: $elo-grey-40;
    cursor: pointer;
    z-index: 1;

    &:hover {
      opacity: 0.8;
    }
  }

  &__header-icon {
    font-size: 60px;
    margin-bottom: 30px;

    &--lock {
      color: $elo-grey-40;
    }

    &--users,
    &--calendar {
      font-size: 30px;
      margin-right: 7px;
    }

    &--warning {
      color: $elo-orange;
    }

    &--import {
      color: $elo-green;
    }
  }

  &__bottom-buttons,
  &__bottom-buttons--centered {
    text-align: center;
    margin-top: 30px;

    .elo-btn {
      margin-right: 15px;
    }

    .elo-btn:only-child {
      margin: 0;
    }

    @media (max-width: 400px) {
      .elo-btn {
        margin: 0 10px 10px;

        &:last-child {
          margin-right: 10px;
        }
      }
    }
  }

  &__bottom-buttons--no-margin {
    margin-top: 0;
  }

  &__bottom-buttons {
    .elo-btn:last-child {
      margin-right: 0;
    }
  }

  &__message {
    margin-bottom: 20px;
    overflow-x: auto;

    &__bold-text {
      font-family: var(--elo-font-sans-serif);
      font-weight: 500;
      padding-left: 10px;
    }
  }

  &--success {
    .modal-title {
      padding: 0 100px;
    }
    .elo-modal__title {
      color: var(--title-font-color);
      font-family: var(--elo-font-sans-serif);
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;

      i {
        font-size: 30px;
        margin-right: 10px;
      }

      .fa-check-circle {
        color: $elo-green;
      }

      .fa-clock {
        color: $elo-grey;
      }
    }

    .elo-modal__message {
      color: var(--description-font-color);
      font-size: var(--elo-font-size-sm);
      margin: 0 50px;
      text-align: center;
    }

    .book-option {
      margin-bottom: 25px;
    }
  }

  &__remove-button {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 45px;
    height: 45px;
    background: $elo-black;
    opacity: 0.3;
    cursor: pointer;
    z-index: 1;

    &:hover {
      opacity: 0.5;
    }

    i {
      color: $elo-white;
      padding: 13px 16px;
      font-size: 17px;
    }
  }

  &--crop-image {
    .ReactCrop,
    .ReactCrop__image {
      width: 100%;
      max-height: unset;
    }
  }

  @media (max-width: 565px) {
    &__message-text {
      margin-bottom: 15px;
      font-size: 12px;
    }

    .modal-body {
      padding: 15px;
    }

    .modal-header {
      padding-top: 25px;
    }

    .elo-modal__bottom-buttons,
    .elo-modal__bottom-buttons--centered {
      margin-top: 25px;
    }
  }

  &.confirmation-modal {
    .modal-body {
      padding: 30px;
    }
  }
}
