@import 'src/ui-kit/styles/colors';

.payment-tickets {
  .event-ticket {
    border-radius: 6px;
    background-color: $elo-white;
    box-shadow: 0 5px 8px 0 rgba($elo-black, 0.05);
    padding: 0 30px;
    margin-bottom: 20px;
    position: relative;

    .ticket-content {
      padding: 30px 0;
      &--in-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .event-ticket-top {
        width: 65%;
        position: relative;

        &--margin-bottom {
          margin-bottom: 20px;
        }

        .event-ticket-name {
          font-family: var(--elo-font-sans-serif), serif;
          font-size: 18px;
          font-weight: 500;
          color: $elo-black-semi;
          line-height: 1;
        }
        .event-ticket-location {
          font-size: 13px;
          color: $elo-grey;
          margin-top: 10px;
          i {
            margin-right: 3px;
          }
        }
      }
      .header-price {
        @include media-breakpoint-down(md) {
          display: flex;
          flex-direction: column;
          margin-top: 25px;

          .price {
            align-self: baseline;
            order: 2;
          }

          .old-price {
            align-self: baseline;
            order: 1;
          }
        }
      }
      .ticket-metadata {
        border-top: solid 1px $elo-grey-light;
        margin-top: 20px;

        &.selected {
          .input-counter {
            color: $elo-black-semi;
            padding-top: 2px;
          }
        }
        .ticket-data {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          margin: 20px 0;

          &--start {
            @media (max-width: 576px) {
              align-items: flex-start;
            }
          }

          .ticket-count {
            display: flex;
            align-items: center;

            &--desktop-remove-icon {
              font-size: var(--elo-font-size-lg);
              color: $elo-grey;
              margin-left: 15px;

              &:hover {
                color: $elo-grey;
                cursor: pointer;
              }

              @media (max-width: 576px) {
                display: none;
              }
            }

            &--mobile-remove-icon {
              display: none;

              @media (max-width: 576px) {
                display: block;
                color: $elo-grey;
                margin-right: 5px;
                margin-top: 5px;

                &:hover {
                  color: $elo-grey;
                  cursor: pointer;
                }
              }
            }

            .limit-help {
              display: block;
              margin-left: 20px;
            }
          }

          .ticket-date-mobile {
            display: none;

            .ticket-date-time {
              line-height: 16px;
            }
          }

          @media (max-width: 576px) {
            .ticket-date-desktop {
              display: none;
            }

            .ticket-date-mobile {
              display: block;
            }
          }

          &__flex-container {
            display: flex;

            .ticket-date-time {
              &__additional-text {
                @media (max-width: 991px) {
                  display: none;
                }
              }
            }
          }

          &__count-container {
            display: flex;
            flex-wrap: wrap;
            column-gap: 5px;
            margin-top: 3px;
          }

          &__additional-text {
            font-size: 14px;
            margin-left: 35px;
            line-height: 1.1;
            word-break: break-all;

            &--tablet {
              display: none;
              font-size: 12px;
              margin-left: 35px;
              margin-top: -15px;
              color: $elo-grey;
              line-height: 1.1;

              @media (max-width: 991px) {
                display: block;
              }
            }

            @media (max-width: 991px) {
              display: none;
            }
          }

          .ticket-date-mobile {
            display: none;

            .ticket-date-time {
              line-height: 16px;
            }
          }

          @media (max-width: 576px) {
            .ticket-date-desktop {
              display: none;
            }

            .ticket-date-mobile {
              display: block;
            }
          }

          &__flex-container {
            display: flex;
          }

          &__count-container {
            display: flex;
            flex-wrap: wrap;
            column-gap: 5px;
          }
        }
        .checkbox-field__label {
          font-size: 14px;
        }
        .limit-help {
          display: none;
          color: $elo-grey-40;
          font-size: 13px;
          margin-left: 35px;

          &--without-margin {
            margin-left: 0;
          }

          &--margin-left-5 {
            margin-left: 5px;
          }
        }

        @media (max-width: 360px) {
          .limit-help:last-child {
            display: block;
          }
        }

        .stock-count {
          font-size: var(--elo-font-size-sm);
          font-family: var(--elo-font-sans-serif);
          color: var(--elo-green-60);
          margin-top: 10px;
        }
      }

      .event-ticket-countdown {
        max-width: 299px;
        width: 100%;
      }

      .payment-plan {
        &__countdown {
          margin-top: 25px;
        }
      }
    }

    .pricing-content {
      padding: 30px 0;
    }
    .cut-out {
      position: absolute;
      top: 60px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      border-radius: 1000px;
      width: 2rem;
      height: 2rem;
      display: block;
      background: $elo-grey-light;
    }

    .cut-out-left {
      @extend .cut-out;
      box-shadow: inset -10px 0 18px -3px rgba($elo-black, 0.05);
      left: -1rem;
    }

    .cut-out-right {
      @extend .cut-out;
      box-shadow: inset 10px 0 18px -3px rgba($elo-black, 0.05);
      right: -1rem;
    }

    .ticket-date-time {
      font-family: var(--elo-font-sans-serif), serif;
      font-weight: 500;
      font-size: var(--elo-font-size-sm);

      &--bold {
        color: $elo-black-semi;
      }

      &--grey {
        color: $elo-grey;
      }

      &__additional-text {
        font-family: var(--elo-font-sans-serif);
        font-size: var(--elo-font-size-sm);
        margin-top: 5px;
        word-break: break-all;

        &--tablet {
          display: none;
          font-family: var(--elo-font-sans-serif);
          font-size: 12px;
          margin-top: -15px;
          line-height: 1.1;

          @media (max-width: 991px) {
            display: block;
          }

          @media (max-width: 576px) {
            margin-left: 19px;
          }
        }
      }
    }

    &--two-steps-advanced {
      background-color: $elo-grey-light-10;
      border: 1px solid $elo-grey-light;
      border-radius: 18px;
      box-shadow: unset;

      .ticket-content {
        padding-bottom: 0;
      }
    }
  }
  // Mobile view
  @include media-breakpoint-down(md) {
    .event-ticket {
      padding: 0 15px;

      .ticket-content {
        padding: 25px 5px;

        .event-ticket-top {
          width: 100%;
        }

        .ticket-metadata {
          .limit-help {
            display: inline;
          }

          .ticket-data {
            .ticket-count {
              .limit-help {
                display: none;
              }
            }
          }
        }
      }

      .pricing-content {
        border-top: solid 1px $elo-grey-light;
        padding: 25px 0;
      }
    }
  }
}
