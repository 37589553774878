// Colors (hex)
:root {
  --elo-green-60: #05ab5b;
  --elo-green: #17df87;
  --elo-green-50: #2bff99;
  --elo-green-40: #31ee95;

  --elo-dark-grey-60: #181d22;
  --elo-dark-grey: #21282e;
  --elo-dark-grey-50: #4a4a4a;
  --elo-dark-grey-40: #485056;
  --elo-dark-grey-30: #586067;
  --elo-grey-dark-20: #787878;

  --elo-grey-80: #717171;
  --elo-grey-60: #71747a;
  --elo-grey-30: #ececec;

  --elo-grey: #8f9295;

  --elo-light-grey-70: #c0c6cf;
  --elo-light-grey-60: #caced4;
  --elo-light-grey: #d0d0d6;
  --elo-light-grey-50: #cecece;
  --elo-light-grey-40: #d7dadf;
  --elo-light-grey-30: #e1e5e8;
  --elo-light-grey-20: #eceef2;
  --elo-light-grey-10: #f3f5f8;

  --elo-ocean-blue-60: #5196a6;
  --elo-ocean-blue: #daebef;

  --elo-red-60: #cf1937;
  --elo-red: #e42242;
  --elo-red-40: #f43555;

  --elo-yellow: #ffde68;
  --elo-orange: #ffb000;
  --elo-orange-60: #ffc107;
  --elo-orange-70: #ff9604;
  --elo-orange-80: #ff9518;

  --elo-white: #ffffff;
  --elo-black: #000000;

  --color-utility-warning-dark: #b05b24;
  --color-utility-alert-light: #fffcf0;
}

// Colors (rgb)
:root {
  --elo-red--rgb: 228, 34, 66;
  --elo-yellow--rgb: 255, 222, 104;
  --elo-black--rgb: 0, 0, 0;
  --elo-white--rgb: 255, 255, 255;
  --elo-dark-grey--rgb: 33, 40, 46;
  --elo-green--rgb: 43, 255, 153;
  --elo-light-grey-10--rgb: 243, 245, 248;
}

// General
:root {
  --title-font-color: var(--elo-dark-grey);
  --description-font-color: var(--elo-dark-grey);
  --page-bg-color: var(--elo-light-grey-10);
}

// Buttons
:root {
  --button-font-color: var(--elo-dark-grey);
  --button-disabled-bg-color: var(--elo-light-grey-20);

  --create-button-bg-color: var(--elo-green);
  --create-button-bg-color-hover: var(--elo-green-40);
  --create-button-bg-color-active: var(--elo-green-60);

  --primary-button-bg-color: var(--elo-green);
  --primary-button-bg-color-hover: var(--elo-green-40);
  --primary-button-bg-color-active: var(--elo-green-60);

  --outline-button-border-color: var(--elo-dark-grey);
  --outline-button-border-color-hover: var(--elo-dark-grey-30);
  --outline-button-border-color-active: var(--elo-dark-grey-60);
  --outline-button-font-color-hover: var(--elo-dark-grey-30);
  --outline-button-font-color-active: var(--elo-dark-grey-60);

  --delete-button-bg-color: var(--elo-red);
  --delete-button-bg-color-hover: var(--elo-red-40);
  --delete-button-bg-color-active: var(--elo-red-60);

  --banner-button-bg-color: var(--elo-green);
  --banner-button-bg-color-hover: var(--elo-green-40);
  --banner-button-bg-color-active: var(--elo-green-60);

  --icon-button-color: var(--elo-dark-grey);
  --icon-button-color-hover: var(--elo-dark-grey-40);
  --icon-button-color-active: var(--elo-dark-grey-60);

  --link-button-color: var(--elo-dark-grey);
  --link-button-color-hover: var(--elo-dark-grey-40);
  --link-button-color-active: var(--elo-dark-grey);

  --collapse-button-color: var(--elo-dark-grey);
  --collapse-button-color-hover: var(--elo-dark-grey-40);
  --collapse-button-color-active: var(--elo-dark-grey);

  --floating-button-font-color: var(--elo-green);
  --floating-button-font-color-hover: var(--elo-green-40);
  --floating-button-font-color-active: var(--elo-green-60);
  --floating-button-font-color-focus: var(--elo-green);

  --action-button-bg-color: var(--elo-white);
  --action-button-font-color: var(--elo-grey);
  --action-button-font-color-hover: var(--elo-light-grey);

  --filter-submit-button-border-color: var(--elo-dark-grey);
  --filter-submit-button-bg-color: var(--elo-dark-grey);
  --filter-submit-button-font-color: var(--elo-white);

  --add-button-bg-color: var(--elo-light-grey-10);
  --add-button-border-color: var(--elo-light-grey);
  --add-button-border-color-hover: var(--elo-grey-60);
  --add-button-font-color: var(--elo-grey-60);

  --edit-button-bg-color: var(--elo-light-grey-20);
  --edit-button-bg-color-hover: var(--elo-light-grey-60);
  --edit-button-bg-color-active: var(--elo-light-grey-70);

  --upload-button-bg-color: var(--elo-light-grey-20);
  --upload-button-bg-color-hover: var(--elo-light-grey-60);
  --upload-button-bg-color-active: var(--elo-light-grey-70);
  --upload-button-border-color: var(--elo-light-grey-40);
  --upload-button-font-color: var(--elo-grey);
  --upload-button-icon-color: var(--elo-grey-60);
}

// Action menu (Dropdown)
:root {
  --dropdown-bg-color: var(--elo-light-grey-20);
  --dropdown-font-color: var(--elo-dark-grey);
  --dropdown-item-bg-color-hover: var(--elo-light-grey-40);
}

// Tabs
:root {
  --tab-bg-color: var(--elo-light-grey-20);
  --tab-border-color-active: var(--elo-green);
  --tab-font-color: var(--elo-grey-60);
  --tab-font-color-hover: var(--elo-dark-grey-30);
  --tab-font-color-active: var(--elo-dark-grey);
}

// Nav Tabs
:root {
  --nav-tab-font-color: var(--elo-grey-60);
  --nav-tab-font-color-active: var(--elo-dark-grey);
  --nav-tab-font-color-hover: var(--elo-dark-grey-30);
  --nav-tab-border-color: var(--elo-light-grey-40);
}

// Fields
:root {
  --field-border-color: var(--elo-light-grey-40);
  --field-border-color-focus: var(--elo-green);
  --field-border-color-error: var(--elo-red);
  --field-bg-color: var(--elo-white);
  --field-bg-color-disabled: var(--elo-light-grey-10);
  --field-font-color: var(--elo-dark-grey);
  --field-label-font-color: var(--elo-grey-60);
  --field-placeholder-font-color: var(--elo-grey);
  --field-required-font-color: var(--elo-red);
  --field-error-font-color: var(--elo-red);
  --field-icon-color: var(--elo-grey-60);

  --field-prefix-bg-color: var(--elo-light-grey-20);
}

// Range
:root {
  --range-bg-color: var(--elo-dark-grey);
}

// Datepicker
:root {
  --datepicker-arrow-color: var(--elo-dark-grey);
  --datepicker-active-color: var(--elo-green);
  --datepicker-font-color: var(--elo-dark-grey);
  --datepicker-hover-color: var(--elo-light-grey-20);
}

// Tags
:root {
  --tag-bg-color: var(--elo-ocean-blue);
  --tag-border-color: var(--elo-ocean-blue-60);
  --tag-font-color: var(--elo-ocean-blue-60);
  --tag-button-color: var(--elo-ocean-blue-60);
  --tag-button-color-hover: var(--elo-ocean-blue-60);
}

// Selects
:root {
  --select-indicator-font-color: var(--elo-dark-grey);
  --select-item-font-color-selected: var(--elo-white);
  --select-item-bg-color-selected: var(--elo-dark-grey);
  --select-item-bg-color-hover: var(--elo-light-grey-20);
  --select-item-font-color: var(--elo-dark-grey);
}

// Pagination
:root {
  --pagination-font-color: var(--elo-dark-grey);
  --pagination-bg-color-selected: var(--elo-light-grey-20);
  --pagination-font-color-selected: var(--elo-dark-grey);
  --pagination-bg-color-active: var(--elo-light-grey);
  --pagination-font-color-active: var(--elo-dark-grey);
  --pagination-bg-color-hover: var(--elo-light-grey-10);

  --pagination-button-bg-color: var(--elo-dark-grey);
  --pagination-button-bg-color-hover: var(--elo-dark-grey-40);
  --pagination-button-bg-color-active: var(--elo-dark-grey-60);
  --pagination-button-font-color: var(--elo-white);
}

// Checkboxes
:root {
  --checkbox-checked-bg-color: var(--elo-green);
  --checkbox-checked-font-color: var(--elo-dark-grey);
  --checkbox-bg-color: var(--elo-white);
  --checkbox-border-color: var(--elo-dark-grey);
  --checkbox-label-font-color: var(--elo-dark-grey);
}

// Switchers {
:root {
  --switch-toggle-bg-color: var(--elo-dark-grey);
  --switch-bg-color: var(--elo-white);
  --switch-bg-color-active: var(--elo-green);
  --switch-border-color: var(--elo-dark-grey);
  --switch-border-color-active: var(--elo-green);

  --text-switch-bg-color: var(--elo-white);
  --text-switch-font-color: var(--elo-dark-grey);
  --text-switch-border-color: var(--elo-dark-grey);
  --text-switch-font-color-active: var(--elo-white);
  --text-switch-bg-color-active: var(--elo-dark-grey);
}

// Radiobuttons
:root {
  --radio-checked-bg-color: var(--elo-green);
  --radio-checked-border-color: var(--elo-green);
  --radio-checked-font-color: var(--elo-dark-grey);
  --radio-bg-color: var(--elo-white);
  --radio-border-color: var(--elo-dark-grey);
  --radio-disabled-bg-color: var(--elo-light-grey-20);
  --radio-disabled-border-color: var(--elo-light-grey-20);
}

// Notifications
:root {
  --notification-error-bg-color: var(--elo-red);
  --notification-error-font-color: var(--elo-white);

  --notification-warning-bg-color: var(--elo-yellow);
  --notification-warning-font-color: var(--elo-dark-grey);

  --notification-success-bg-color: var(--elo-green);
  --notification-success-font-color: var(--elo-dark-grey);
}

// Infos
:root {
  --info-font-color: var(--elo-dark-grey);

  --warning-info-bg-color: rgba(var(--elo-yellow--rgb), 0.1);
  --warning-info-border-color: var(--elo-yellow);

  --error-info-bg-color: rgba(var(--elo-red--rgb), 0.1);
  --error-info-border-color: var(--elo-red);
  --error-info-icon-color: var(--elo-red);
}

// Tooltips
:root {
  --tooltip-bg-color: var(--elo-dark-grey-40);
  --tooltip-font-color: var(--elo-light-grey);
}

// Statuses
:root {
  --status-success-bg-color: var(--elo-green);
  --status-success-font-color: var(--elo-dark-grey);

  --status-warning-bg-color: var(--elo-yellow);
  --status-warning-font-color: var(--elo-dark-grey);

  --status-error-bg-color: var(--elo-red);
  --status-error-font-color: var(--elo-white);

  --status-neutral-bg-color: var(--elo-light-grey-40);
  --status-neutral-font-color: var(--elo-dark-grey);
}

// Widgets
:root {
  --widget-bg-color: var(--elo-light-grey-10);
  --widget-font-color: var(--elo-dark-grey);
  --widget-font-color-secondary: var(--elo-grey);
  --widget-icon-font-color: var(--elo-dark-grey);

  --dark-widget-bg-color: var(--elo-dark-grey-40);
  --dark-widget-font-color: var(--elo-white);
  --dark-widget-font-color-secondary: var(--elo-light-grey-40);
  --dark-widget-icon-font-color: var(--elo-white);
  --dark-widget-icon-font-color-hover: var(--elo-light-grey-20);
  --dark-widget-old-price-font-color: var(--elo-light-grey);

  --selectable-widget-bg-color: var(--elo-light-grey-10);
  --selectable-widget-font-color: var(--elo-dark-grey);
  --selectable-widget-bg-color-active: var(--elo-dark-grey-40);
  --selectable-widget-font-color-active: var(--elo-white);
  --selectable-widget-icon-font-color: var(--elo-dark-grey-40);
  --selectable-widget-icon-font-color-active: var(--elo-green);

  --account-widget-bg-color: var(--elo-light-grey-10);
  --account-widget-icon-bg-color: var(--elo-white);
  --account-widget-icon-font-color: var(--elo-dark-grey);
  --account-widget-bg-color-active: var(--elo-dark-grey);
  --account-widget-font-color: var(--elo-dark-grey);
  --account-widget-font-color-active: var(--elo-white);
}

// Hovered widget
:root {
  --hovered-widget-bg-color: var(--elo-white);
  --hovered-widget-icon-font-color: var(--elo-white);
  --hovered-widget-icon-bg-color: var(--elo-dark-grey-40);
  --hovered-widget-bg-color-hover: var(--elo-dark-grey-40);
}

// Tables
:root {
  --table-head-bg-color: var(--elo-light-grey-20);
  --table-head-font-color: var(--elo-grey);

  --table-body-bg-color: var(--elo-white);
  --table-body-font-color: var(--elo-dark-grey);

  --table-row-border-color: var(--elo-light-grey-20);

  --table-first-level-row-bg-color: var(--elo-light-grey-10);
  --table-second-level-row-bg-color: var(--elo-light-grey-20);
  --table-second-level-row-border-color: var(--elo-light-grey-40);

  --table-caret-font-color: var(--elo-dark-grey);

  --table-link-font-color: var(--elo-dark-grey);
  --table-link-font-color-hover: var(--elo-dark-grey-30);
  --table-link-font-color-active: var(--elo-dark-grey);
}

// Seller Sidebar
:root {
  --sidebar-icon-font-color-active: var(--elo-green);

  --sidebar-cabinet-bg-color: var(--elo-dark-grey);
  --sidebar-cabinet-font-color: var(--elo-light-grey);
  --sidebar-cabinet-icon-font-color-active: var(--elo-green);
  --sidebar-cabinet-arrow-font-color: var(--elo-dark-grey-40);
  --sidebar-cabinet-font-color-active: var(--elo-white);
  --sidebar-cabinet-subitem-font-color-active: var(--elo-green);

  --sidebar-payer-bg-color: var(--elo-white);
  --sidebar-payer-font-color: var(--elo-dark-grey-40);
  --sidebar-payer-icon-font-color: var(--elo-grey-60);
  --sidebar-payer-icon-font-color-active: var(--elo-green);
  --sidebar-payer-arrow-font-color: var(--elo-grey);
  --sidebar-payer-font-color-active: var(--elo-white);
  --sidebar-payer-item-bg-color-active: var(--elo-dark-grey);
  --sidebar-payer-subitem-font-color: var(--elo-grey-60);
  --sidebar-payer-subitem-font-color-active: var(--elo-dark-grey);
}

// Grey Boxes
:root {
  --box-bg-color: var(--elo-light-grey-20);
  --box-font-color: var(--elo-dark-grey);
}

// Links
:root {
  --link-font-color: var(--elo-dark-grey);
  --link-font-color-hover: var(--elo-dark-grey-30);
  --link-font-color-active: var(--elo-dark-grey);
}

// Modals
:root {
  --modal-title-font-color: var(--elo-dark-grey);
  --modal-description-font-color: var(--elo-dark-grey);
}

// Image covers
:root {
  --image-cover-bg-color: var(--elo-light-grey-20);
  --image-cover-font-color: var(--elo-grey);
}

// Filter toggle
:root {
  --filter-toggle-counter-font-color: var(--elo-dark-grey);
}

// Thumbnail select
:root {
  --thumbnail-select-border-color: var(--elo-dark-grey);
  --thumbnail-select-icon-font-color: var(--elo-dark-grey);
}

// Spinner
:root {
  --spinner-border-color: rgba(var(--elo-green--rgb), 0.2);
  --spinner-solid-border-color: var(--elo-green);
}

// Toggle sections
:root {
  --toggle-section-bg-color: var(--elo-light-grey-10);
}

// Progress bar
:root {
  --progress-bar-bg-color: var(--elo-green);
  --progress-bg-color: var(--elo-light-grey-20);
  --progress-value-font-color: var(--elo-grey-60);
}

// Cover widgets
:root {
  --cover-widget-bg-color: var(--elo-light-grey-10);
  --cover-widget-icon-font-color: var(--elo-grey);
}

// Text editor
:root {
  --text-editor-bg-color: var(--elo-light-grey-20);
}

// Icons
:root {
  --icon-font-color: var(--elo-dark-grey);
}

// Payment button
:root {
  --elo-payment-button-bg: var(--elo-orange-60);
  --elo-payment-button-hover-bg: var(--elo-orange);
  --elo-payment-button-color: var(--elo-black);
}

// 404, 500
:root {
  --error-page-bg-color: #f0f1f6;
  --error-page-font-color: var(--elo-dark-grey-40);
}
