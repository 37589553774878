@import 'src/ui-kit/styles/colors';

.payment-error {
  background-color: $elo-white;
  box-shadow: 0 1px 5px 0 rgba($elo-black, 0.05);
  border: solid 1px $elo-grey-40;
  max-width: 500px;
  margin: 50px auto;
  padding: 30px;
  .error-title {
    font-size: 30px;
    color: $elo-red-60;
  }
  .error-subtitle {
    font-size: 20px;
    color: $elo-red-60;
    margin-bottom: 10px;
    word-break: break-word;
  }
  .error-msg {
    font-size: 14px;
    color: $elo-grey-dark;
    word-break: break-word;
  }
  .secured-by-elopay {
    text-align: center;
    font-size: 14px;
    color: $elo-grey;
    .fas {
      margin-right: 5px;
    }
  }
}
