@import '../../../styles/colors';
@import '../../../styles/fonts';

.elo-snackbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  background-color: hwb(0 0% 100% / 0.702);
  color: $elo-white;
  font-size: var(--elo-font-size-sm);
  font-family: var(--elo-font-sans-serif);
  font-weight: 400;
  min-height: 60px;
  align-items: center;
  padding: 25px 35px;

  &__content {
    line-height: 1.2;
    margin-right: 30px;

    a {
      color: $elo-orange;

      &:hover {
        opacity: 0.9;
        text-decoration: none;
      }

      &:active {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  &__settings-btn {
    font-size: 16px;
    border: 1px solid $elo-grey-40;
    color: $elo-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    margin-right: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }
  }

  @media (max-width: 769px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 0;

    &__content {
      margin-right: 0;
    }

    &__buttons {
      flex-wrap: wrap;
      justify-content: space-between;

      .elo-snackbar__main-btn {
        order: 1;
        width: 100%;
      }

      .elo-snackbar__settings-btn {
        margin-top: 20px;
        order: 2;
      }

      .elo-snackbar__additional-btn {
        margin-top: 20px;
        margin-right: 0;
        order: 3;
      }
    }
  }
}
