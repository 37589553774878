@import 'src/ui-kit/styles/colors';

.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  line-height: 0;
  height: 37px;
  &__center-icon {
    position: absolute;
    top: 6px;
    left: 5.5px;
    color: $elo-grey-40;
    background-color: $elo-white;
    border: 5px solid $elo-white;
    border-radius: 50%;
    font-size: 15px;

    &--disabled {
      display: none;
    }

    &--not-published {
      border-left: 4.5px solid $elo-white;
      border-right: 4.5px solid $elo-white;
    }

    &--private {
      border: 4px solid white;
      border-left: 5px solid $elo-white;
      border-right: 5px solid $elo-white;
      font-size: 18px;
    }
  }

  &__product-type-icon {
    position: absolute;
    right: -10px;
    bottom: -5px;
    color: var(--elo-dark-grey);
    background-color: $elo-white;
    border-top: 4px solid $elo-white;
    border-left: 4px solid $elo-white;
    border-radius: 50%;
    font-size: 15px;

    &--download {
      // top: 21px;
      font-size: 17px;
    }

    &--digital {
      border-top: 5px solid $elo-white;
    }

    // &--ticket {
    //   left: 24px;
    // }

    &--course {
      font-size: 16px;
    }

    &--dark-background {
      background-color: $elo-grey-light-20;
      border-top: 4px solid $elo-grey-light-20;
      border-left: 4px solid $elo-grey-light-20;
    }

    &--big,
    &--extra-big {
      font-size: 18px;
    }
  }

  &__product-type-icon-disabled {
    position: absolute;
    right: -10px;
    bottom: -5px;
    color: $elo-grey-40;
    font-size: 15px;

    &--dark-background {
      background-color: $elo-grey-light-20;
      border-top: 4px solid $elo-grey-light-20;
      border-left: 4px solid $elo-grey-light-20;
      border-radius: 50%;
    }

    &--big,
    &--extra-big {
      font-size: 18px;
    }
  }

  &__img {
    width: 37px;
    height: 37px;
    max-width: none !important;
    border-radius: 4px;
    &--big {
      width: 74px;
      height: 74px;
      max-width: none !important;
    }
    &--extra-big {
      width: 100px;
      height: 100px;
      max-width: none !important;
    }
    &--not-published {
      opacity: 0.5;
    }
  }

  &--big {
    height: 74px;
  }

  &--extra-big {
    height: 100px;
  }

  &__no-cover {
    width: 37px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--image-cover-bg-color);
    &--big {
      width: 74px;
      height: 74px;
    }

    &--extra-big {
      width: 100px;
      height: 100px;
    }

    i {
      font-size: 24px;
      color: var(--image-cover-font-color);
    }
  }
}
