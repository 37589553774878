@import 'src/ui-kit/styles/colors';

.course-top-nav {
  height: 60px;
  box-shadow: 0 2px 5px 0 rgba($elo-black, 0.07);
  background-color: $elo-white;

  #course-burger-menu {
    display: none;

    .fa-times {
      font-size: 30px;
      width: 25px;
      color: $elo-grey-dark;
    }
  }

  .navbar-nav {
    flex-direction: row;
  }

  .seller-data {
    display: flex;
    align-items: center;
    margin-left: 25px;

    &__avatar {
      height: 36px;
      width: 36px;
      border-radius: 18px;
      background-size: cover;
    }

    &__name {
      font-size: 13px;
      color: $elo-grey-dark;
      margin-left: 10px;
    }
  }

  .nav-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 15px;

    &__left-block {
      display: flex;
      align-items: center;
    }
  }

  .nav-item {
    margin-left: 15px;

    .elo-btn {
      cursor: pointer;
      border-radius: 17px;
      min-width: 104px;
      padding: 9px 20px 7px 20px;

      &:disabled {
        pointer-events: none;
      }
    }
  }

  &__next-btn i {
    font-size: var(--elo-font-size-xs);
    margin-left: 5px;
  }

  &__finish-btn {
    display: flex;
    font-size: var(--elo-font-size-xs);
    .elo-btn {
      span {
        display: block;
      }
      i {
        display: none;
        font-size: 14px;
        color: $elo-white;
      }
    }
  }

  &__previous-btn i {
    font-size: var(--elo-font-size-xs);
  }

  &.payment-continue {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;

    .nav-inner {
      padding-right: 0;
    }

    .btn-warning {
      background-color: $elo-orange;
    }

    .continue {
      color: $elo-white;

      .addition {
        margin-left: 5px;
      }
    }

    box-shadow: 0 2px 5px 0 rgba($elo-black, 0.07);
    position: fixed;
    background-color: rgba($elo-white, 0.988);
    width: 100%;
    z-index: 2;

    .back-btn {
      background-color: $elo-grey-light-20;
      color: rgba($elo-black-semi, 0.97);

      &:hover {
        opacity: 0.7;
      }
    }

    .collapse {
      transition: opacity 0.5s;
    }

    .collapsing {
      &.back-btn,
      &.continue,
      &.seller {
        transition: height 0.05s;
        height: auto;
        opacity: 0;
      }
    }

    .btn {
      border-radius: 0;
      font-size: 16px;
      border: none;

      padding: 18px 23px;

      .fas {
        line-height: 24px;
      }

      &.continue {
        float: right;

        .sum,
        .addition,
        .continue-text,
        .fas {
          display: inline-block;
          vertical-align: middle;
        }

        .sum {
          font-size: 24px;
          font-weight: bold;
          line-height: 24px;
          letter-spacing: 0.8px;
        }

        .addition {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: rgba($elo-white, 0.7);
        }

        .continue-text {
          font-size: 16px;
          font-weight: 600;
          text-align: left;
          color: $elo-white;
          position: relative;
          padding: 0 10px;
          margin-left: 5px;

          &.with-left-border:before {
            content: '';
            position: absolute;
            left: 0;
            width: 1px;
            height: 30px;
            background-color: rgba($elo-white, 0.7);
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .course-top-nav {
    padding: 0;

    #course-burger-menu {
      display: inline-block;
      margin-left: 15px;
    }

    .seller-data {
      margin-left: 10px;

      &__name {
        display: none;
      }
    }

    .nav-item {
      .elo-btn {
        min-width: 70px;

        span {
          display: none;
        }
      }
    }

    &__finish-btn {
      .elo-btn.orange {
        span {
          display: block;
        }
      }
    }

    &.payment-continue {
      .continue .addition {
        display: none;
      }
    }
  }
}

@media (max-width: 575px) {
  .course-top-nav {
    .seller-data {
      display: none;
    }

    .elo-btn i {
      font-size: 18px;
    }

    .nav-item {
      margin-left: 10px;

      .reset-lesson-btn {
        display: flex;
        align-items: center;
        padding: 7px 15px;
        width: 92px;
      }

      &.course-top-nav__next-btn {
        margin-left: 10px;
      }

      &.course-top-nav__previous-btn,
      &.course-top-nav__next-btn {
        .elo-btn {
          min-width: 34px;
          max-width: 34px;
          height: 34px;
          padding: 8px 6px 8px 8px;
        }
      }
    }

    &__finish-btn {
      .elo-btn.orange {
        span {
          display: none;
        }
        i {
          display: block;
        }
      }
    }
  }
}

.course-side-nav {
  .header {
    padding-left: 30px;
    padding-top: 10px;
    display: none;

    .seller-data {
      display: none;
    }

    @media (max-width: 575px) {
      display: block;

      .seller-data {
        display: flex;
        align-items: center;

        &__avatar {
          height: 36px;
          width: 36px;
          border-radius: 18px;
          background-size: cover;
        }

        &__name {
          font-size: 13px;
          color: $elo-grey-dark;
          margin-left: 10px;
        }
      }
    }
  }
}
