@import 'src/ui-kit/styles/colors';

.tooltip-menu {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;

  & + .tooltip-menu-close {
    z-index: 1;
  }

  &__container {
    display: flex;
    justify-content: flex-end;

    &--right {
      padding-right: 15px;
      justify-content: flex-end !important;
    }
  }

  .elo-btn-icon {
    margin: 0;
  }

  &:focus {
    outline: none;
  }

  &__column {
    position: relative;
    width: 60px;

    .tooltip-menu-close {
      z-index: 1;
    }
  }

  &__icon {
    color: var(--dropdown-font-color);
    font-size: 22px;
    cursor: pointer;

    &:hover {
      color: var(--dropdown-item-bg-color-hover);
    }
  }

  &__list-container {
    padding: 0;
  }

  &__item {
    background: none;
    border: none;
    color: var(--dropdown-font-color);
    cursor: pointer;
    font: {
      family: var(--elo-font-sans-serif);
      size: 12px;
      style: normal;
      weight: 500;
    }

    &--pdf {
      padding: 8px 24px;
    }

    &--info {
      padding: 0 24px;
    }

    &--parent {
      background-color: var(--elo-light-grey-70);
    }

    &--subitem {
      background-color: var(--elo-light-grey-30);
    }

    &.action-icon {
      font-size: var(--elo-font-size-md);
      padding: 0 16px;

      &:hover {
        background: none;
        color: $elo-blue;
      }
    }

    &:hover {
      background-color: var(--dropdown-item-bg-color-hover);
    }

    &:focus {
      outline: none;
    }

    &--edit {
      position: relative;

      .tooltip-menu__hover-menu-container {
        display: none;
      }

      &:hover {
        .tooltip-menu__hover-menu-container {
          display: flex;
          width: 146px;
        }
      }
    }

    &--grey {
      background-color: var(--elo-light-grey-60);
    }
  }

  &__popover {
    box-shadow: none;
    border: 0;
    max-width: initial;
    display: flex;
    border-radius: 5px;

    .popover-body {
      display: flex;
      flex-direction: column;
      padding: 12px 0;
    }

    &.actions {
      .popover-body {
        flex-direction: row;
      }
    }

    ul {
      margin: 0;
      list-style-type: none;
      font-style: normal;
    }

    &--with-icons {
      .popover-body {
        flex-direction: row;
        padding: 12px 10px;
      }

      .tooltip-menu__item {
        height: 100%;
        padding: 0 3px;
        line-height: 1;

        &:hover {
          background: none;
        }

        .elo-btn-icon {
          font-size: var(--elo-font-size-md);
        }

        .elo-btn-icon--disabled {
          color: $elo-grey;
          font-size: 16px;
          cursor: default;
        }
      }
    }
  }

  &__popover-container {
    border: 0;
    box-shadow: none;
  }

  &__hover-menu-container {
    flex-direction: column;
    position: absolute;
    top: -12px;
    left: -145px;
    width: 145px;
    padding: 12px 0;
    border-radius: 6px;
    background-color: var(--dropdown-bg-color);

    a {
      display: flex;
      align-items: center;
      cursor: pointer;
      background: none;
      border: none;
      text-align: left;
      font-size: 12px;
      line-height: 2;
      color: var(--dropdown-font-color);
      font-family: var(--elo-font-sans-serif);
      font-weight: 500;
      padding: 0 20px;
      height: 28px;
      text-decoration: none;

      &:hover {
        color: var(--dropdown-font-color);
        background-color: var(--dropdown-item-bg-color-hover);
      }
    }
  }
}

.tooltip-menu-close {
  position: fixed;
  top: 0;
  left: 0;
  // for some reason z-index was set 0
  z-index: 0;
  width: 100%;
  height: 100%;
}

.bs-popover-auto[x-placement^='top'] > .arrow::before {
  border-top-color: var(--dropdown-bg-color) !important;
  margin-bottom: 1px;
}

.bs-popover-auto[x-placement^='right'] > .arrow::before {
  border-right-color: var(--dropdown-bg-color) !important;
  margin-left: 1px;
}

.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
  border-bottom-color: var(--dropdown-bg-color) !important;
  margin-top: 1px;
}

.bs-popover-auto[x-placement^='left'] > .arrow::before {
  border-left-color: var(--dropdown-bg-color) !important;
  margin-right: 1px;
}
