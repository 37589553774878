$screen-width-xs: 0;
$screen-width-sm: 576px;
$screen-width-md: 768px;
$screen-width-lg: 992px;
$screen-width-xl: 1200px;

:root {
  --screen-width-xs: #{$screen-width-xs};
  --screen-width-sm: #{$screen-width-sm};
  --screen-width-md: #{$screen-width-md};
  --screen-width-lg: #{$screen-width-lg};
  --screen-width-xl: #{$screen-width-xl};
}
