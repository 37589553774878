@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/elo-field';

.checkbox-field {
  &--no-margin {
    margin-bottom: 0;
  }

  &--margin-15 {
    margin-bottom: 15px;
  }

  &--margin-left {
    margin-left: 33px;
  }

  &--margin-top-10 {
    margin-top: 10px;
  }

  &__label {
    display: flex;
    color: var(--checkbox-label-font-color);

    &--without-margin {
      margin-bottom: 0;
    }
  }

  &__control.field__control,
  &__control {
    width: auto;
    display: block;
    position: relative;
    padding-left: 34px;
    margin: 0;
    cursor: pointer;
    user-select: none;
    line-height: 22px;
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      /* When the checkbox is checked, add a blue background */
      &:checked ~ .custom-check-mark {
        background-color: var(--checkbox-checked-bg-color);
        border-color: var(--checkbox-checked-bg-color);

        .boom {
          background: $elo-blue;
          content: '';
          display: block;
          position: relative;
          border-radius: 50%;
          z-index: 100;
        }
      }

      /* Show the custom-check-mark when checked */
      &:checked ~ .custom-check-mark::before {
        display: block;
      }
    }

    /* Create a custom checkbox */
    .custom-check-mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      background-color: var(--checkbox-bg-color);
      border: 1px solid var(--checkbox-border-color);

      &.centered {
        top: -10px;
      }

      &::before {
        content: '';
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 12px;
        border: solid var(--checkbox-checked-font-color);
        border-width: 0 3px 3px 0;
        transform: translate(-50%, -65%) rotate(45deg);
      }
    }
  }

  &__label-container {
    display: flex;
    align-items: center;
    line-height: 1;
  }
}
