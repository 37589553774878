@import 'src/ui-kit/styles/colors';

$bg: $elo-grey-40;
$hole: 0.4em;

.ticket-small-budge {
  display: inline-block;
  box-sizing: content-box;
  position: relative;
  background-size: 51% 100%;
  background-repeat: no-repeat;
  background-image: radial-gradient(circle at 0 50%, rgba($bg, 0) $hole, $bg $hole),
    radial-gradient(circle at 100% 50%, rgba($bg, 0) $hole, $bg $hole);
  background-position:
    top left,
    top right;
  border-radius: 2px;
  font-size: 12px;
  color: $elo-white;
  padding: 6px 15px;
}

.help-hint {
  font-size: 13px;
  font-style: normal;
  color: $elo-grey-dark;
}
