@import 'src/ui-kit/styles/colors';

.shop-auth {
  .auth-form {
    padding: 50px 40px;
    border-radius: 4px;
    background-color: $elo-white;
    box-shadow: 0 5px 15px 0 rgba($elo-black, 0.1);
    margin: 60px 0 80px;

    &__container {
      max-width: 100%;
      min-width: 35%;
      width: 590px;

      @media (max-width: 576px) {
        max-width: 100%;
        width: 90%;
      }
    }

    .elo-btn {
      font-size: 15px;

      @media (max-width: 320px) {
        font-size: 13px;
      }
    }

    .auth-header {
      margin-bottom: 20px;
      text-align: center;
    }

    .auth-title {
      font-family: var(--elo-font-sans-serif);
      font-size: 24px;
      color: $elo-black-semi;
      font-weight: bold;
      margin-bottom: 5px;
      line-height: 1;

      &__description {
        font-size: 15px;
        font-family: var(--elo-font-sans-serif);
        font-weight: 400;
        color: $elo-grey;
        line-height: 16px;
      }
    }

    .auth-envelope {
      margin: 40px 0 40px 10px;
    }

    &__btn {
      padding: 16.5px;
      font-size: 15px;
      border-radius: 30px;
      line-height: 1;
      height: 50px;

      &.fluid {
        width: 100%;
        max-width: 311px;
      }

      i {
        font-size: var(--elo-font-size-md);
      }
    }

    .text-field__prefix {
      position: absolute;
      height: 100%;
      background-color: transparent;
      border: none;
    }

    input {
      height: 50px;
      font-size: 15px;
      padding: 15px 38px 12px;

      &::placeholder {
        font-size: 15px !important;
      }
    }

    .auth-field {
      &--with-margins {
        margin: 40px 0;
      }

      &--top-and-bottom {
        margin-top: 40px;
        margin-bottom: 20px;
      }

      &__email {
        &--sign-in {
          margin-top: 40px;
          margin-bottom: 20px;
        }

        &--forgot-password {
          margin: 40px 0;

          input {
            font-size: 15px;
            height: 50px;
            padding: 15px 38px 12px;

            &::placeholder {
              font-size: 15px !important;
            }
          }
        }
      }

      &__password--sign_in {
        margin-bottom: 10px;
      }
    }

    .auth-description {
      margin: 20px 0 20px 0;
      color: rgba($elo-black-semi, 0.7);
      font-family:
        Nunito Sans,
        sans-serif;
      font-size: 14px;
    }

    .auth-input {
      margin-top: 20px;
      margin-bottom: 0;

      input {
        font-size: 15px;

        &::placeholder {
          color: rgba($elo-black-semi, 0.3);
          font-size: 15px !important;
        }

        &::-moz-placeholder {
          line-height: 2.5 !important;
        }
      }
    }

    .auth-link__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .auth-link {
      display: inline-block;
      padding: 13px 0 0 10px;

      a {
        text-decoration: underline;
        font-size: 15px;
        color: $elo-grey;
        font-family: var(--elo-font-sans-serif);
        font-weight: 400;

        &:hover {
          color: $elo-blue-60;
        }
      }

      &--without-paddings {
        padding: 0;
      }

      &__forgot-password-back {
        padding: 19px 15px 0 10px;
      }
    }

    .auth-btn {
      float: right;

      &--action {
        font-size: 15px;
        min-height: 50px;
        border-radius: 30px;
      }
    }

    .magic-link__title {
      font-family: var(--elo-font-sans-serif);
      font-weight: 500;
      font-size: 15px;
    }

    .magic-link__description {
      margin-bottom: 40px;
    }

    .magic-link-btn {
      border-radius: 4px;
      background-color: $elo-grey-light-30 !important;
      border: 2px solid $elo-grey-40;
      font-size: 15px;
      color: $elo-grey;
      font-family: var(--elo-font-sans-serif);
      font-weight: 500;
      width: 50%;
      padding: 13px;
      margin-right: 30px;

      &:hover {
        background-color: $elo-grey-light !important;
      }

      &:disabled {
        background-color: $elo-grey-40 !important;
      }

      &--in-container {
        margin: 20px 0;
      }

      &--request-new {
        cursor: pointer;
        margin-right: 0;
        text-align: center;
        border: solid 2px $elo-grey-40;
        border-radius: 25px;
        background-color: $elo-grey-light-20;

        a {
          font-size: 15px;
          font-weight: 500;
          color: $elo-grey;
          text-decoration: none;
        }
      }
    }

    .fields-container__text-with-separators {
      font-size: 15px;
    }

    .prefix-icon {
      font-size: var(--elo-font-size-lg);
      padding-bottom: 0;
    }

    .elo-btn-container {
      margin-top: 40px;
    }
  }
}
