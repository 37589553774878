@import 'src/ui-kit/styles/colors';

table.white-table {
  border-collapse: collapse;
  th,
  td {
    vertical-align: middle;
    &:last-child,
    &:last-child {
      padding-right: 15px;
    }
    &:first-child,
    &:first-child {
      padding-left: 15px;
    }
    a,
    a:hover,
    a:active,
    a:visited {
      text-decoration: none;
    }
    &:not(:last-child) {
      a {
        color: $elo-black-semi;
        &:hover {
          color: $elo-blue;
        }
      }
    }
  }

  thead tr {
    height: 44px;
    line-height: 1;
    th {
      border: none;
      background-color: var(--table-head-bg-color);
      font-size: var(--elo-font-size-xs);
      font-weight: 500;
      color: var(--table-head-font-color);
      text-transform: capitalize;
      font-family: var(--elo-font-sans-serif);
      user-select: none;
      &:first-child {
        border-radius: 4px 0px 0px 4px;
      }
      &.sortable {
        .sortable__container {
          display: flex;
          align-items: center;
          cursor: pointer;

          i {
            font-size: 14px;
            margin-bottom: 2px;
          }
        }
      }
      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
  tbody tr {
    height: 55px;
    font-size: 13px;
    color: $elo-black-semi;
    line-height: 1;
    .fa-pencil-alt,
    .fa-trash-alt {
      cursor: pointer;
      color: $elo-blue;
      &:hover {
        color: $elo-blue-60;
      }
      &:active {
        color: $elo-blue-70;
      }
    }
    td {
      border-top: none;
      border-bottom: solid 1px rgba($elo-grey, 0.2);
      line-height: 1.2;
      &.title {
        font-family: var(--elo-font-sans-serif);
      }
      &.italic {
        font-style: normal;
      }
      &.copyable-url {
        font-size: 10px;
      }
      .centered-div {
        display: flex;
        align-items: center;
      }
    }
  }
}
