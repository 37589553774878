@import 'src/ui-kit/styles/colors';

$content-max-width: 1140px;

.course-preview-modal {
  max-width: inherit;
  margin: 0;
  min-height: 100vh;
  background-color: $elo-white;

  &--no-content {
    display: flex;
    justify-content: center;

    i {
      display: block;
      margin-bottom: 10px;
      color: $elo-orange;
      font-size: 30px;
    }

    .course-preview-modal__no-lessons-message {
      width: 100%;
      height: 100vh;
      padding: 40px;

      &-centered {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        img {
          margin-bottom: 69px;
        }
      }

      &-title {
        margin-bottom: 14px;
        font: var(--elo-heading-h2);
        color: var(--color-primary-dg-brand);
        font-weight: 500;
      }

      &-subtitle {
        margin-bottom: 24px;
        font: var(--elo-body-sm-regular);
        color: var(--color-primary-dg-500);
      }
    }
  }

  .course-preview {
    display: flex;
    justify-content: left;
    margin-top: 60px;

    .round-image-36 {
      min-height: 36px;
      min-width: 36px;
      border-radius: 18px;
      background-size: cover;
    }

    .lesson-page {
      width: calc(100% - 295px);
      min-height: 100vh;
      background-color: $elo-grey-light-20;
      transition: margin-left 0.3s;
      margin-left: 295px;
      word-break: break-word;

      .white-widget {
        margin: 30px;
      }

      &__banner {
        margin: 30px;

        &--custom {
          margin: 30px 0;
        }

        &--comma-separated:not(:last-child)::after {
          content: ', ';
        }
      }

      &__title {
        display: inline-block;
        text-align: left;
        font-family: var(--elo-font-sans-serif);
        font-size: 18px;
        color: $elo-black-semi;
      }

      .quiz-attempt {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .attempt-info {
          display: flex;
          align-items: center;

          i {
            font-size: 30px;
          }

          span {
            font-family: Montserrat Reg;
            font-size: 13px;
            font-style: normal;
            color: $elo-grey-dark;
          }

          .attempt-status {
            font-size: 18px;
            font-family: var(--elo-font-sans-serif);
            font-style: normal;
            margin-left: 7px;
            margin-right: 30px;
          }
        }

        .points {
          display: inline;
          margin-left: 20px;
          font-family: var(--elo-font-sans-serif);
          font-size: 18px;
          font-weight: bold;
          color: $elo-grey;

          .all {
            color: $elo-grey-dark;
          }
        }
      }

      .waiting {
        i {
          color: $elo-orange;
        }

        .attempt-status {
          color: $elo-orange !important;
        }
      }

      .failed {
        i {
          color: $elo-red;
        }

        .attempt-status {
          color: $elo-red !important;
        }

        .gained {
          color: $elo-red !important;
        }
      }

      .completed {
        i {
          color: $elo-green;
        }

        .attempt-status {
          color: $elo-green !important;
        }

        .gained {
          color: $elo-green !important;
        }
      }

      .quiz-afterword {
        display: flex;
        align-items: flex-end;
        overflow: auto;

        .author-comment {
          margin-left: 25px;

          .author {
            display: block;
            font-family: var(--elo-font-sans-serif);
            font-size: 13px;
            color: $elo-black-semi;
          }

          .comment {
            display: block;
            font-family: Montserrat Reg;
            font-size: 13px;
            color: $elo-black-semi;
          }
        }
      }

      .blocked-text {
        text-align: center;
        margin: 100px 0 160px;

        i {
          display: block;
          font-size: 144px;
          color: $elo-grey;
          margin-bottom: 50px;
        }

        .quiz-complete {
          display: block;
          max-width: 600px;
          font-family: Montserrat Reg;
          font-size: 14px;
          text-align: center;
          color: $elo-black-semi;
          margin-left: auto;
          margin-right: auto;
        }

        .will-be {
          font-family: var(--elo-font-sans-serif);
          font-size: 14px;
          font-weight: 500;
          color: $elo-grey;
        }
      }

      .Countdown {
        word-break: break-word;

        .Countdown-col-element {
          font-family: var(--elo-font-sans-serif);
          color: $elo-grey-dark;
          margin-right: 10px;

          strong {
            font-size: 48px;
            font-weight: bold;
            margin-right: 10px;
          }

          span {
            font-size: 24px;
          }
        }
      }
    }

    .content-pagebuilder-view .content-block-filelist {
      .file-wrapper .text-side {
        max-width: 500px;
      }
    }

    @media (max-width: 991px) {
      .course-side-nav {
        position: fixed;
      }
      .lesson-page {
        margin-left: 0;
        width: 100%;

        .white-widget {
          margin-left: -10px !important;
          margin-right: -10px !important;
          padding-left: 20px;
          padding-right: 20px;
        }

        .lesson-comments {
          margin-left: -10px !important;
          margin-right: -10px !important;
          padding-left: 20px;
          padding-right: 20px;
        }
      }

      .content-pagebuilder-view .content-block-filelist {
        .file-wrapper .text-side {
          max-width: 80%;
        }
      }
    }
  }

  .course-theme-default {
    .course-side-nav {
      margin-top: 0;
      height: 100%;

      @media (max-width: 991px) {
        margin-top: 60px;
      }
    }
  }
}
