@import 'src/ui-kit/styles/colors';

.switcher {
  float: right;
  font-size: 12px;
  color: $elo-grey;
  .view-type {
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      color: $elo-grey-40;
    }
    &:first-child {
      margin-right: 15px;
    }
  }
}

.goods-header {
  background: $elo-blue;
  padding: 5px 10px;
  margin: 20px 0px 10px;
  .name-editable {
    float: left;
    color: $elo-white;
    padding-top: 1px;
  }
  .switcher {
    color: $elo-white;
  }
}

.goods,
.digitals {
  &.list,
  & > {
    .item {
      &.audio-player {
        .name {
          .inline-edit {
            float: left;
            max-width: 100%;
          }
        }
      }
      .move {
        background-color: $elo-grey-light-10;
        color: $elo-grey-dark;
        cursor: move;
        .fas {
          cursor: move !important;
        }
      }
      .col.icon {
        position: relative;
      }
      .duration {
        font-size: 10px;
        cursor: default;
      }
      .multiline {
        cursor: default;
      }
      .icon {
        padding: 5px;
        .audio-button {
          cursor: pointer;
          color: $elo-orange;
          &.fas {
            font-size: 30px !important;
          }
          &:before {
            content: '\f144';
          }
          &.play {
            &:before {
              content: '\f04c';
            }
          }
          audio {
            display: none;
          }
        }
      }
      .remove {
        text-align: right;
        a {
          color: $elo-orange-60;
        }
      }
    }
  }
  &.list {
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
    .clearfix {
      display: none !important;
    }
    .item.good {
      position: relative;
      display: table-row;
      background-color: $elo-grey-light-10;
      width: 100%;
      border-radius: 4px;
      text-decoration: none;
      color: inherit;
      &:hover {
        background-color: $elo-grey-light-20;
      }
      &.ui-sortable-helper {
        border-spacing: 0;
      }
      &.audio-player {
        .col.icon {
          padding: 0 5px;
          text-align: center;
        }
        .name {
          .inline-edit.edit {
            float: none;
          }
        }
      }
      .move {
        width: 25px;
        padding: 5px;
        height: 100%;
        font-size: 16px;
      }
      .col {
        display: table-cell;
        vertical-align: middle;
        padding: 5px;
        &.multiline {
          @extend .wrap-words;
        }
        &:not(.multiline) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }
      .icon {
        .fa-play-circle,
        .fa-pause {
          padding: 5px;
        }
        a {
          color: $elo-black;
        }
        img {
          width: 35px;
          height: 35px;
        }
        .fa,
        .fas {
          font-size: 35px;
        }
      }
      .name {
        width: 100%;
      }
      .size {
        text-align: right;
      }
      .remove,
      .add,
      .download {
        font-size: 20px;
        .fas {
          cursor: pointer;
        }
      }
      .add {
        color: $elo-green;
        .fas {
          vertical-align: middle;
        }
      }
    }
  }
}
