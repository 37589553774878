@import 'src/ui-kit/styles/colors';

$pink: #e65078;
$pink-hover: #f75c85;
$pink-active: #c83a60;

.elo-btn {
  font-family: var(--elo-font-sans-serif);
  font-size: 12px;
  border: 0;
  box-shadow: none;
  outline: none;
  background-color: $elo-grey-40;
  color: $elo-white;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    box-shadow: none;
    outline: none;
    color: $elo-white;
    text-decoration: none;
  }

  // SIZING
  &.x-small {
    min-width: 96px;
    border-radius: 17px;
    padding: 5px;
  }
  &.small {
    min-width: 136px;
    border-radius: 17px;
    padding: 8px;
  }
  &.medium {
    min-width: 237px;
    border-radius: 17px;
    padding: 10px;
  }
  &.large {
    min-width: 127px;
    font-size: 16px;
    padding: 10px;
    border-radius: 22px;
  }
  &.x-large {
    font-size: 16px;
    padding: 14px 13px 13px;
    border-radius: 25px;
  }

  &.full-size {
    width: 100%;
    font-size: 16px;
    padding: 18px 0px 18px 0px;
    border-radius: 25px;
  }

  // COLORS
  &.orange:not(:disabled) {
    background-color: $elo-orange;
    &:hover {
      background-color: $elo-orange-60;
    }
    &:active {
      background-color: $elo-orange-70;
    }
  }
  &.regular:not(:disabled) {
    background-color: $elo-blue;
    font-size: 12px;
    &:hover {
      background-color: $elo-blue-60;
    }
    &:active {
      background-color: $elo-blue-70;
    }
  }
  &.close:not(:disabled),
  &.back:not(:disabled) {
    border-radius: 18px;
    border: solid 1px $elo-grey-40;
    color: $elo-grey;
    background-color: transparent;
    &:hover {
      border-color: $elo-grey-dark;
    }
    &:active {
      border-color: $elo-black-semi;
    }
  }
  &.red:not(:disabled) {
    background-color: $pink;
    &:hover {
      background-color: $pink-hover;
    }
    &:active {
      background-color: $pink-active;
    }
  }
  &.outline-blue:not(:disabled) {
    color: $elo-blue;
    border: solid 1px $elo-blue;
    background-color: transparent;
    padding-top: 8px;
    padding-bottom: 6px;
    &:hover {
      border-color: $elo-blue;
      color: $elo-blue;
      opacity: 0.65;
    }
    &:active {
      border-color: $elo-blue;
      color: $elo-blue;
    }
  }

  &.outline-grey:not(:disabled) {
    border: solid 1px $elo-grey-40;
    color: $elo-grey;
    background-color: transparent;
    padding-top: 8px;
    padding-bottom: 6px;
    &:hover {
      border-color: $elo-grey-dark;
    }
    &:active {
      border-color: $elo-black-semi;
    }
  }

  &.outline-white:not(:disabled) {
    border: solid 1px $elo-white;
    color: $elo-white;
    background-color: transparent;
    padding-top: 8px;
    padding-bottom: 6px;
    &:hover {
      border-color: $elo-white;
    }
    &:active {
      border-color: $elo-white;
    }
  }
}
