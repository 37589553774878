@import 'src/ui-kit/styles/colors';

.product-list {
  &__show-tickets-button {
    border: none;
    display: flex;
    justify-content: center;
    padding: 0 6px;
    width: 100%;
    background: none;

    .fa-caret-right {
      color: var(--table-caret-font-color);
      font-size: 15px;

      &:focus {
        outline: none;
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__show-tickets-button--open {
    .fa-caret-down {
      font-size: 15px;
      color: var(--table-caret-font-color);

      &:focus {
        outline: none;
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__table {
    width: 99.4%;
    margin: 0 3px;

    td:last-child {
      position: relative;
      width: 52px;
    }
  }

  &__show-tickets-title {
    padding: 0 !important;
  }

  &__event-row--open {
    background-color: var(--table-body-bg-color);
  }

  &__ticket-row {
    background-color: var(--table-first-level-row-bg-color);
    animation-name: opacityRowsAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    border-bottom: solid 1px var(--table-row-border-color);

    .loading-spinner {
      &__loader {
        margin: 10px auto;
      }
    }
  }

  table.white-table,
  .elo-table {
    .product-list__ticket-row--no-tickets {
      td {
        text-align: center;
      }
    }

    tbody {
      .product-list__event-row--open,
      .product-list__ticket-row {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .table {
    td {
      padding-top: 0;
      padding-bottom: 0;
    }

    td:first-child {
      padding: 0;
    }
  }

  table.white-table tbody tr.row--not-published {
    color: var(--table-body-font-color);

    .product-list__product-link-id,
    .product-list__product-link-name {
      color: var(--table-link-font-color);

      &:hover {
        text-decoration: none;
        color: var(--table-link-font-color-hover);
      }
    }
  }

  &__product-link-id,
  &__product-link-name {
    color: var(--table-link-font-color);

    &:hover {
      text-decoration: none;
      color: var(--table-link-font-color-hover);
    }
  }

  &__product-link-name {
    display: flex;
    align-items: center;
  }

  &__view-buttons {
    display: flex;
    justify-content: flex-end;
    line-height: 0;
    margin-bottom: 8px;
  }

  .elo-table-navigation {
    margin-bottom: 8px;
    width: 99.4%;
    margin-left: 3px;
  }

  &__can-sell-icon,
  &__green-icon {
    font-size: 20px;
    color: $elo-green;
  }

  &__can-not-sell-icon {
    font-size: 20px;
    color: $elo-grey-40;

    &:hover {
      color: $elo-grey;
    }
  }

  &__can-not-sell-tooltip {
    .tooltip-inner {
      box-shadow: 0 0 15px 0 rgba($elo-black, 0.2);
    }
  }

  &__price {
    display: flex;
    align-items: center;
  }

  &--with-event-product {
    .table td:nth-child(2),
    .table th:nth-child(2) {
      padding-left: 0;
    }

    .product-list__show-tickets-toggle {
      width: 25px;
      padding-left: 0 !important;
    }
  }
}

.product-details {
  min-width: 150px;
  display: flex;
  align-items: center;

  &__cover {
    margin-right: 15px;

    img {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 37px;
      // height: 37px;
      position: relative;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  &__text {
    font-family: var(--elo-font-sans-serif);
    align-items: center;
    max-width: 500px;
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__text--published {
    color: $elo-grey-dark;
  }
}

@keyframes opacityRowsAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
