@import 'src/ui-kit/styles/colors';

.upsell-creation {
  padding: 10px;
  border: 1px solid $elo-grey-40;
  border-top: 0;
  .has-popover {
    background-color: inherit;
  }
}

div.upsell-empty {
  .title {
    margin-bottom: 15px;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.9px;
    color: $elo-grey;
  }
  .sub-title {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.4px;
    color: $elo-black-semi;
  }
  .hint {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.3px;
    text-align: left;
    color: $elo-grey;
  }
}

div.upsells-list {
  div.upsell-preview.postsell {
    zoom: 0.45;
    .preview-hint {
      max-height: 415px;
      overflow-y: auto;
    }
  }

  .add-upsell-block {
    text-align: center;
    padding: 80px 0;
    .plus {
      font-size: 40px;
      margin-bottom: 30px;
      color: $elo-grey-40;
    }
  }
  .actions {
    span {
      position: absolute;
      left: 32px;
      white-space: nowrap;
    }
    margin-top: 10px;
    text-align: center;
  }
  a .fas {
    margin-left: 15px;
  }
}

div.upsell-form {
  border: solid 1px $elo-grey-40;
  .box-widget-block {
    padding: 25px 30px;
    border-bottom: 1px solid $elo-grey-40;
    .net-prices-checkbox {
      margin: 20px 0;
      .switcher-label {
        margin-top: 5px;
      }
      input {
        position: absolute;
      }
    }
    .box-title {
      color: $elo-blue;
      margin-bottom: 15px;
      .left {
        font-size: 24px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: -0.6px;
        float: left;
      }
      .right {
        float: right;
        font-size: 14px;
        line-height: 1.14;
        letter-spacing: -0.3px;
        text-align: right;
        color: $elo-blue;
        margin-top: 8px;
      }
    }
    .box-block {
      background-color: $elo-grey-light-10;
      border: solid 1px $elo-grey-40;
    }
  }

  .step-subtitle {
    padding-bottom: 15px;
    border-bottom: 1px solid $elo-grey-light-20;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.6px;
    color: $elo-blue;
    label {
      font-weight: 600;
    }
    .step-info {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.4px;
      color: rgba($elo-black-semi, 0.7);
    }
  }
  .right-border {
    border-right: 1px solid $elo-grey-40;
  }
  .main-product {
    .box-block {
      padding: 15px;
      .product-info {
        img {
          width: 60px;
          height: 60px;
          border-radius: 4px;
          display: inline-block;
          vertical-align: middle;
        }
        .no-cover {
          width: 60px;
          height: 60px;
          border-radius: 4px;
          background: $elo-grey-light;
          font-size: 20px;
          text-align: center;
          display: inline-block;
          vertical-align: middle;
          i {
            margin-top: 20px;
            color: $elo-grey-40;
          }
        }
        .name {
          margin-left: 15px;
          font-size: 20px;
          font-weight: 600;
          line-height: 1.2;
          letter-spacing: -0.5px;
          text-align: left;
          color: $elo-black;
          display: inline-block;
          vertical-align: middle;
        }
      }
      .tickets-select {
        input {
          width: 50px;
        }
        select {
          width: 250px;
        }
        select,
        input {
          background-color: $elo-white;
          margin: 8px 0 0 10px;
          float: right;
        }
      }
    }
  }
  .addons {
    .upsell-image-container {
      span {
        display: none;
      }
    }
    .box-block {
      .addons-list {
        max-height: 270px;
        padding: 15px 15px 0 15px;
        overflow: auto;
        .addons-item {
          position: relative;
          margin-bottom: 15px;
          label {
            margin: 0;
            display: block;
            background-color: $elo-white;
            border: solid 1px $elo-grey-40;
            cursor: pointer;
            min-height: 102px;
            img {
              width: 100px;
              height: 100px;
              position: absolute;
            }
            .no-cover {
              width: 100px;
              height: 100px;
              position: absolute;
              background: $elo-grey-light;
              font-size: 40px;
              text-align: center;
              i {
                margin-top: 30px;
                color: $elo-grey-40;
              }
            }
            .name {
              margin-left: 100px;
              padding: 15px;
              display: inline-block;
              vertical-align: top;
              font-size: 18px;
              font-weight: 600;
              line-height: 1.33;
              letter-spacing: -0.4px;
              text-align: left;
              color: $elo-black-semi;
            }
            &:hover {
              border: solid 1px $elo-blue-60;
              background-color: $elo-blue-60;
              transition: 0.3s;
              span {
                color: $elo-white;
              }
            }
          }
          input:checked ~ label {
            border: solid 1px $elo-blue-60;
            background-color: $elo-blue-60;
            transition: 0.3s;
            span {
              color: $elo-white;
            }
          }
          input {
            position: absolute;
            right: 15px;
            top: 12px;
          }
        }
      }
      .addons-select-wrap {
        background-color: $elo-white;
        padding: 15px;
        text-align: center;
        border-top: 1px solid $elo-grey-40;
        box-shadow: 0 -2px 5px 0 rgba($elo-black, 0.14);
        position: relative;
      }
    }
    .selected-addons-list {
      .selected-addons-item {
        border-radius: 3px;
        background-color: $elo-grey-light-10;
        border: solid 1px $elo-grey-40;
        margin-bottom: 15px;
        position: relative;
        min-height: 130px;
        img {
          width: 100px;
          height: 100px;
          padding: 15px;
          box-sizing: content-box;
          position: absolute;
        }
        .no-cover {
          width: 100px;
          height: 100px;
          position: absolute;
          margin: 15px;
          box-sizing: content-box;
          background: $elo-grey-light;
          font-size: 40px;
          text-align: center;
          i {
            margin-top: 30px;
            color: $elo-grey-40;
          }
        }
        .pricing-plans-info {
          position: absolute;
          right: 15px;
          top: 15px;
          border: solid 1px $elo-blue-60;
          text-align: center;
          border-radius: 2px;
          padding: 2px 5px 2px 6px;
          cursor: pointer;
          i {
            color: $elo-blue-60;
          }
          &:hover {
            .pricing-plans-info-details {
              display: block;
            }
          }
          .pricing-plans-info-details {
            display: none;
            padding: 10px;
            position: absolute;
            right: -15px;
            top: 30px;
            background-color: $elo-white;
            border: solid 1px $elo-grey-40;
            box-shadow: 0 4px 8px 0 rgba($elo-black, 0.1);
            width: 400px;
            z-index: 10;
            border-radius: 3px;
            text-align: left;
            &:after,
            &:before {
              bottom: 100%;
              right: 5%;
              border: solid transparent;
              content: ' ';
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
            }

            &:after {
              border-color: rgba($elo-white, 0);
              border-bottom-color: $elo-white;
              border-width: 7px;
              margin-left: -7px;
            }
            &:before {
              border-color: rgba($elo-grey-40, 0);
              border-bottom-color: $elo-grey-40;
              border-width: 8px;
              margin-left: -8px;
            }
          }
        }
        .info {
          display: inline-block;
          vertical-align: top;
          margin: 15px 15px 15px 130px;
          .name {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.33;
            letter-spacing: -0.4px;
            text-align: left;
            color: $elo-black-semi;
            margin-right: 40px;
            margin-bottom: 10px;
          }
        }
        .tickets-select {
          input {
            width: 50px;
          }
          select {
            width: 250px;
          }
          select,
          input {
            background-color: $elo-white;
            margin: 8px 10px 0 0;
          }
        }
      }
    }
  }
  .upsell-details {
    .box-block {
      padding: 15px;
    }
  }
  .tracking-codes,
  .webhook-endpoints {
    .col {
      padding: 5px;
      margin-left: 20px;
    }
    .box-block {
      padding: 10px;
      .description {
        color: $elo-grey;
        font-size: 13px;
        font-style: normal;
      }
    }
  }
}

div.upsell-wrap {
  .inline-edit-title {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: rgba($elo-black-semi, 0.7);

    .input-sm {
      height: 22px;
      padding: 2px;
    }

    .plain-text {
      cursor: default;
      display: inline;
    }

    .edit-icon {
      display: inline-block;
      font-size: 16px;
      cursor: pointer;
      color: $elo-blue-60;
      margin-left: 10px;
    }
  }
}

.preview-actions {
  margin-bottom: 10px;
}
