@import 'src/ui-kit/styles/colors';

$light-orange-10: #fff3cd;
$light-orange-20: #ffeeba;
$dark-orange: #856404;
$light-blue: #80bdff;
$green: #2dc260;

.payment-page {
  .product-upsell {
    &:not(:first-child) {
      margin-top: 15px;
    }
    border-radius: 6px;
    border: 1px solid $elo-grey-light-20;
    &__active {
      border-color: $elo-blue-60;
    }
  }
  .secure-info {
    margin-top: 25px;
    &__footer {
      font-size: var(--elo-font-size-xs);
      text-align: right;
      padding-top: 15px;
      border-top: 1px solid $elo-grey-light;
      margin-top: 15px;
    }
    &__link {
      text-decoration: underline;
      color: $elo-grey-dark;
      margin-left: 15px;
      font-size: var(--elo-font-size-xs);
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
    .title {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: -0.3px;
      color: rgba($elo-black-semi, 0.7);
      padding-bottom: 10px;
      @media (min-width: 768px) {
        font-size: 16px;
      }
    }
    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 25px 25px;

      .icon-grayscale {
        filter: grayscale(1);
      }
    }
    @media (max-width: 767px) {
      border-top: none;
      background-color: $elo-white;
      margin-left: -15px;
      margin-right: -15px;
      padding: 0 15px;
      .title {
        border-bottom: 1px solid $elo-grey-light-10;
      }
      .icons {
        .icon {
          img {
            max-height: 25px;
            max-width: 100%;
          }
        }
      }
    }
  }
  .embeded-page-wrapper {
    .payment-tickets .cut-out-left,
    .payment-tickets .cut-out-right {
      background-color: $elo-white;
    }
    position: relative;
    background-color: $elo-white;
    box-shadow: 0 2px 10px 0 rgba($elo-black, 0.2);
    padding: 40px;
    border-radius: 6px;
    .header,
    .upsell-preview,
    .widget-wrap {
      box-shadow: 0 2px 10px 0 rgba($elo-black, 0.2);
    }
  }
  .country-select-module__control {
    height: 20px;
  }

  .country-select-module__single-value {
    color: $elo-black-semi;
  }

  .country-select-module__value-container {
    padding: 0 8px;
  }
  &.embeded {
    display: flex;

    @media (min-width: 320px) {
      padding: 0;
      border-radius: 0;
      .container-fluid {
        padding: 0;
      }
    }
  }
  &.two_steps,
  &.two_steps_advanced {
    .next-btn {
      margin-top: 33px;
      font-size: 16px;
    }
    .steps-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $elo-grey-light-20;
      font-size: 16px;
      font-family: var(--elo-font-sans-serif), serif;
      .menu-item {
        .arrow {
          border-top: 30px solid transparent;
          border-bottom: 30px solid transparent;
          border-left: 12px solid $elo-grey-light;
          position: absolute;
          right: -12px;
          z-index: 1;
        }
        &:first-child {
          border-radius: 6px 0 0 0;
        }
        position: relative;
        flex: 1;
        text-align: left;
        padding-left: 43px;
        border-radius: 6px 6px 0 0;
        height: 60px;
        align-items: center;
        display: flex;
        cursor: pointer;
        user-select: none;
        color: $elo-grey;
        background-color: $elo-grey-light;
        &.active {
          background-color: $elo-white;
          color: $elo-black-semi;
          .arrow {
            border-left-color: $elo-white;
          }
        }
        &:not(.active):hover {
          background-color: $elo-grey-40;
          .arrow {
            border-left-color: $elo-grey-40;
          }
        }
      }
    }
  }
  &.default {
    .widget-wrap {
      border: solid 1px $elo-grey-light-20;
    }

    .pricing-content {
      .payment-plans-title {
        font-family: var(--elo-font-sans-serif);
        font-weight: 600;
        color: $elo-black-semi;
      }
    }

    .purchase {
      @media (max-width: 991px) {
        margin-top: 15px;
      }
    }
  }
  &.simplified,
  &.two_steps,
  &.two_steps_advanced {
    .header {
      border: solid 1px $elo-grey-light-20;

      @media (max-width: 576px) {
        &--hidden {
          border: unset;
        }
      }
    }
    .event-ticket {
      border: solid 1px $elo-grey-light-20;
      .cut-out-left,
      .cut-out-right {
        display: none;
      }
    }
    .pricing-content {
      padding-top: 0;

      .payment-plans-title {
        font-family: var(--elo-font-sans-serif);
        font-weight: 600;
        color: $elo-black-semi;
      }

      &--with-bottom-margin {
        margin-bottom: 50px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .widget-wrap {
      border: solid 1px $elo-grey-light-20;
    }
    .brl-lg {
      border-left: 0;
    }
    .header,
    .payment-tickets,
    .upsells {
      .col-lg-4,
      .col-lg-8 {
        @include media-breakpoint-up(lg) {
          @include make-col(12);
        }
      }
    }
  }

  &.two_steps_advanced {
    .pricing-content {
      .payment-plans-title {
        font-family: var(--elo-font-sans-serif);
        font-weight: 600;
        color: $elo-black-semi;
      }

      .widget-line {
        margin: 30px -44px;
      }
    }

    .purchase-content,
    .ticket-step-content {
      .widget-line {
        margin: 30px -44px;
      }
    }
  }

  // Those styles used only for checkout preview in the cabinet, not for real page
  &.cabinet-preview {
    zoom: 0.5;

    // hack for firefox as zoom is not supported
    @-moz-document url-prefix() {
      transform: scale(0.6);
      transform-origin: top left;
      width: 167%;
      height: 167%;
    }
  }

  &.default.cabinet-preview {
    &--desktop {
      .container {
        max-width: 100%;
      }

      .header {
        .header-name {
          font-size: 28px !important;
          width: 64% !important;
        }

        .header-price {
          display: block !important;
          margin: 0 !important;
        }
      }

      .header-product-wrap {
        .row {
          flex-wrap: nowrap;
        }
      }

      .preview-content {
        padding: 30px 15px !important;
      }

      .pricing-content {
        padding: 30px 15px !important;
        background-color: unset !important;
      }

      .widget-wrap {
        .widget-box {
          .custom-col {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 30px;
          }
        }
      }

      .brl-lg {
        border-left: 1px solid $elo-grey-light;
      }

      .checkout-page-bubble {
        left: 0;
        margin-left: 0;
      }
    }

    &--mobile {
      .container {
        max-width: 576px;
      }

      .payment-plans {
        .payment-plans-title {
          padding-top: 0 !important;
        }
      }
    }
  }

  &.simplified.cabinet-preview,
  &.two_steps.cabinet-preview {
    &--desktop {
      .container {
        max-width: 100%;
      }

      .header {
        .header-name {
          font-size: 28px !important;
          width: 64% !important;
        }

        .header-price {
          display: block !important;
          margin: 0 !important;
        }
      }

      .preview-content {
        padding: 30px 15px !important;
      }

      .pricing-content {
        padding: 30px 15px !important;
        background-color: unset !important;
      }

      .checkout-page-bubble {
        left: 0;
        margin-left: 0;
      }
    }

    &--mobile {
      .container {
        max-width: 576px;
      }

      .payment-plans {
        .payment-plans-title {
          padding-top: 0 !important;
        }
      }
    }
  }

  &.two_steps_advanced.cabinet-preview {
    &--desktop {
      .container {
        max-width: 100%;
      }

      .header {
        .header-name {
          font-size: 28px !important;
          width: 64% !important;
        }

        .header-price {
          display: block !important;
          margin: 0 !important;
        }
      }

      .checkout-page-bubble {
        left: 0;
        margin-left: 0;
      }
    }

    &--mobile {
      .container {
        max-width: 576px;
      }
    }
  }
}

.payment-page {
  font-family: var(--elo-font-sans-serif), serif;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 20px 0;
  background-color: $elo-grey-light;

  &:not(.embeded) {
    min-height: calc(100vh - 66px);
  }

  &--footer-with-header {
    @media (min-width: 991px) {
      &:not(.embeded) {
        min-height: calc(100vh - 91px - 66px);
      }
    }
  }

  .trust-seal-custom-image {
    max-height: 32px;
  }

  // GENERAL
  .container-title {
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
    color: $elo-black-semi;
    font-size: var(--elo-font-size-lg);
    margin-bottom: 15px;
  }
  .widget-line {
    height: 1px;
    background-color: $elo-grey-light;
    margin: 30px 0;
    @include media-breakpoint-down(md) {
      margin: 25px 0;
    }
    & + .widget-line {
      display: none;
    }
  }
  .widget-wrap {
    border-radius: 6px;
    background-color: $elo-white;
    box-shadow: 0 4px 8px 0 rgba($elo-black, 0.03);

    .widget-box {
      @include make-row();
      padding: 30px 15px;

      @include media-breakpoint-down(md) {
        padding: 25px 15px 10px 15px;
      }
      .custom-col {
        @include make-col-ready();
        @include media-breakpoint-up(lg) {
          @include make-col(6);
          padding: 0 30px;
        }
      }
      .custom-col-12 {
        @include make-col-ready();
        @include media-breakpoint-up(lg) {
          @include make-col(12);
          padding: 0 44px;
        }
      }
      .full-column {
        flex: 0 0 100%;
        max-width: 100%;
      }

      &.hidden {
        display: none;
      }
    }
    @include media-breakpoint-down(md) {
      .payers-container {
        padding: 25px 20px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .brl-lg {
      border-left: 1px solid $elo-grey-light;
    }
  }

  // HEADER
  .header {
    margin-bottom: 20px;
    background-color: $elo-white;
    border-radius: 5px;
    border: 0;
    box-shadow: 0 4px 8px 0 rgba($elo-black, 0.03);
    &.merged {
      margin-bottom: 0;
      border-bottom: 1px solid $elo-grey-light;
    }
    &.selected {
      border: solid 1px $elo-blue-60;
    }
    .header-product-wrap {
      padding: 0 30px;
    }
    .header-count-wrap {
      background-color: $elo-grey-40;
      border-radius: 0 0 4px 4px;
      padding: 10px 44px;
      display: flex;
      align-items: center;
      .header-count-label {
        font-size: 12px;
        color: $elo-black-semi;
        margin: 0 10px 0 0;
      }
      .limit-help {
        color: var(--elo-dark-grey);
        font-size: 12px;
        margin-left: 10px;
        font-style: normal;
      }
    }
    #product-name-show {
      margin-top: 2px;
      font-size: 24px;
    }
    .preview-content {
      padding-top: 30px;
      padding-bottom: 30px;
      width: 100%;

      &.cursor-pointer {
        cursor: pointer;
      }

      &--in-row {
        display: flex;
        justify-content: space-between;
        column-gap: 5px;

        .header-price {
          min-width: fit-content;

          .price {
            font-size: 24px;
          }

          @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            margin-bottom: 20px;

            .price {
              align-self: baseline;
              order: 2;
            }

            .old-price {
              align-self: baseline;
              order: 1;
            }
          }
        }

        @media (max-width: 576px) {
          flex-wrap: wrap;
        }
      }

      &__flex-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .payment-plan {
          &__countdown {
            align-self: flex-end;
            margin-top: 0;
            margin-bottom: 15px;
          }
        }

        .header-description {
          margin-top: 0;
        }
      }
    }
    .pricing-content {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .header-image-wrap {
      position: relative;
      .header-image {
        height: 145px;
        width: 145px;
        border-radius: 4px;
        background-size: cover;
        background-position: center;
        background-color: $elo-grey-light;
        margin: 0 auto;
      }
    }
    .header-name {
      font-size: 28px;
      font-weight: bold;
      color: $elo-black-semi;
      word-break: break-word;

      .field {
        font-size: 28px;

        @media (max-width: 991px) {
          font-size: 18px;
        }
      }

      .checkbox-field__label,
      span {
        font-family: var(--elo-font-sans-serif);
        font-weight: 400;
      }

      .checkbox-field__label--active {
        color: $elo-black-semi;
      }
    }
    .header-content {
      &--default {
        @media (max-width: 576px) {
          display: none;
        }
      }
    }
    .stock-count {
      font-size: var(--elo-font-size-sm);
      font-family: var(--elo-font-sans-serif);
      color: var(--elo-green-60);
      margin-top: 10px;
    }
    .header-locations {
      font-size: 12px;
      line-height: 1.5;
      margin-bottom: 20px;
      color: $elo-grey;
      .fas {
        width: 14px;
        font-size: 14px;
      }
    }
    .header-description {
      font-size: 14px;
      line-height: 1.3;
      color: $elo-black-semi;
      word-wrap: break-word;
      margin-top: 8px;
    }
    .read-more-trigger {
      cursor: pointer;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 13px;
      width: 96px;
      height: 28px;
      border-radius: 14px;
      background-color: $elo-grey-light-20;
      font-size: var(--elo-font-size-xs);
      color: $elo-grey;
      outline: none;
    }
    .read-more-state {
      display: none;
    }
    @include media-breakpoint-down(md) {
      .header-product-wrap {
        padding: 0 15px;
      }
      .header-count-wrap {
        padding: 5px 15px;
      }
      .preview-content {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .pricing-content {
        padding: 25px 0;
      }
    }
    @include media-breakpoint-down(md) {
      .header-name {
        font-size: 18px;
        width: 100%;
      }
    }

    @media (max-width: 576px) {
      &--hidden {
        .preview-content {
          padding: 0;
        }
      }
    }
  }

  .collapsible-header {
    display: none;

    @media (max-width: 576px) {
      display: block;
      margin-bottom: 20px;
    }
  }

  // UPSELLS
  .upsells {
    .container-title {
      padding: 0 44px;
      margin-top: 20px;
      @include media-breakpoint-down(md) {
        padding: 0 15px;
      }
    }
    .upsells-list {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;

      & > div {
        margin: 15px 0;
        z-index: 1;
      }

      & > span {
        z-index: 1;
      }

      .upsell-preview {
        margin-bottom: 15px;
      }
    }

    .upsells-list-container {
      border-radius: 6px;
      background-color: $elo-white;
      box-shadow: 0 4px 8px 0 rgba($elo-black, 0.03);
      overflow: auto;
    }

    .plans-list-container {
      margin-bottom: 15px;
      padding: 30px 15px;
    }
  }

  // ATTENDEES
  .attendees {
    .custom-check {
      font-size: 12px;
      color: $elo-grey;
      margin: 25px 0;
    }
    .attendees-empty {
      text-align: center;
      color: $elo-grey;
      font-size: 15px;
      padding: 20px 0;
    }
    .attendees-list {
      .attendee-form {
        &:not(:last-child) {
          padding-bottom: 20px;
          border-bottom: 1px solid $elo-grey-light;
          margin-bottom: 20px;
        }
        .attendee-fields {
          margin-top: 15px;
          label {
            color: $elo-grey;
            font-size: 13px;
          }
          & > div:last-child {
            margin-bottom: 0;
          }
        }
      }
      .attendee-ticket {
        @extend .ticket-small-budge;
        margin-bottom: 10px;
      }
      .attendee-ticket-name {
        font-family: var(--elo-font-sans-serif), serif;
        font-size: 14px;
        color: $elo-black-semi;
        margin-bottom: 10px;
      }
      .attendee-ticket-info {
        font-size: 12px;
        color: $elo-grey;
        margin-bottom: 10px;

        &__additional-text {
          word-break: break-all;
        }

        .fas {
          font-size: 14px;
          color: $elo-grey-40;
          width: 14px;
          text-align: center;
          margin-right: 5px;
        }
        > div {
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .field.checkbox-field {
      margin: 15px 0;
    }

    .field.checkbox-field + .field.checkbox-field {
      margin: 0;
    }

    .details {
      font-size: var(--elo-font-size-sm);
      line-height: 16px;
      color: $elo-grey;
      margin-left: 35px;
      margin-bottom: 30px;
    }
  }

  // PAYER FORMS
  .simplified-payer-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .payer-name {
      color: $elo-black-semi;
      font-size: 14px;
      font-family: var(--elo-font-sans-serif), serif;
      margin-bottom: 8px;
    }
    .payer-street,
    .payer-country,
    .payer-phone {
      color: var(--elo-dark-grey);
      font-size: var(--elo-font-size-xs);
      line-height: 1.2;
    }

    .payer-country {
      margin-bottom: 8px;
    }

    .payer-phone {
      margin-bottom: 0;
    }
    .edit-payer-link {
      align-self: baseline;
      text-decoration: none;
      font-size: 12px;
      color: $elo-blue-60;
      cursor: pointer;
      font-family: var(--elo-font-sans-serif), serif;
      .change-user {
        color: $elo-grey;
      }
    }
  }
  .payer-forms,
  .attendee-form {
    .form-group:not(.country-select) input,
    .form-group .Select-control,
    .title {
      &::placeholder {
        color: $elo-grey-40;
      }

      font-family: var(--elo-font-sans-serif), serif;
      font-size: 12px;
      height: 40px;
      line-height: 40px;
      padding-top: 7px;
    }
    .zip-container {
      .elo-select-field__dropdown-indicator {
        height: 40px;
      }
    }
    .payer-type-switcher {
      font-family: var(--elo-font-sans-serif), serif;
      margin: 15px 0 0 0;
      font-size: 14px;
      text-align: left;
      color: $elo-black-semi;
      display: flex;
      font-weight: 500;
      label {
        line-height: 1.5rem;
      }

      &__separator:not(:last-child) {
        border-right: 1px solid $elo-grey-40;
        color: $elo-grey-light-40;
        margin: 2px 30px 0;
        height: 20px;
      }

      @media (max-width: 380px) {
        flex-wrap: wrap;
        justify-content: space-between;
        &__separator {
          display: none;
        }
      }
    }
    .payer-gift-check {
      font-size: var(--elo-font-size-sm);
      color: $elo-grey;

      &--with-top-margin {
        margin-top: 16px;
      }

      .fas {
        color: $elo-grey-40;
        font-size: 20px;
      }
    }
    .payer-gift-form {
      padding-top: 30px;
    }
    .form-group {
      input.geoapify-autocomplete-input {
        width: 100%;
        border-radius: 0.25rem;
        border: 1px solid $elo-grey-light-50;
        padding-top: 0;
        padding-left: 12px;
        color: $elo-grey-medium;

        &:focus {
          background-color: $elo-white;
          border-color: var(--elo-green);
          outline: 0;
          box-shadow: none;
        }
      }

      &.invalid-street {
        .geoapify-autocomplete-input {
          border-color: var(--field-border-color-error);
          padding-right: calc(1.5em + 0.75rem);
          background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e');
          background-repeat: no-repeat;
          background-position: right calc(0.375em + 0.1875rem) center;
          background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }
        .geoapify-autocomplete-input:focus {
          border-color: var(--field-border-color-error);
          box-shadow: 0 0 0 0.2rem $elo-red-shadow;
        }
        .invalid-feedback {
          display: block;
        }
      }
    }

    .geoapify-autocomplete-items {
      padding-bottom: 10px;
      border-radius: 0 0 0.25rem 0.25rem;
      font-size: 12px;
      color: $elo-grey-medium;

      .secondary-part {
        font-size: 12px;
        color: $elo-grey-medium;
      }

      .active {
        background-color: $elo-blue-60;
        color: $elo-white;

        .secondary-part {
          color: $elo-white;
        }
      }
    }
    .geoapify-autocomplete-items div:hover {
      background-color: var(--elo-light-grey);
    }
    .geoapify-close-button {
      display: none;
    }

    .payment-phone {
      .form-control {
        width: 100%;
      }
    }
  }

  // SUMMARY
  .summary {
    .test-period-description {
      padding: 10px;
      border: 1px solid $elo-grey-40;
      margin-bottom: 15px;
      p {
        margin-bottom: 0;
      }
    }
    .coupon-check {
      margin-bottom: 24px;

      &-content {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        justify-content: space-between;

        & > div {
          width: 100%;
        }

        & > button {
          margin-top: 25px;

          &.disabled {
            background-color: var(--color-primary-cg-brand) !important;
            color: var(--color-primary-dg-300) !important;
          }
        }
      }
    }

    .coupon-applied {
      margin-bottom: 32px;

      &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        margin-bottom: 0;

        & > div {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        &__code {
          font-weight: 600;
          margin-right: 4px;
        }

        &__details {
          font: var(--elo-body-sm-regular);
          color: var(--color-primary-dg-400);
        }
      }
    }

    @media (max-width: 991px) {
      .elo-card {
        min-width: unset;
      }

      .coupon-check-content,
      .coupon-applied-content {
        flex-direction: column;
        align-items: normal;

        & > button {
          justify-content: center;
          margin-top: 0;
        }
      }
    }

    .prices-info {
      margin: 10px 0;
      font-size: 12px;
      color: $elo-grey;
      .prices-item {
        .prices-item-title {
          padding-bottom: 5px;
          padding-top: 20px;
          margin-bottom: 15px;
          font-size: 14px;
          color: $elo-black-semi;
          border-bottom: 1px solid $elo-grey-light;
        }
        .subtotal,
        .additional-fee,
        .vat,
        .discount {
          color: $elo-black-semi;
          font-size: 13px;
          &:not(:last-child) {
            margin-bottom: 20px;
          }
          .val {
            text-align: right;
            color: $elo-black-semi;
          }
          .discount-price {
            color: $elo-green;
          }
        }
        .discount .val {
          color: $green;
        }
        .total {
          font-family: var(--elo-font-sans-serif), serif;
          color: $elo-black-semi;
          font-size: 18px;
          line-height: 1;
          .val {
            text-align: right;
          }
          &.w-coupon {
          }
        }
      }
      .vat-preview {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        color: $elo-black-semi;
        font-size: 13px;
        font-family: var(--elo-font-sans-serif);
      }
      .vat-included {
        &:before {
          content: '*';
          position: absolute;
          left: 0;
          top: 0;
        }
        position: relative;
        font-size: 13px;
        padding-left: 10px;
        color: $elo-grey;
        margin-top: 20px;
      }

      .price-details {
        font-size: 12px;
        padding-left: 5px;
        color: $elo-grey;
        &__link {
          color: $elo-blue;
          padding-left: 4px;
          cursor: pointer;
        }
      }
    }
  }

  // PURCHASE
  .purchase {
    &__buy-button {
      &:disabled {
        background-color: $elo-grey-40 !important;
        color: $elo-white !important;
      }

      &--with-margin {
        margin-top: 25px;
      }
    }

    .opt-in-section {
      border-top: 1px solid rgba($elo-grey, 0.2);
      border-bottom: 1px solid rgba($elo-grey, 0.2);
      .form-group {
        margin-bottom: 0;
      }
      .opt-in-terms {
        margin-top: 20px;
        label {
          color: $elo-black-semi;
        }
        &:last-child {
          margin-bottom: 20px;
        }
        .required-input-label,
        .required-custom-check-text {
          &::after {
            color: $elo-red;
            content: '*';
            font-size: var(--elo-font-size-lg);
            padding-left: 2px;
          }
        }

        &__select-box {
          label {
            color: $elo-grey;
          }
        }
      }
    }
    .terms,
    .opt-in-section {
      margin: 25px 0;
      font-size: 13px;
      color: $elo-grey;
      a {
        color: $elo-blue;
      }
      .seller-terms,
      .cancel-terms,
      .back-link {
        margin-bottom: 10px;
      }
      .seller-terms {
        color: $elo-black-semi;
      }
      .cancel-terms {
        margin-top: 25px;

        &__text {
          color: $elo-black-semi;
          line-height: 1;

          &--radio {
            margin-left: 34px;
            padding-top: 4px;
          }
        }

        &--centered {
          margin-left: 34px;
          padding-top: 4px;
          line-height: 1.2;
        }

        .field {
          display: block;
        }

        &--required {
          &::after {
            color: $elo-red;
            content: '*';
            font-size: var(--elo-font-size-lg);
            padding-left: 2px;
          }
        }
      }
      .form-check-label {
        margin-left: 30px;
      }
    }

    &__warning-alert {
      display: flex;
      align-items: center;
      padding: 16px 24px;
      margin-bottom: 15px;
      border-radius: 4px;
      background-color: $light-orange-10;
      border: solid 1px $light-orange-20;

      &--message {
        font-family: var(--elo-font-sans-serif), sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2;
        color: $dark-orange;
      }

      &--icon {
        font-size: 29px;
        color: $elo-orange;
        margin-right: 15px;
      }
    }
  }

  .express-checkout {
    width: 100%;
    padding: 0 30px;

    @media (max-width: 991.98px) {
      padding: 0 20px;
    }

    &__title {
      font: var(--elo-heading-h5);
      color: var(--color-primary-neutral-black);
      margin-bottom: 24px;
    }

    &__express-buttons {
      display: grid;
      grid-auto-columns: 2fr;
      grid-auto-flow: column;
      column-gap: 16px;

      @media (max-width: 991.98px) {
        grid-auto-columns: 1fr;
        grid-auto-flow: row;
        row-gap: 16px;
      }
    }

    &__purchase {
      .terms {
        margin: 16px 0;
        font-size: 13px;
        color: $elo-grey;

        a {
          color: $elo-blue;
        }

        .seller-terms {
          color: $elo-black-semi;
        }
      }
    }
  }

  .preview-warning {
    display: flex;
    background-color: rgba($elo-red, 0.1);
    border: solid 1px $elo-red;
    border-radius: 4px;
    color: $elo-red;
    margin-bottom: 20px;
    padding: 10px 15px;
    font-size: 13px;

    i {
      margin-right: 10px;
    }
  }

  .select-paymethod__img-wrapper {
    display: flex;
    align-items: flex-end;

    @media (max-width: 520px), (min-width: 992px) {
      .card-img + .card-img.mango_pay {
        margin-top: 5px;
      }
    }

    @media (min-width: 1200px) {
      flex-direction: row;
    }
  }

  .container-default {
    max-width: 1200px;

    .product-content {
      display: flex;
      flex-direction: row;
      gap: 30px;

      .preview-content-full-screen,
      .ticket-content-full-screen {
        flex-grow: 1;
        flex-basis: 100%;
      }

      .preview-content-half-screen,
      .ticket-content-half-screen,
      .pricing-content {
        flex-grow: 1;
        flex-basis: 0;
        max-width: calc(50% - 30px);
      }

      &-divider {
        border-left: 1px solid $elo-grey-light;
      }
    }
  }

  .container-simplified,
  .container-two_steps,
  .container-two_steps_advanced {
    max-width: 994px;

    .product-content {
      display: flex;
      flex-direction: column;
    }
  }

  .container-default,
  .container-simplified,
  .container-two_steps,
  .container-two_steps_advanced {
    width: 100%;
    padding: 0 40px;
    margin: 0 auto;

    @media (max-width: 1280px) {
      padding: 0 16px;
    }

    @media (max-width: 992px) {
      max-width: 960px;

      .product-content {
        flex-direction: column;
        gap: 0;
      }

      .pricing-content {
        margin-left: -15px;
        margin-right: -15px;
      }

      .product-content {
        .preview-content-half-screen,
        .ticket-content-half-screen,
        .pricing-content {
          max-width: unset;
        }
      }
    }

    @media (max-width: 576px) {
      max-width: 560px;
      padding: 0 8px;
    }
  }
}

@media (max-width: 991px) {
  .custom-shop .payment-page {
    padding-top: 90px;

    &--no-margin {
      padding-top: 20px;
    }
  }

  .custom-shop {
    .upsell-preview {
      margin-top: 60px;
    }

    .payment-in-progress {
      padding-top: 20px;
    }
  }

  :not(.custom-shop) {
    .payment-in-progress__sepa-icon {
      display: flex;
    }
  }
}

.buyer-info {
  padding: 30px 0;
}

.payment {
  .product-header {
    padding: 32px 15px 27px;
    display: table;
    width: 100%;
    .product-details {
      margin-top: 8px;
    }
    .cover-section,
    .name {
      display: table-cell;
      vertical-align: top;
    }
    .cover-section {
      width: 60px;
    }
    .cover {
      width: 60px;
      height: 60px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border-radius: 4px;
      box-shadow: 1px 1px 3px rgba($elo-black, 0.1);
    }
    .name {
      margin-left: 15px;
      display: block;
      padding-bottom: 5px;
      .cover {
        width: 60px;
        height: 60px;
        border-radius: 4px;
        margin-right: 10px;
      }
      a {
        text-decoration: none;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: -0.9px;
        color: $elo-black;
      }
      .product-icon {
        color: $elo-grey-40;
        font-size: 20px;
        margin-top: 10px;
        position: absolute;
        top: 5px;
        right: 15px;
      }
      i.fas {
        color: $elo-green;
        font-size: 28px;
      }
    }
    .product-content-details {
      margin-left: 15px;
      i.fas {
        font-size: 16px;
      }
      .space {
        width: 5vw;
      }
      .location {
        max-width: 60%;
      }
      .location,
      .date,
      .space,
      .course_details,
      .files {
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.4px;
        .title {
          color: rgba($elo-black-semi, 0.7);
        }
      }
    }
  }

  @media (max-width: 767px) {
    .product-header {
      padding: 15px 15px 10px;
      .product-content-details {
        margin-left: 0;
        margin-bottom: 10px;
        .location {
          max-width: 100%;
        }
        .location,
        .date {
          padding-bottom: 15px;
        }
      }
      .name {
        margin-left: 0;
        padding: 10px 0 15px;
        .text {
          display: table;
          .cover,
          a,
          span {
            display: table-cell;
            vertical-align: middle;
          }
        }
        &,
        a {
          font-size: 20px;
          line-height: 26px;
          letter-spacing: -0.5px;
        }
      }
      .product-details {
        margin-top: 0;
        .price-section {
          border-top: 1px solid $elo-grey-40;
          padding-top: 15px;
          .product-price {
            .current-price {
              font-size: 20px;
              line-height: normal;
              letter-spacing: -0.6px;
              color: $elo-black;
              .crossed-price {
                font-size: 12px;
                letter-spacing: -0.4px;
                line-height: normal;
              }
            }
            .type {
              font-size: 10px;
              letter-spacing: -0.2px;
            }
          }
        }
      }
    }
  }

  .select {
    color: $elo-red;
    background-color: $elo-red;
  }

  .Select {
    &.is-invalid {
      & + .invalid-feedback {
        display: block;
      }
      .Select-control {
        border-color: $elo-red-60;
      }
    }

    .Select-control {
      height: 38px;
      .Select-placeholder {
        margin-top: 2px;
        color: $elo-black-semi;
      }
      .Select-clear {
        margin-top: 3px;
      }
      .Select-arrow {
        margin-top: 3px;
      }
      .Select-value {
        margin-top: 3px;
      }
    }
  }
  .country-select {
    .Select-option:nth-child(3) {
      border-bottom: 1px solid $elo-grey-light;
    }
  }

  .country-selector {
    font-size: 13px;
  }
}

.fix-padding {
  padding: 0 20px !important;
  font-style: normal;
}

.prices-details-info {
  font-size: 12px;
  color: $elo-grey-dark;
  table {
    color: $elo-grey-dark;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .rate-wrap {
    .rate-title {
      padding-bottom: 5px;
      font-size: 14px;
      font-weight: bold;
      //text-align: right;
      clear: both;
    }
    &.rate-odd {
      background: $elo-grey-light;
    }
    &.rate-even {
      background: $elo-white;
    }
  }
  .table-total {
    font-size: 14px;
  }
  .table-vat {
    font-size: 10px;
  }
}

.payment-in-progress {
  &__container {
    padding: 100px 10px;
    max-width: 650px;
    text-align: center;
    margin: 0 auto;
  }

  &__icon {
    height: 40px;
    margin-bottom: 25px;
  }

  &__title {
    margin-bottom: 10px;
    color: $elo-black-semi;
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
  }

  &__message {
    margin-bottom: 30px;
    color: $elo-grey-dark;
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    font-size: 13px;
  }
}

.checkbox-field {
  &--header-name {
    .checkbox-field__label {
      line-height: 1;
    }
  }
}

.purchase__buy-button.elo-btn.orange:not(:disabled) {
  color: var(--elo-payment-button-color);
  background-color: var(--elo-payment-button-bg);
  border-color: var(--elo-payment-button-bg);
}

.purchase__buy-button.elo-btn.orange:not(:disabled):hover {
  color: var(--elo-payment-button-color);
  background-color: var(--elo-payment-button-hover-bg);
  border-color: var(--elo-payment-button-hover-bg);
}
