@import 'src/ui-kit/styles/colors';

.checkout-splited {
  label {
    input.radio-input:checked + .radio-cont {
      .crossed-price {
        color: $elo-white;
      }
      .text {
        color: rgba($elo-white, 0.76);
      }
    }
    .old-price {
      @extend .crossed-price;
      font-size: 12px;
    }
    .cross {
      padding: 0 5px;
    }
    .first-payment {
      border-radius: 4px;
      border: solid 1px $elo-grey-40;
      background-color: $elo-grey-light-10;
      padding: 15px;
      &.with-details {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .plane-name {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        margin-left: 5px;
      }
      .plan-interval {
        font-size: 12px;
        padding-left: 10px;
      }
      .text {
        font-size: 14px;
      }
      .first-price,
      .eur {
        font-size: 20px;
        font-weight: 500;
        line-height: 16px;
      }
    }
    .details {
      font-size: 14px;
      line-height: 14px;
      color: rgba($elo-black-semi, 0.7);
      border: solid 1px $elo-grey-40;
      border-top: none;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: $elo-white;
      .first-start-date,
      .next-start-date {
        color: $elo-blue-60;
        margin-left: 5px;
      }
      .first-count,
      .next-count,
      .cross {
        font-size: 10px;
        font-weight: normal;
        line-height: 14px;
        color: rgba($elo-black-semi, 0.7);
      }
      .first-price,
      .next-price,
      .total,
      .eur,
      .total-count {
        font-size: 14px;
        font-weight: 600;
        color: $elo-black-semi;
      }
      .text-period {
        font-size: 10px;
        line-height: 12px;
        text-align: right;
        color: $elo-blue-60;
      }
      .scheduler,
      .total-price {
        padding: 15px;
      }
      .border {
        width: 100%;
        height: 1px;
        background-color: $elo-grey-40;
      }
    }
  }
}
