@import '../../../styles/colors';
@import '../../../styles/fonts';

.additional-info-toggle {
  border: 0;
  box-shadow: none;
  outline: none;
  background: none;
  color: $elo-blue;
  text-decoration: none;
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
  line-height: 1;
  font-family: var(--elo-font-sans-serif);
  font-weight: 400;
  font-size: 12px;
  display: flex;
  margin-bottom: 20px;
  padding: 0;

  i {
    margin-right: 5px;
    font-size: 14px;
  }

  &__arrow-down {
    margin-top: -2px;
  }

  &__arrow-right {
    margin-top: -2px;
  }

  &:focus,
  &:active,
  &:hover {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    opacity: 0.8;
  }

  &--active {
    color: $elo-grey-dark;
  }
}
