@import '../../styles/colors';
@import '../../styles/fonts';

button.elo-btn,
.elo-btn {
  font-family: var(--elo-font-sans-serif);
  font-weight: 400;
  font-size: 14px;
  border: 0;
  box-shadow: none;
  outline: none;
  background-color: var(--button-disabled-bg-color);
  color: var(--button-font-color);
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
  border-radius: 17px;
  min-width: 104px;
  padding: 8px 20px 8px;
  line-height: 1.4;

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.rectangle {
    border-radius: 3px;
    color: var(--button-font-color);
  }

  &--multiple-right-side {
    margin-left: 10px;
  }

  &:focus,
  &:active,
  &:hover {
    box-shadow: none;
    outline: none;
    color: var(--button-font-color);
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
  }

  &--no-opacity {
    opacity: 1;
  }

  &--floating {
    position: fixed;
    bottom: 0;
    left: 0;
    font-size: 12px;
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    color: var(--floating-button-font-color);
    background-color: rgba($elo-black, 0.702);
    border-radius: 8px 8px 0 0;
    padding: 10px 30px 6px;
    z-index: 10;

    i {
      font-size: 16px;
    }

    &:hover {
      color: var(--floating-button-font-color-hover);
    }

    &:active {
      color: var(--floating-button-font-color-active);
    }

    &:focus {
      color: var(--floating-button-font-color-focus);
    }
  }

  i {
    font-size: 14px;
    margin-right: 5px;
  }

  .right-icon {
    margin: 0 0 0 5px;
  }

  &.minimized {
    padding: 2px 14px 1px;
  }

  &.full-size {
    width: 100%;
  }

  &.long {
    width: 100% !important;
    padding: 12px 20px;
  }

  &.rectangle {
    border-radius: 3px;
    color: var(--button-font-color);
  }

  // COLORS
  &.grey:not(:disabled) {
    background-color: $elo-grey-light;

    &:hover {
      background-color: $elo-grey-40;
    }

    &:active {
      background-color: $elo-grey;
    }
  }

  &.orange:not(:disabled) {
    background-color: var(--create-button-bg-color);

    &:hover {
      background-color: var(--create-button-bg-color-hover);
    }

    &:active {
      background-color: var(--create-button-bg-color-active);
    }
  }

  &.green:not(:disabled) {
    background-color: var(--primary-button-bg-color);

    &:hover {
      background-color: var(--primary-button-bg-color-hover);
    }

    &:active {
      background-color: var(--primary-button-bg-color-active);
    }
  }

  &.blue:not(:disabled) {
    background-color: var(--primary-button-bg-color);

    &:hover {
      background-color: var(--primary-button-bg-color-hover);
    }

    &:active {
      background-color: var(--primary-button-bg-color-active);
    }
  }

  &.dark:not(:disabled) {
    color: var(--elo-white);
    background-color: var(--elo-dark-grey);

    &:hover {
      background-color: var(--elo-grey-60);
    }

    &:active {
      background-color: var(--elo-dark-grey);
    }
  }

  &.red:not(:disabled) {
    background-color: var(--delete-button-bg-color);

    &:hover {
      background-color: var(--delete-button-bg-color-hover);
    }

    &:active {
      background-color: var(--delete-button-bg-color-active);
    }
  }

  &.pink:not(:disabled) {
    color: var(--elo-white);
    background-color: var(--delete-button-bg-color);

    &:hover {
      background-color: var(--delete-button-bg-color-hover);
    }

    &:active {
      background-color: var(--delete-button-bg-color-active);
    }
  }

  &.outline-pink:not(:disabled) {
    color: var(--delete-button-bg-color);
    border: solid 1px var(--delete-button-bg-color);
    background-color: transparent;
    padding-top: 7px;
    padding-bottom: 7px;

    &:hover {
      border-color: var(--delete-button-bg-color-hover);
      background-color: transparent;
      color: var(--delete-button-bg-color-hover);
    }

    &:active {
      border-color: var(--delete-button-bg-color-active);
      background-color: transparent;
      color: var(--delete-button-bg-color-active);
    }
  }

  &.outline-red:not(:disabled) {
    border: solid 1px var(--outline-button-border-color);
    color: var(--button-font-color);
    background-color: transparent;
    padding-top: 7px;
    padding-bottom: 7px;

    &:hover {
      border-color: var(--outline-button-border-color-hover);
      background-color: transparent;
      color: var(--outline-button-font-color-hover);
    }

    &:active {
      border-color: var(--outline-button-border-color-active);
      background-color: transparent;
      color: var(--outline-button-font-color-active);
    }
  }

  &.transparent {
    background-color: transparent;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;

    &.transparent-pink:not(:disabled) {
      color: var(--link-button-color);

      &:hover {
        color: var(--link-button-color-hover);
      }

      &:active {
        color: var(--link-button-color-active);
      }
    }

    &.transparent-blue:not(:disabled) {
      color: var(--link-button-color);

      &:hover {
        color: var(--link-button-color-hover);
      }

      &:active {
        color: var(--link-button-color-active);
      }
    }

    &.outline-pink:not(:disabled) {
      color: var(--outline-button-font-color);
      border: solid 1px var(--outline-button-border-color);
      background-color: transparent;

      &:hover {
        color: var(--outline-button-font-color-hover);
        border-color: var(--outline-button-border-color-hover);
      }

      &:active {
        color: var(--outline-button-font-color-active);
        border-color: var(--outline-button-border-color-active);
      }
    }

    &.outline-blue:not(:disabled) {
      color: var(--outline-button-font-color);
      border: solid 1px var(--outline-button-border-color);
      background-color: transparent;

      &:hover {
        border: solid 1px var(--outline-button-border-color-hover);
        color: var(--outline-button-font-color-hover);

        .elo-btn-icon {
          color: var(--outline-button-font-color-hover);
        }
      }

      &:active {
        border: solid 1px var(--outline-button-border-color-active);
        color: var(--outline-button-font-color-active);

        .elo-btn-icon {
          color: var(--outline-button-font-color-active);
        }
      }
    }
  }

  &.outline-blue:not(:disabled) {
    color: var(--outline-button-border-color);
    border: solid 1px var(--outline-button-border-color);
    background-color: transparent;
    padding-top: 7px;
    padding-bottom: 7px;

    &:hover {
      border-color: var(--outline-button-border-color-hover);
      color: var(--outline-button-font-color-hover);

      .elo-btn-icon {
        color: var(--outline-button-font-color-hover);
      }
    }

    &:active {
      border-color: var(--outline-button-border-color-active);
      color: var(--outline-button-border-color-active);

      .elo-btn-icon {
        color: var(--outline-button-border-color-active);
      }
    }
  }

  &.outline-grey:not(:disabled) {
    border: solid 1px var(--outline-button-border-color);
    background-color: transparent;
    color: var(--outline-button-border-color);
    padding-top: 7px;
    padding-bottom: 7px;

    &:hover {
      border-color: var(--outline-button-border-color-hover);
      color: var(--outline-button-font-color-hover);
      background-color: transparent;
    }

    &:active {
      border-color: var(--outline-button-border-color-active);
      color: var(--outline-button-font-color-active);
      background-color: transparent;
    }
  }

  &.outline-blue:not(:disabled).copy .fa-copy {
    font-size: 16px;
  }

  &.outline-white:not(:disabled) {
    --outline-button-border-color: var(--elo-white);
    --outline-button-font-color: var(--elo-white);
    --outline-button-border-color-hover: var(--elo-light-grey-10);
    --outline-button-border-color-active: var(--elo-light-grey-20);
    --outline-button-font-color-hover: var(--elo-light-grey-10);
    --outline-button-font-color-active: var(--elo-light-grey-20);

    border: solid 1px var(--outline-button-border-color);
    color: var(--outline-button-font-color);
    background-color: transparent;
    padding-top: 7px;
    padding-bottom: 7px;

    &:hover {
      border-color: var(--outline-button-border-color-hover);
      color: var(--outline-button-font-color-hover);
    }

    &:active {
      border-color: var(--outline-button-border-color-active);
      color: var(--outline-button-font-color-active);
    }
  }

  &.outline-green--disabled:not(:disabled) {
    border: solid 1px var(--outline-button-border-color);
    color: var(--outline-button-font-color);
    background-color: transparent;
    padding-top: 7px;
    padding-bottom: 7px;
    cursor: initial;

    &:hover {
      color: var(--outline-button-font-color-hover);
      border-color: var(--outline-button-border-color-hover);
    }

    &:active {
      color: var(--outline-button-font-color-active);
      border-color: var(--outline-button-border-color-active);
    }
  }

  &.outline-orange:not(:disabled) {
    border: solid 1px var(--outline-button-border-color);
    background-color: transparent;
    padding-top: 7px;
    padding-bottom: 7px;
    color: var(--outline-button-font-color);

    &:hover {
      color: var(--outline-button-font-color-hover);
      border-color: var(--outline-button-border-color-hover);
    }

    &:active {
      color: var(--outline-button-font-color-active);
      border-color: var(--outline-button-border-color-active);
    }
  }

  &.download:not(:disabled) {
    min-width: 80px;
    text-transform: uppercase;
    font-size: 12px;
    padding: 7px;
    line-height: 0;
    font-family: LucidaGrande, serif;

    i {
      font-size: 16px;
      margin-right: 9px;
    }
  }

  &.overlay {
    position: fixed;
    left: 55%;
    transform: translateX(-55%);
    z-index: 1;
  }

  &.share:not(:disabled) {
    min-width: 90px;
    width: 90px;
    height: 34px;
    padding: 0 10px;
    font-size: 12px;
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      margin-left: 0;
    }
  }
}

.elo-btn-text {
  background: none;
  border: 0;
  color: var(--link-button-color);
  padding: 0;

  &:hover {
    color: var(--link-button-color-hover);
  }

  &:active {
    color: var(--link-button-color-active);
  }

  &--in-text {
    margin: 0 5px;
  }

  &--red {
    color: var(--link-button-color);

    &:hover {
      color: var(--link-button-color-hover);
    }

    &:active {
      color: var(--link-button-color-active);
    }
  }

  &--underlined {
    color: var(--link-button-color);
    text-decoration: underline;

    &:hover {
      color: var(--link-button-color-hover);
    }

    &:active {
      color: var(--link-button-color-active);
    }
  }
}

.elo-btn-container {
  display: flex;
  margin-top: 30px;

  .elo-btn {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &--bottom-margin {
    margin-top: 0;
    margin-bottom: 20px;
  }

  &--no-margin {
    margin-top: 0;
  }

  &--right {
    justify-content: flex-end;
  }

  &--centered {
    justify-content: center;
  }

  &--between {
    justify-content: space-between;
  }

  &--dashed {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 15px 0;
    border-radius: 6px;
    background-color: var(--add-button-bg-color);
    border: dashed 1px var(--add-button-border-color);
    color: var(--add-button-font-color);

    &:hover {
      border-color: var(--add-button-border-color-hover);
    }
  }

  &--full-width {
    width: 100%;
  }

  &--overlay {
    position: absolute;
    left: 55%;
    transform: translateX(-55%);
    z-index: 1;

    @media screen and (max-width: 1150px) {
      position: static;
      transform: none;
      order: 3;
      width: 100%;
      margin-top: 10px;
    }
  }
}
