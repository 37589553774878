@import 'src/ui-kit/styles/brand-colors';
@import 'variables.scss';
// Variables
@import 'utils/helpers';
@import 'utils/fonts';

// NPM
@import '~bootstrap/scss/bootstrap';
@import 'fontawesome-pro/css/all.min.css';
@import 'fontawesome-pro/css/v4-shims.min.css';
@import '~react-datetime/css/react-datetime.css';
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';

@import '~froala-editor/css/froala_editor.pkgd.min.css';
@import 'libs/toastify';

@import 'react/utils';
@import 'react/ui/form-fields/field';
@import 'react/cabinet/content_page';
@import 'react/cabinet/styleguide/components';
@import 'react/shop/styleguide/buttons';
@import 'react/pricing_plan/pricing_plan_preview';
@import 'react/payment/shop_payment_new';
@import 'react/payment/shop_payment_show';
@import 'react/payment/shop_payment_deal';
@import 'react/payment/payment_methods';
@import 'react/payment/payment_error';
@import 'react/payment/payment_tickets';
@import 'react/payment/payment_plans';
@import 'react/cabinet/styleguide/table';
@import 'react/components/common/radio';
@import 'react/components/lessons_tree';
@import 'react/ui/form/non-editable-text';
@import 'react/ui/form-fields/partial-text-field';
@import 'react/cabinet/upsells';
@import 'react/cabinet/tooltip_menu';
@import 'react/cabinet/products_list/tooltip_menu';

@import 'bit_global';

// input with counter +/- buttons
@import 'react/components/common/buttoninputgroup/button_input_group';

// ===============================================
// default shop styles
@import 'react/shop/default_shop';
@import 'react/shop/default_shop_item';
@import 'react/shop/digitals_list';
@import 'react/shop/auth';
@import 'react/shop/header';
@import 'react/shop/footer';
@import 'react/shop/success_page';

// namotto shop styles
@import 'react/shop/namotto';

/** Shared UI Components **/
@import 'react/cabinet/products_list/products_list';
@import 'react/ui/product-cover';
@import 'react/ui/collabsible';
@import '../js/ui/image-croping/modals';
@import 'react/ui/product-slider';
@import '../js/ui/pagination/pagination';
@import 'react/ui/fields-container';
@import 'react/ui/form-fields/select-field';
@import 'react/ui/form-fields/date-time-field';

/** course themes **/
@import 'react/shared/course-preview/widgets/navigation/course-top-nav';
@import 'react/shared/course-preview/widgets/navigation/course-side-nav';
@import 'react/shared/course-preview/widgets/quiz';
@import 'react/shared/course-preview/widgets/comments';

@import 'react/shared/course-preview/themes/default';
@import 'react/shared/course-preview/themes/custom';

@import 'common/general';

@import '@elo-ui/styles';

html {
  position: relative;
  min-height: 100%;
}

html body {
  font-family: Montserrat, sans-serif;
}

.custom-shop.el {
  font-family:
    Nunito Sans,
    sans-serif;
}

.custom-shop.el *:not(.fab):not(.fas):not(.fa):not(.far):not(.fal):not(.fad) {
  font-family:
    Nunito Sans,
    sans-serif;
}

.show-less-more-btn {
  font-size: 14px;
  color: $elo-blue;
  &:hover {
    cursor: pointer;
  }
}

#stripe-bank-element {
  border: solid 1px $elo-grey-40;
}

//this code allow livestream video but break embeded video
//there's more sense to allow embeded video in shop than livestream
// .fr-view .fr-video {
//   position: initial;
// }

// reset froala style for date time field
.fr-view table th {
  background: unset;
}

.fr-view table td,
.fr-view table th {
  border: unset;
}
