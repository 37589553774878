@import 'src/ui-kit/styles/colors';

$product-type: #44a7ed;
$course-type: #7ac643;

body.default-shop-body {
  background-color: $elo-grey-light-20;
}

.access-password-form {
  margin: 100px 0;
  .form-content {
    width: 400px;
    margin: auto;
    background-color: $elo-white;
    @media (max-width: 420px) {
      width: auto;
    }
    position: relative;
    background-clip: padding-box;
    outline: none;
    .form-header {
      padding: 15px;
      border-bottom: 1px solid $elo-grey-light;
      min-height: 16.42857143px;
    }
    .form-body {
      position: relative;
      padding: 15px;
      max-height: calc(100vh - 212px);
      overflow-y: auto;
    }
    .form-footer {
      font-family: Roboto;
      text-align: center;
      font-size: 12px;
      padding: 15px;
      color: rgba($elo-grey-dark, 0.5);
      border-top: 1px solid $elo-grey-light;
    }
  }
}

.payment-protected {
  font-family: Roboto;
  padding: 100px;
  text-align: center;
  font-size: 30px;
  color: $elo-grey;
}

.btn-group.locale-switcher {
  float: none;
  &:not(.dropup) {
    .locales {
      top: 41px;
      right: 15px;
      position: absolute;
    }
    @media (min-width: 768px) {
      padding: 17px 15px 0 0;
    }
  }
  &.dropup {
    .locales {
      right: 0;
      left: auto;
    }
  }
  .btn {
    padding: 2px 0 3px 5px;
    background-color: rgba($elo-black, 0.5);
    border: none;
    .caret {
      margin: 0 5px;
      border-top-color: rgba($elo-white, 0.6);
    }
    img {
      margin: 0 10px 2px 0;
    }
  }
  .locales {
    background-color: rgba($elo-black, 0.5);
    min-width: 45px;
    border: none;
    border-radius: 3px;
    text-align: center;
    li {
      display: block;
      cursor: pointer;
      .locale {
        padding: 0px 4px 4px;
      }
      .active,
      &:hover {
        background: rgba($elo-white, 0.2);
        border-radius: 3px;
      }
    }
  }
  img {
    width: 25px;
  }
}

.claim_box {
  width: 300px;
  margin: 100px auto 0;
  text-align: center;
  .user-has-no-product {
    font-family: Roboto;
    color: $elo-black-semi;
    font-size: 24px;
    font-weight: 300;
    padding: 15px 0;
    .username {
      font-weight: 400;
    }
  }
  #claim_box_text {
    border-radius: 3px;
    background-color: rgba($elo-grey-light, 0.33);

    font-family: 'Open Sans';
    color: $elo-black-semi;
    font-size: 16px;
    font-weight: 300;
    padding: 10px;
    .text {
      padding: 35px 20px;
    }
    .btn {
      font-family: Roboto;
      width: 100%;
      color: $elo-white;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

#products-list {
  -moz-column-width: 18em;
  -webkit-column-width: 18em;
  column-width: 18em;
  -moz-column-gap: 1em;
  -webkit-column-gap: 1em;
  column-gap: 1em;

  .item {
    display: inline-block;
    padding: 0.25rem;
    width: 100%;
  }

  .well {
    position: relative;
    display: block;
  }
}

.modal-open {
  overflow: visible !important;
}

.default-shop {
  .products {
    .product {
      .col {
        a {
          display: block;
          width: 100%;
        }
        &.seller {
          .avatar {
            display: inline-block;
            img {
              border-radius: 50%;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      .icon {
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        .no-cover,
        .no-image {
          background-color: $elo-grey-light;
          position: relative;
          &:before {
            font-family: 'Font Awesome 5 Pro';
            font-weight: 900;
            content: '\f030';
            position: absolute;
            color: $elo-grey-40;
            font-size: 32px;
            top: calc(50% - 20px);
            text-align: center;
            left: 0;
            right: 0;
          }
        }
      }
    }
    &.gallery {
      #products-table {
        &.by-columns {
          -moz-column-width: 18em;
          -webkit-column-width: 18em;
          column-width: 18em;
          -moz-column-gap: 1em;
          -webkit-column-gap: 1em;
          column-gap: 1em;
          padding: 10px;
        }
        .product {
          position: relative;
          width: 100%;
          max-height: fit-content;
          display: inline-block;
          margin: 5px 1px 10px;
          box-shadow: 1px 1px 17px 0px $elo-grey-light-20;
          .product-type {
            font-family: Roboto;
            color: $elo-white;
            font-size: 10px;
            font-weight: 700;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            padding: 0 5px;
            &.product-type {
              background-color: $product-type;
            }
            &.course-type {
              background-color: $course-type;
            }
          }
          .icon {
            width: 100%;
            img {
              width: 100%;
            }
            .image {
              display: none;
            }
            .no-image {
              width: 100%;
              height: 200px;
              background-size: cover;
              background-position: center;
            }
          }
          .name,
          .seller {
            width: 100%;
            background-color: $elo-white;
            padding: 10px;
            a {
              font-family: Roboto;
              color: $elo-black-semi;
              font-size: 14px;
              font-weight: 400;
              vertical-align: middle;
              display: inline;
              &:hover {
                text-decoration: none;
              }
            }
          }
          .name {
            a {
              width: 100%;
              display: block;
              word-break: break-word;
            }
          }
          .seller {
            border-top: 1px solid $elo-grey-light-20;
            a:hover {
              text-decoration: underline;
            }
          }
          .col.price {
            display: none;
          }
        }
      }
    }
  }
}

#login-modal .forgot {
  text-align: right;
  a {
    color: $elo-grey;
  }
}

.ribbon {
  z-index: 1;
  padding: 2px 12px;
  font-size: 16px;
  font-weight: bold;
  color: $elo-black-semi;
  text-transform: uppercase;
  line-height: 20px;
  background: $elo-orange;
  position: absolute;
  bottom: 0;
  right: 0;
}

.specific-social {
  font-size: 25px !important;
}

.shared-options {
  height: 27px;
  width: 150px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .social_links {
    i {
      font-size: 20px;

      &:hover {
        font-size: 25px;
      }
    }
    a:hover {
      text-decoration: none;
    }
  }
}
