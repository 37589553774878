@import 'colors/colors';
@import 'spacings/spacings';
@import 'typography/fonts';
@import 'typography/typography';
@import 'breakpoints';

// TODO: remove - temporary support old variable
:root {
  --elo-highlight-brand: var(--color-highlight-brand, #2bff99);
  --elo-highlight-web: var(--color-highlight-web, #17df87);
  --elo-highlight-hover: var(--color-highlight-hover) #31ee95;
  --elo-highlight-click: var(--color-highlight-click) #05ab5b;
  --elo-neutral-white: var(--color-primary-neutral-white, #ffffff);
  --elo-neutral-black: var(--color-primary-neutral-black, #000004);
  --elo-primary-dg-brand: var(--color-primary-dg-brand, #21282e);
  --elo-primary-dg-600: var(--color-primary-dg-600, #141619);
  --elo-primary-dg-500: var(--color-primary-dg-500, #485056);
  --elo-primary-dg-400: var(--color-primary-dg-400, #71747a);
  --elo-primary-dg-300: var(--color-primary-dg-300, #8f9295);
  --elo-primary-dg-200: var(--color-primary-dg-200, #d7dadf);
  --elo-primary-dg-100: var(--color-primary-dg-100, #f3f5f8);
  --elo-primary-cg-brand: var(--color-primary-cg-brand, #f0f1f6);
  --elo-primary-cg-200: var(--color-primary-cg-200, #cdcdd5);
  --elo-primary-cg-100: var(--color-primary-cg-100, #f9f9fe);
  --elo-primary-wg-brand: var(--color-primary-wg-brand, #e4e1dd);
  --elo-primary-wg-100: var(--color-primary-wg-100, #f5f3f0);
  --elo-utility-success-dark: var(--color-utility-success-dark, #047443);
  --elo-utility-success-mid: var(--color-utility-success-mid, #13b971);
  --elo-utility-success-light: var(--color-utility-success-light, #d4ffe9);
  --elo-utility-error-dark-alt: var(--color-utility-error-dark-alt, #ff5a64);
  --elo-utility-error-dark: var(--color-utility-error-dark, #ce0018);
  --elo-utility-error-mid: var(--color-utility-error-mid, #ff1f39);
  --elo-utility-error-light: var(--color-utility-error-light, #ffedef);
  --elo-utility-warning-dark: var(--color-utility-warning-dark, #ce4308);
  --elo-utility-warning-mid: var(--color-utility-warning-mid, #ff9518);
  --elo-utility-warning-light: var(--color-utility-warning-light, #ffc485);
  --elo-utility-alert-dark: var(--color-utility-alert-dark, #ffc700);
  --elo-utility-alert-mid: var(--color-utility-alert-mid, #ffea7c);
  --elo-utility-alert-light: var(--color-utility-alert-light, #fffdcf);
  --elo-dec-purple-parent: var(--color-decorative-purple-parent, #9d63ff);
  --elo-dec-purple-shade1: var(--color-decorative-purple-shade1, #c09ff8);
  --elo-dec-purple-shade2: var(--color-decorative-purple-shade2, #d4c5ff);
  --elo-dec-purple-shade3: var(--color-decorative-purple-shade3, #ede7ff);
  --elo-dec-teal-parent: var(--color-decorative-teal-parent, #8cc8b9);
  --elo-dec-teal-shade1: var(--color-decorative-teal-shade1, #50a590);
  --elo-dec-teal-shade2: var(--color-decorative-teal-shade2, #57bf9c);
  --elo-dec-teal-shade3: var(--color-decorative-teal-shade3, #baded5);
  --elo-dec-mauve-parent: var(--color-decorative-mauve-parent, #d356ff);
  --elo-dec-mauve-shade1: var(--color-decorative-mauve-shade1, #b400f5);
  --elo-dec-mauve-shade2: var(--color-decorative-mauve-shade2, #e9adff);
  --elo-dec-mauve-shade2: var(--color-decorative-mauve-shade3, #f4d6ff);

  --elo-highlight-1: var(--elo-highlight-brand);
  --elo-highlight-2: var(--elo-highlight-web);
  --elo-highlight-2-hover: var(--elo-highlight-hover);
  --elo-highlight-2-click: var(--elo-highlight-click);
  --elo-accent-1: var(--elo-utility-success-light);
  --elo-primary-100: var(--elo-primary-dg-brand);
  --elo-primary-90: var(--elo-primary-dg-500);
  --elo-primary-80: var(--elo-primary-dg-400);
  --elo-primary-70: var(--elo-primary-dg-300);
  --elo-primary-60: var(--elo-primary-dg-200);
  --elo-primary-50: var(--elo-primary-cg-brand);
  --elo-primary-40: var(--elo-primary-dg-100);
  --elo-primary-20: #cdcdd5;
  --elo-primary-30: #e4e1dd;
  --elo-primary-0: var(--elo-neutral-white);
  --elo-success-dark: var(--elo-utility-success-dark);
  --elo-success-light: var(--elo-utility-success-light);
  --elo-warning-dark: var(--elo-utility-warning-dark);
  --elo-warning-light: var(--elo-utility-alert-light);
  --elo-error: var(--elo-utility-error-mid);
  --elo-error-dark: var(--elo-utility-error-dark);
  --elo-error-semilight: var(--elo-utility-error-mid);
  --elo-error-light: var(--elo-utility-error-light);
  --elo-dark: #2d343a;
  --elo-success-badge-dark: #047443;
  --elo-success-badge-light: #d4ffe9;
  --elo-alert-badge-light: #fffdcf;
  --elo-warning-badge-dark: #ce4308;
  --elo-error-badge-light: #ffedef;
  --elo-error-badge-dark: #ce0018;
  --elo-review-badge-light: #e4e1dd;

  --elo-checkout: var(--elo-utility-warning-mid);
  --elo-checkout-semilight: var(--elo-utility-warning-light);
  --elo-checkout-dark: var(--elo-utility-warning-mid);
  --elo-h0-font-style: var(--elo-heading-h3-font-style);
  --elo-h0-font-weight: var(--elo-heading-h3-font-weight);
  --elo-h0-font-size: var(--elo-heading-h3-font-size);
  --elo-h0-line-height: var(--elo-heading-h3-line-height);
  --elo-h0-font-family: var(--elo-heading-h3-font-family);
  --elo-h0: var(--elo-heading-h3);

  --elo-h1-font-style: var(--elo-heading-h4-font-style);
  --elo-h1-font-weight: var(--elo-heading-h4-font-weight);
  --elo-h1-font-size: var(--elo-heading-h4-font-size);
  --elo-h1-line-height: var(--elo-heading-h4-line-height);
  --elo-h1-font-family: var(--elo-heading-h4-font-family);
  --elo-h1: var(--elo-heading-h4);

  --elo-h2-font-style: var(--elo-heading-h6-font-style);
  --elo-h2-font-weight: var(--elo-heading-h6-font-weight);
  --elo-h2-font-size: var(--elo-heading-h6-font-size);
  --elo-h2-line-height: var(--elo-heading-h6-line-height);
  --elo-h2-font-family: var(--elo-heading-h6-font-family);
  --elo-h2: var(--elo-heading-h6);

  --elo-regular-small-font-style: var(--elo-body-xs-regular-font-style);
  --elo-regular-small-font-weight: var(--elo-body-xs-regular-font-weight);
  --elo-regular-small-font-size: var(--elo-body-xs-regular-font-size);
  --elo-regular-small-line-height: var(--elo-body-xs-regular-line-height);
  --elo-regular-small-font-family: var(--elo-body-xs-regular-font-family);
  --elo-regular-small: var(--elo-body-xs-regular);

  --elo-regular-font-style: var(--elo-body-sm-regular-font-style);
  --elo-regular-font-weight: var(--elo-body-sm-regular-font-weight);
  --elo-regular-font-size: var(--elo-body-sm-regular-font-size);
  --elo-regular-line-height: var(--elo-body-sm-regular-line-height);
  --elo-regular-font-family: var(--elo-body-sm-regular-font-family);
  --elo-regular: var(--elo-body-sm-regular);

  --elo-medium-font-style: var(--elo-body-sm-medium-font-style);
  --elo-medium-font-weight: var(--elo-body-sm-medium-font-weight);
  --elo-medium-font-size: var(--elo-body-sm-medium-font-size);
  --elo-medium-line-height: var(--elo-body-sm-medium-line-height);
  --elo-medium-font-family: var(--elo-body-sm-medium-font-family);
  --elo-medium: var(--elo-body-sm-medium);
  --shadow-elevation-1: 0 1px 2px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  --shadow-elevation-2: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 2px 4px 2px rgba(0, 0, 0, 0.15);
  --shadow-elevation-3: 0 4px 4px 0 rgba(0, 0, 0, 0.15), 0 8px 12px 6px rgba(0, 0, 0, 0.15);
}
