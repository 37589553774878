@import 'src/ui-kit/styles/colors';
@import './fonts';

.payment-plans {
  & > div {
    padding-left: 15px;
    padding-right: 15px;
  }

  .preferred-plan-container {
    display: flex;
    justify-content: space-between;

    &__badge {
      align-self: baseline;
    }

    .payment-plan__new-container--selection {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .payment-plan__new-container--coupon-applied {
      border: 1px solid var(--color-highlight-web);

      & > span {
        font: var(--elo-body-xs-regular);
        color: var(--color-primary-neutral-black);
      }
    }

    @media (max-width: 576px) {
      flex-direction: column;

      &__badge {
        order: 1;
        margin-bottom: 16px;
      }
    }
  }

  .payment-plans-title {
    font-size: 18px;
    font-weight: 500;
    color: $elo-grey;
    padding-bottom: 25px;
    text-transform: uppercase;
  }

  .payment-plan {
    &.selected {
      border-radius: 3px;
    }

    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__new-container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &--in-form {
        padding: 10px 0 0 5px;

        .elo-radio-button {
          padding-left: 35px;
        }

        .plan-item-price {
          padding-left: 40px;
        }
      }

      &--selection {
        .elo-radio-button {
          margin-bottom: 0;
        }
      }
    }

    &__prices {
      margin-left: 10px;
      text-align: right;
      margin-top: 4px;
    }

    &.selected .custom-check {
      color: $elo-black-semi;
    }

    &:not(.selected) .custom-check {
      color: $elo-grey-dark;
    }

    .custom-check {
      font-family: var(--elo-font-sans-serif);
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding-top: 4px;
    }

    .old-price {
      white-space: nowrap;
    }

    .coupon-applied {
      font-size: 12px;
      font-weight: bold;
      color: $elo-green;
      text-align: right;

      i {
        margin-right: 5px;
      }
    }

    .coupon-code-applied {
      display: flex;
      align-items: center;
      gap: 4px;
      margin: 8px 0;
      font: var(--elo-body-xs-semibold);
      color: var(--color-utility-success-mid);
    }

    .description-info {
      font-size: 13px;
      line-height: 16px;
      color: var(--color-text-surface-secondary);
      margin-top: 8px;

      .price-crossed {
        text-decoration: line-through;
      }

      & > .elo-payment-plan--overflowed {
        flex-wrap: nowrap;
        gap: 4px;
      }
    }

    .details {
      font-size: 13px;
      line-height: 16px;
      color: $elo-grey-dark;
      margin-top: 8px;

      .price-crossed {
        position: relative;
        white-space: nowrap;

        &::before {
          content: '';
          height: 1px;
          position: absolute;
          background: $elo-black-semi;
          width: 100%;
          left: 0;
          top: 5px;
        }

        &--windows::before {
          top: 7px;
        }
      }
    }

    @media (max-width: 991px) {
      width: unset;
      min-width: unset;
      margin: 0 15px;
    }

    @media (max-width: 576px) {
      &__new-container {
        &--selection {
          order: 2;
        }
      }
    }
  }

  .plan-border {
    height: 1px;
    margin: 20px 15px;
    background-color: $elo-grey-light;
  }

  .plan-wrapper {
    margin: 20px 15px;
  }

  .old-price {
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    color: $elo-red-60;
    font-size: 13px;
    margin-top: 5px;
    position: relative;

    &::before {
      content: '';
      height: 1px;
      position: absolute;
      background: $elo-red-60;
      width: 100%;
      left: 0;
      top: 7px;
    }

    &--windows::before {
      top: 7px;
    }
  }
}

.payment-page .payment-plans {
  .elo-card:not(.elo-card__selectable--selected) .description-info {
    visibility: hidden;
    height: 0;
    margin-top: 0;
  }
}
