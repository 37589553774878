@import 'src/ui-kit/styles/colors';

.custom {
  .course-side-nav {
    position: initial;
    width: 353px;
    overflow: initial;

    .menu-wrapper {
      width: 353px !important;
      margin-bottom: -5px !important;

      .progress-container {
        .course-name {
          display: none;
        }

        .percents {
          color: $elo-grey;
        }

        .progress {
          height: 10px;
          border-radius: 5px;
        }
      }

      .menu-lessons-tree {
        .category-head {
          background: initial;
        }

        .lesson-wrap.active:hover {
          color: $elo-white;
        }

        .lesson-wrap:hover {
          opacity: initial;
          font-weight: bold;
        }
      }
    }

    .course-bottom-nav {
      .elo-btn.full-color {
        color: $elo-white;
      }
    }

    .lesson-comments {
      margin: 0 !important;
      box-shadow: none;
      padding: 10px 0;

      .comments-form {
        .elo-btn.outline-blue:disabled {
          pointer-events: none;
          color: $elo-white;
          border: solid 1px $elo-white;
          opacity: 0.6;
        }
      }
    }

    .content-pagebuilder-view .content-block-filelist {
      .file-wrapper .text-side {
        max-width: 500px;
      }
    }
  }

  .quiz-attempt {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .attempt-info {
      display: flex;
      align-items: center;

      i {
        font-size: 30px;

        &.fa-smile {
          color: $elo-green;
        }

        &.fa-frown {
          color: $elo-red;
        }
      }

      span {
        font-family: Montserrat Reg;
        font-size: 13px;
        font-style: normal;
        color: $elo-grey-dark;
      }

      .attempt-status {
        font-size: 18px;
        font-family: var(--elo-font-sans-serif);
        font-style: normal;
        margin-left: 7px;
        margin-right: 30px;
      }
    }

    .attempt-actions {
      button {
        display: unset;
        font-size: 12px;
        min-width: 104px;
        padding: 9px 20px 7px;
        min-height: unset;
        border-radius: 17px;
      }
    }

    .points {
      display: inline;
      margin-left: 20px;
      font-family: var(--elo-font-sans-serif);
      font-size: 18px;
      font-weight: bold;
      color: $elo-grey;

      .all {
        color: $elo-grey-dark;
      }
    }
  }

  .lesson-page {
    width: calc(100% - 295px);
    min-height: 100vh;
    background-color: $elo-grey-light-20;
    transition: margin-left 0.3s;
    margin-left: 295px;

    .white-widget {
      margin: 30px;
    }

    &__banner {
      margin: 30px;

      &--custom {
        margin: 30px 0;
      }

      &--comma-separated:not(:last-child)::after {
        content: ', ';
      }
    }

    &__title {
      display: inline-block;
      text-align: left;
      font-family: var(--elo-font-sans-serif);
      font-size: 18px;
      color: var(--title-font-color);
    }

    .quiz-attempt {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .attempt-info {
        display: flex;
        align-items: center;

        i {
          font-size: 30px;
        }

        span {
          font-family: Montserrat Reg;
          font-size: 13px;
          font-style: normal;
          color: $elo-grey-dark;
        }

        .attempt-status {
          font-size: 18px;
          font-family: var(--elo-font-sans-serif);
          font-style: normal;
          margin-left: 7px;
          margin-right: 30px;
        }
      }

      .points {
        display: inline;
        margin-left: 20px;
        font-family: var(--elo-font-sans-serif);
        font-size: 18px;
        font-weight: bold;
        color: $elo-grey;

        .all {
          color: $elo-grey-dark;
        }
      }
    }

    .waiting {
      i {
        color: $elo-orange;
      }

      .attempt-status {
        color: $elo-orange !important;
      }
    }

    .failed {
      i {
        color: $elo-red;
      }

      .attempt-status {
        color: $elo-red !important;
      }

      .gained {
        color: $elo-red !important;
      }
    }

    .completed {
      i {
        color: $elo-green;
      }

      .attempt-status {
        color: $elo-green !important;
      }

      .gained {
        color: $elo-green !important;
      }
    }

    .quiz-afterword {
      display: flex;
      align-items: flex-end;
      overflow: auto;

      .author-comment {
        margin-left: 25px;

        .author {
          display: block;
          font-family: var(--elo-font-sans-serif);
          font-size: 13px;
          color: $elo-black-semi;
        }

        .comment {
          display: block;
          font-family: Montserrat Reg;
          font-size: 13px;
          color: $elo-black-semi;
        }
      }
    }

    .blocked-text {
      text-align: center;
      margin: 100px 0 160px;

      i {
        display: block;
        font-size: 144px;
        color: $elo-grey;
        margin-bottom: 50px;
      }

      .quiz-complete {
        display: block;
        max-width: 600px;
        font-family: Montserrat Reg;
        font-size: 14px;
        text-align: center;
        color: $elo-black-semi;
        margin-left: auto;
        margin-right: auto;
      }

      .will-be {
        font-family: var(--elo-font-sans-serif);
        font-size: 14px;
        font-weight: 500;
        color: $elo-grey;
      }
    }

    .Countdown {
      word-break: break-word;

      .Countdown-col-element {
        font-family: var(--elo-font-sans-serif);
        color: $elo-grey-dark;
        margin-right: 10px;

        strong {
          font-size: 48px;
          font-weight: bold;
          margin-right: 10px;
        }

        span {
          font-size: 24px;
        }
      }
    }
  }

  .round-image-36 {
    min-height: 36px;
    min-width: 36px;
    border-radius: 18px;
    background-size: cover;
  }

  .content-pagebuilder-view .content-block-filelist {
    .file-wrapper .text-side {
      max-width: 500px;
    }
  }

  @media (max-width: 991px) {
    .course-side-nav {
      position: absolute;

      .menu-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .content-pagebuilder-view .content-block-filelist {
      .file-wrapper .text-side {
        max-width: 250px;
      }
    }
  }
}
