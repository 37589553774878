@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-500.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-500.woff') format('woff');
}
// TODO: - remove font
@font-face {
  font-family: 'ES Klarheit Elopage';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/esklarheit-elopage/ESKlarheitElopage-Regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/esklarheit-elopage/ESKlarheitElopage-Regular.woff') format('woff');
}
// TODO: - remove font
@font-face {
  font-family: 'ES Klarheit Elopage';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/esklarheit-elopage/ESKlarheitElopage-Medium.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/esklarheit-elopage/ESKlarheitElopage-Medium.woff') format('woff');
}
// TODO: - remove font
@font-face {
  font-family: 'ES Klarheit Elopage';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/esklarheit-elopage/ESKlarheitElopage-Semibold.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/esklarheit-elopage/ESKlarheitElopage-Semibold.woff') format('woff');
}

:root {
  --elo-font-serif: 'ES Klarheit Elopage', serif;
  --elo-font-sans-serif: 'Inter', sans-serif;

  --elo-font-size-xs: 12px;
  --elo-font-size-sm: 14px;
  --elo-font-size-md: 16px;
  --elo-font-size-lg: 18px;
}
