@import 'src/ui-kit/styles/colors';

$green: #009900;
$red: #dc3545;
$yellow: #ddaa00;
$yellow-bg: #ffb900;
$check: #37de89;
$minus: #d9534f;

$out-sizes: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 30, 35, 40, 45, 50,
  55, 60, 65, 70, 80, 90, 100, 150, 160, 170, 200, 250, 260, 437;
$out-directions: top, right, bottom, left;

@each $out-size in $out-sizes {
  .padding-#{$out-size}px {
    padding: #{$out-size}px !important;
  }
  .margin-#{$out-size}px {
    margin: #{$out-size}px !important;
  }
  .font-#{$out-size}px {
    font-size: #{$out-size}px !important;
  }
}

@each $out-size in $out-sizes {
  @each $out-direction in $out-directions {
    .padding-#{$out-direction}-#{$out-size}px {
      padding-#{$out-direction}: #{$out-size}px !important;
    }
    .margin-#{$out-direction}-#{$out-size}px {
      margin-#{$out-direction}: #{$out-size}px !important;
    }
  }
}

@media (max-width: 767px) {
  .margin-left-0px-xs {
    margin-left: 0px !important;
  }
  .margin-right-0px-xs {
    margin-right: 0px !important;
  }
}

@mixin vendor($name, $argument) {
  -webkit-#{$name}: #{$argument};
  -ms-#{$name}: #{$argument};
  -moz-#{$name}: #{$argument};
  -o-#{$name}: #{$argument};
  #{$name}: #{$argument};
}

@mixin placeholder($color: $input-color-placeholder) {
  // Firefox 4-18
  &:-moz-placeholder {
    color: $color;
  }
  // Firefox 19+
  &::-moz-placeholder {
    color: $color;
  }
  // Internet Explorer 10+
  &:-ms-input-placeholder {
    color: $color;
  }
  // Safari and Chrome
  &::-webkit-input-placeholder {
    color: $color;
  }
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.width-100px {
  width: 100px !important;
}

.width-150px {
  width: 150px !important;
}

$width-sizes: 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60;
@each $width-size in $width-sizes {
  .width-#{$width-size}pp {
    width: '#{$width-size}% !important';
  }
}

$height-sizes: 5, 10, 15, 20, 25, 30, 35, 40, 44, 45, 50;
@each $height-size in $height-sizes {
  .height-#{$height-size}px {
    height: '#{$height-size}px !important';
  }
}

$max-width-sizes: 210;
@each $max-width-sizes in $max-width-sizes {
  .max-width-#{$max-width-sizes}px {
    max-width: '#{$max-width-sizes}px !important';
  }
}

.wrap-words {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-wrap {
  white-space: nowrap;
}

.white-ph {
  .form-control {
    &::-webkit-input-placeholder {
      color: rgba($elo-white, 0.5);
      opacity: 1;
    }
  }
}

.relative {
  position: relative;
}

.inline-block {
  display: inline-block;
}

.float-none {
  float: none !important;
}

.font-black {
  color: $elo-black !important;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.yellow {
  color: $yellow;
}

.yellow-bg {
  background-color: $yellow-bg;
}

.font-white {
  color: $elo-white;
}

.white-bg {
  background-color: $elo-white !important;
}

.bottom-border {
  border-bottom: 1px solid $elo-grey-40;
}

.fine-white-table {
  background-color: $elo-white;
  .table-responsive {
    padding: 15px;
  }
  table {
    margin-bottom: 0 !important;
    .pay-methods {
      text-align: center;
      width: 80px;
      &.top {
        padding: 0 0 2px 0;
        border-bottom-width: 1px;
        font-size: 10px;
      }
      img {
        height: 12px;
      }
      .fa-check-circle {
        color: $check;
        font-size: 18px;
      }
      .fa-minus-circle {
        color: $minus;
        font-size: 18px;
      }
      .checkbox-switch {
        .switcher-input {
          margin: 0;
          height: 0;
        }
        .switcher-span {
          margin: 0;
        }
        .switcher-label {
          margin: 0;
          margin-top: 3px;
        }
        .switcher-input:checked ~ label {
          background: $check;
        }
      }
    }
  }
}

.white-links a {
  color: $elo-white;
}

.footer_links {
  a:link,
  a:visited {
    color: rgba($elo-white, 0.5);
    font-size: 11px;
    font-weight: bold;
  }
}

.font-white {
  color: $elo-white;
}

.font-bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.empty {
  font-size: 25px;
  text-align: center;
  padding: 50px 0;
  color: $elo-grey;
  &.small {
    font-size: 22px;
    padding: 20px 0;
  }
  &.x-small {
    font-size: 16px;
    padding: 15px 0;
  }
}

.glyphicon-animate {
  -webkit-animation-name: rotateThis;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes "rotateThis" {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.text-xs-left {
  text-align: left;
}
.text-xs-right {
  text-align: right;
}
.text-xs-center {
  text-align: center;
}
.text-xs-justify {
  text-align: justify;
}
@media (min-width: 768px) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
  .text-sm-justify {
    text-align: justify;
  }
}

@media (min-width: 992px) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
  .text-md-justify {
    text-align: justify;
  }
}

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
  .text-lg-justify {
    text-align: justify;
  }
}

@media (max-width: 767px) {
  .float-xs-left {
    float: left;
  }
  .float-xs-right {
    float: right;
  }
}
@media (min-width: 768px) {
  .float-sm-left {
    float: left;
  }
  .float-sm-right {
    float: right;
  }
}

@media (min-width: 992px) {
  .float-md-left {
    float: left;
  }
  .float-md-right {
    float: right;
  }
}

@media (min-width: 1200px) {
  .float-lg-left {
    float: left;
  }
  .float-lg-right {
    float: right;
  }
}

.vertical-middle {
  vertical-align: middle !important;
}
.row-eq-height {
  @media (min-width: 750px) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.comma-separated:not(:last-child)::after {
  content: ', ';
}

$variants: (
  'small': 578px,
  'medium': 768px,
  'large': 992px,
  'xlarge': 1440px,
  'xxlarg': 1920px,
) !default;

@mixin breakpoint($variant) {
  @if map-has-key($variants, $variant) {
    @media (max-width: map-get($variants, $variant)) {
      @content;
    }
  }
}

.mt-10 {
  margin-top: 10px;
}
