@import 'src/ui-kit/styles/colors';

.partial-text-field {
  display: flex;
  flex-wrap: inherit;
  flex-direction: column;

  &__text-container {
    display: flex;
  }

  &__label-container {
    display: flex;
    align-items: center;
  }

  &__control {
    box-sizing: border-box;
    padding: 12px 18px;
    line-height: 1.4;
    border: 1px solid var(--field-border-color);
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-color: var(--field-bg-color);

    &:focus:not(&--error) {
      border: 1px solid var(--field-border-color-focus);
    }

    &--error {
      border: 1px solid var(--field-border-color-error);
    }

    &--lowercased {
      text-transform: lowercase;
    }

    // Override Bootstrap styles
    &::placeholder {
      color: var(--field-placeholder-font-color) !important;
      font-size: 14px !important;
      line-height: 1.4 !important;
    }
  }

  &__predefined-text {
    display: flex;
    align-items: center;
    width: fit-content;
    border: 1px solid var(--field-border-color);
    border-right: none;
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    color: var(--field-font-color);
    background-color: var(--field-prefix-bg-color);
    white-space: nowrap;
  }

  .field__error {
    justify-content: flex-end;
  }

  &.field--required {
    .field__label {
      span:first-child::after {
        content: '*';
        margin: -1px 2px;
        font-size: 15px;
        color: var(--field-required-font-color);
      }
    }
  }
}
