.react__tooltip-menu {
  width: 84px;
  height: 45px;

  .popover-inner {
    margin: auto;

    .tooltip-menu__item {
      line-height: 1;
      padding: 0;
      height: auto;

      i {
        font-size: 16px;
      }
    }
  }

  .popover-body {
    .row {
      .col:first-of-type {
        padding-right: 10px;
      }

      .col:last-of-type {
        padding-left: 10px;
      }
    }
  }
}
