@import 'src/ui-kit/styles/colors';

.shop-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px;
  background-color: $elo-grey-light;
  font-family: var(--elo-font-sans-serif);
  font-weight: 400;
  font-size: 12px;
  color: $elo-grey-dark;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__items {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 8px 0 0;
    padding-left: 0;
  }

  @media (max-width: 576px) {
    &__items {
      flex-direction: column;
    }

    .container {
      padding-bottom: 25px;
      padding-top: 20px;
      justify-content: space-between;
    }
    &__powered-by {
      display: block;
      text-align: right;
    }
  }

  &__item {
    margin-right: 20px;

    @media (max-width: 768px) {
      margin-right: 8px;
    }
  }

  &__link {
    color: $elo-grey-dark;

    &:hover,
    &:active {
      color: $elo-black-semi;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__powered-by-section {
    color: rgba($elo-black-semi, 0.43);
    margin-top: 6px;
    right: 15px;
    position: absolute;

    span {
      margin-bottom: 1px;
      line-height: 1;
    }

    a:hover {
      text-decoration: none;
    }

    @media (max-width: 576px) {
      margin: auto 0;
      position: inherit;
    }
  }

  &__company-name {
    color: $elo-black-semi;
    font-weight: bold;
    margin-left: 5px;

    > img {
      width: 74px;
      padding-bottom: 2px;
    }
  }
}
