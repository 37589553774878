// This file is auto generated
// Do not make any changes here
:root {
  --color-highlight-brand: #2bff99;
  --color-highlight-web: #17df87;
  --color-highlight-hover: #31ee95;
  --color-highlight-click: #05ab5b;
  --color-utility-success-dark: #047443;
  --color-utility-success-mid: #13b971;
  --color-utility-success-light: #d4ffe9;
  --color-utility-error-dark: #ce0018;
  --color-utility-error-mid: #ff1f39;
  --color-utility-error-light: #ffedef;
  --color-utility-error-dark-alt: #ff5a64;
  --color-utility-warning-dark: #ce4308;
  --color-utility-warning-mid: #ff9518;
  --color-utility-warning-light: #ffc485;
  --color-utility-alert-dark: #ffc700;
  --color-utility-alert-mid: #ffea7c;
  --color-utility-alert-light: #fffdcf;
  --color-primary-neutral-white: #ffffff;
  --color-primary-neutral-black: #000004;
  --color-primary-dg-brand: #21282e;
  --color-primary-dg-600: #141619;
  --color-primary-dg-500: #485056;
  --color-primary-dg-400: #71747a;
  --color-primary-dg-300: #8f9295;
  --color-primary-dg-200: #d7dadf;
  --color-primary-dg-100: #f3f5f8;
  --color-primary-cg-brand: #f0f1f6;
  --color-primary-cg-200: #cdcdd5;
  --color-primary-cg-100: #f9f9fe;
  --color-primary-wg-brand: #e4e1dd;
  --color-primary-wg-100: #f5f3f0;
  --color-decorative-purple-parent: #9d63ff;
  --color-decorative-purple-shade1: #c09ff8;
  --color-decorative-purple-shade2: #d4c5ff;
  --color-decorative-purple-shade3: #ede7ff;
  --color-decorative-teal-parent: #8cc8b9;
  --color-decorative-teal-shade1: #50a590;
  --color-decorative-teal-shade2: #57bf9c;
  --color-decorative-teal-shade3: #baded5;
  --color-decorative-mauve-parent: #d356ff;
  --color-decorative-mauve-shade1: #b400f5;
  --color-decorative-mauve-shade2: #e9adff;
  --color-decorative-mauve-shade3: #f4d6ff;
  --color-text-surface-primary: var(--color-primary-dg-brand);
  --color-text-surface-secondary: var(--color-primary-dg-400);
  --color-text-surface-highlight: var(--color-highlight-click);
  --color-text-surface-disabled: var(--color-primary-dg-300);
  --color-text-background-primary: var(--color-primary-neutral-black);
  --color-text-background-secondary: var(--color-primary-dg-500);
  --color-text-background-highlight: var(--color-highlight-click);
  --color-text-background-disabled: var(--color-primary-dg-300);
}
