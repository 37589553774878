// This file is auto generated
// Do not make any changes here
:root {
  --elo-space-48: 48px;
  --elo-space-40: 40px;
  --elo-space-32: 32px;
  --elo-space-24: 24px;
  --elo-space-16: 16px;
  --elo-space-12: 12px;
  --elo-space-8: 8px;
  --elo-space-4: 4px;
  --elo-space-0: 0px;
}
