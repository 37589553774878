@import 'src/ui-kit/styles/colors';

.white-widget {
  border-radius: 4px;
  background-color: $elo-white;
  padding: 24px;
  position: relative;

  &--with-icon {
    display: flex;
  }

  &__content {
    width: 100%;

    &--with-icon {
      width: 95%;
    }
  }

  &__icon {
    width: 5%;

    .elo-status-badge {
      overflow: visible;
      position: relative;
      top: -10px;
    }
  }

  &__title {
    color: var(--title-font-color);
    line-height: 1;
    font-size: var(--elo-font-size-lg);
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;

    &--margin {
      margin-bottom: 24px;
    }

    &--centered {
      text-align: center;
    }

    &--text-uppercase {
      text-transform: uppercase;
    }

    &--text-light-font {
      font-family: var(--elo-font-sans-serif);
      font-weight: 400;
      font-size: 13px;
      margin-bottom: 18px;
    }

    &--no-margin {
      margin-bottom: 0;
    }

    .elo-tooltip {
      &__wrapper {
        display: inline;
        margin-left: 8px;

        svg {
          margin-top: -3px;
        }
      }
    }
  }

  &--not-last {
    margin-bottom: 24px;
  }

  &--no-padding-bottom {
    padding-bottom: 0;
  }

  &--no-padding {
    padding: 0;
  }

  &--padding-bottom-15 {
    padding-bottom: 15px;
  }

  &--no-margin {
    margin-bottom: 0;
  }

  &--margin-top--30 {
    margin-top: 30px;
  }

  &--margin-top-16 {
    margin-top: 16px;
  }

  &--base-layer {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  }

  &--margin-bottom-10 {
    margin-bottom: 10px;
  }

  &--fluid {
    max-width: 100%;
  }

  &--fit-content {
    width: fit-content;
  }

  &--transparent {
    background-color: transparent;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--centered {
      justify-content: center !important;
    }

    &--flex-wrap {
      flex-wrap: wrap;

      .white-widget__title--margin {
        margin-bottom: 30px;

        & + .white-widget__sub-title {
          margin-bottom: 30px;
          margin-left: 0;
        }
      }
    }

    & .elo-btn {
      align-self: flex-start;
    }

    & img {
      height: 30px;
    }

    .custom-create-button {
      margin-bottom: 15px;
    }
  }

  &__sub-title {
    margin-left: 5px;
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    font-size: 13px;
    color: $elo-grey-dark;
    align-self: baseline;

    &--no-margin {
      margin: 0;
    }

    &--top-sm-margin {
      margin-top: 16px;
    }
  }

  &--disabled {
    .white-widget__header {
      .white-widget__title {
        color: $elo-grey;
      }
    }
  }

  &--middle-padding {
    padding: 15px 20px;
  }

  &--selected {
    border: 1px solid $elo-green;
  }

  &--rounded {
    border-radius: 16px;
  }

  &__buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: auto;
    justify-content: flex-end;
  }

  &__second-button {
    margin-bottom: 5px;
  }

  &__second-button + &__first-button {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  &__full {
    padding: 20px 52px 0 75px;
    background-color: var(--color-primary-dg-100);
    min-height: 100vh;
    height: 100%;
  }

  &--with-border {
    border: 1px solid var(--color-primary-dg-200);
    border-radius: 8px;
  }
}

.ww-box,
.white-widget {
  position: relative;
  font-size: 13px;
  color: $elo-black-semi;
  font-family: var(--elo-font-sans-serif);

  .ww-title {
    font-family: var(--elo-font-sans-serif);
    font-size: 18px;
  }

  .ww-label {
    font-family: var(--elo-font-sans-serif);
    color: $elo-grey-dark;
  }

  .ww-actions {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}

.white-widget {
  .ww-title {
    margin-bottom: 20px;
  }
}
