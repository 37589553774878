.custom-shop {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    flex: 1;

    @media (max-width: 991px) {
      margin-top: 20px;
    }

    .payment-ticket {
      padding-bottom: 1rem;
    }
  }
}

.iframed-custom-shop {
  height: unset;
}

.iframed-transparent {
  .payment-page {
    background-color: transparent;
  }
}
