@import '../../../styles/colors';
@import '../../../styles/fonts';

.tooltip-more {
  width: 565px;
  max-width: 565px;
  max-height: 30vh;
  box-shadow: 0 0 20px 0 rgba($elo-black, 0.2);
  border: none;
  z-index: 10;
  overflow: auto;
  border-radius: 4px;

  &--in-modal {
    z-index: 1051;
  }

  &__icon {
    &:not(:first-child) {
      margin-left: 6px;
    }
  }

  &__info-icon {
    display: inline;
  }

  .popover {
    border: none;
  }

  .bs-popover-auto[x-placement^='top'] {
    > .arrow {
      &::before,
      &::after {
        border-top-color: $elo-white !important;
      }
    }
  }

  .bs-popover-auto[x-placement^='right'] {
    > .arrow {
      &::before,
      &::after {
        border-right-color: $elo-white !important;
      }
    }
  }

  .bs-popover-auto[x-placement^='bottom'] {
    > .arrow {
      &::before,
      &::after {
        border-bottom-color: $elo-white !important;
      }
    }
  }

  .bs-popover-auto[x-placement^='left'] {
    > .arrow {
      &::before,
      &::after {
        border-left-color: $elo-white !important;
      }
    }
  }

  .popover-inner {
    overflow: auto;
    max-height: 30vh;
  }

  &--six-items {
    &.popover-inner {
      max-height: 400px;
      overflow: scroll;
    }
  }

  &--small-height {
    .popover-inner {
      max-height: 30vh;
    }
  }

  &--extra-small-height {
    .popover-inner {
      max-height: 30vh;
    }
  }

  .popover-body {
    padding: 0 30px;
    font-size: var(--elo-font-size-sm);
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    color: $elo-black-semi;
  }

  &__close {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  h5 {
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
    font-style: normal;
    font-size: 13px;
    text-transform: uppercase;
    color: $elo-black-semi;
    margin-bottom: 27px;
    margin-right: 10px;
    line-height: 1;
  }

  &__no-data {
    margin: 30px 0;
    font-size: var(--elo-font-size-sm);
    color: $elo-black-semi;
  }

  &__pricing-plans {
    text-align: left !important;

    .payment-plans {
      margin: 0;

      .row {
        margin-left: 0;
      }

      .payment-plan {
        padding: 0 0 16px;

        &:last-child {
          padding: 0;
        }

        .details {
          font-style: normal;
        }

        .custom-check {
          line-height: 1;
        }

        &__container {
          .custom-check {
            font-size: 13px;
            color: $elo-black-semi;
            font-style: initial;
            padding-left: 0;
          }
        }
      }

      .pr-1 {
        font-family: var(--elo-font-sans-serif);
        font-weight: 400;
        font-size: var(--elo-font-size-sm);
        font-style: normal;
        color: $elo-black-semi;
        padding-left: 0;

        .custom-check {
          padding-left: 0;
        }
      }

      .details {
        padding-left: 0;
        margin-left: 0;
      }
    }
  }

  &__section {
    border-bottom: solid 1px rgba($elo-grey, 0.2);
    margin: 15px;
    padding-bottom: 30px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &__section-title {
    display: flex;
    justify-content: space-between;
  }

  .no-data {
    margin-bottom: 20px;
  }

  &__edit-icon {
    font-size: var(--elo-font-size-sm);
    color: var(--icon-button-color);
    cursor: pointer;
  }

  &--with-table {
    .popover-body {
      padding: 20px;
      font-style: normal;
    }

    .elo-table-container {
      overflow-y: hidden;
    }

    .elo-table thead tr {
      height: 30px;

      th {
        padding: 9px;
      }
    }
  }

  &--small,
  &--medium,
  &--large,
  &--responsive {
    .popover {
      max-width: 320px;
    }

    .popover-body {
      padding: 20px;
      font-style: normal;

      hr {
        margin: 10px 0;
      }
    }

    .popover-inner {
      width: 100%;
      max-width: 100%;
    }
  }

  &--italic-body {
    .popover-body {
      color: $elo-grey;
      font-style: normal;
      line-height: 18px;
    }
  }

  &--medium {
    .popover {
      max-width: 565px;
    }
  }

  &--large {
    .popover {
      max-width: 780px;
    }
  }

  &--responsive {
    .popover {
      width: 80%;
      max-width: 80%;
    }
  }

  &__container {
    .popover {
      width: 565px;
      max-width: 565px;
      border: none;
      box-shadow: none;
    }
  }

  &--width-780 {
    .popover {
      width: 780px;
      max-width: 780px;
    }

    .popover-body {
      min-width: 780px;
    }

    .popover-inner {
      width: 100%;
      max-width: 100%;
    }
  }

  .elo-table .transactions-tooltip-row td:first-child {
    padding-left: 10px;
    font-size: 12px;
  }
}

.popover-info-icon {
  cursor: pointer;
  opacity: 1;
}

.tooltip-picker {
  .arrow {
    display: none;
  }
}

.popover-info-icon-wrapper {
  display: inline-block;
  margin-left: 5px;
}

svg:hover,
svg:active {
  .popover-info-icon-circle {
    fill: $elo-grey;
  }
}

.tooltip-field {
  display: flex;
  justify-content: space-between;
  font-size: var(--elo-font-size-sm);
  font-style: normal;
  line-height: 1;
  color: $elo-black-semi;
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }

  &__name {
    font-family: var(--elo-font-sans-serif);
    font-weight: normal;
    margin-left: 30px;
    color: $elo-grey-dark;
  }
}
