/* Nunito Sans */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/nunito-sans/nunito-sans-v12-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/nunito-sans/nunito-sans-v12-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/nunito-sans/nunito-sans-v12-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/nunito-sans/nunito-sans-v12-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/nunito-sans/nunito-sans-v12-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/nunito-sans/nunito-sans-v12-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/nunito-sans/nunito-sans-v12-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/nunito-sans/nunito-sans-v12-latin-700italic.woff') format('woff');
}

/* Inconsolata */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inconsolata/inconsolata-v31-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inconsolata/inconsolata-v31-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inconsolata/inconsolata-v31-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inconsolata/inconsolata-v31-latin-700.woff') format('woff');
}

/* Fraunces */
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/fraunces/fraunces-v24-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/fraunces/fraunces-v24-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/fraunces/fraunces-v24-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/fraunces/fraunces-v24-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/fraunces/fraunces-v24-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/fraunces/fraunces-v24-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Fraunces';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/fraunces/fraunces-v24-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/fraunces/fraunces-v24-latin-700italic.woff') format('woff');
}

/* Public Sans */
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/public-sans/public-sans-v14-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/public-sans/public-sans-v14-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/public-sans/public-sans-v14-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/public-sans/public-sans-v14-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/public-sans/public-sans-v14-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/public-sans/public-sans-v14-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/public-sans/public-sans-v14-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/public-sans/public-sans-v14-latin-700italic.woff') format('woff');
}

/* Red Hat Display */
@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/red-hat-display/red-hat-display-v14-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/red-hat-display/red-hat-display-v14-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/red-hat-display/red-hat-display-v14-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/red-hat-display/red-hat-display-v14-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Red Hat Display';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/red-hat-display/red-hat-display-v14-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/red-hat-display/red-hat-display-v14-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Red Hat Display';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/red-hat-display/red-hat-display-v14-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/red-hat-display/red-hat-display-v14-latin-700italic.woff') format('woff');
}

/* Lexend Deca */
@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lexend-deca/lexend-deca-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lexend-deca/lexend-deca-v17-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lexend-deca/lexend-deca-v17-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lexend-deca/lexend-deca-v17-latin-700.woff') format('woff');
}

/* Sansita */
@font-face {
  font-family: 'Sansita';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/sansita/sansita-v10-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/sansita/sansita-v10-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Sansita';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/sansita/sansita-v10-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/sansita/sansita-v10-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Sansita';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/sansita/sansita-v10-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/sansita/sansita-v10-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Sansita';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/sansita/sansita-v10-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/sansita/sansita-v10-latin-700italic.woff') format('woff');
}

/* Tourney */
@font-face {
  font-family: 'Tourney';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/tourney/tourney-v8-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/tourney/tourney-v8-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Tourney';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/tourney/tourney-v8-latin-600.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/tourney/tourney-v8-latin-600.woff') format('woff');
}
@font-face {
  font-family: 'Tourney';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/tourney/tourney-v8-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/tourney/tourney-v8-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Tourney';
  font-style: italic;
  font-weight: 600;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/tourney/tourney-v8-latin-600italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/tourney/tourney-v8-latin-600italic.woff') format('woff');
}

/* Big Shoulders Stencil Display */
@font-face {
  font-family: 'Big Shoulders Stencil Display';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/big-shoulders-stencil/big-shoulders-stencil-display-v21-latin-regular.woff2')
      format('woff2'),
    url('https://fonts.ablefy.io/woff/big-shoulders-stencil/big-shoulders-stencil-display-v21-latin-regular.woff')
      format('woff');
}
@font-face {
  font-family: 'Big Shoulders Stencil Display';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/big-shoulders-stencil/big-shoulders-stencil-display-v21-latin-500.woff2')
      format('woff2'),
    url('https://fonts.ablefy.io/woff/big-shoulders-stencil/big-shoulders-stencil-display-v21-latin-500.woff')
      format('woff');
}
@font-face {
  font-family: 'Big Shoulders Stencil Display';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/big-shoulders-stencil-display/big-shoulders-stencil-display-v21-latin-700.woff2')
      format('woff2'),
    url('https://fonts.ablefy.io/woff/big-shoulders-stencil-display/big-shoulders-stencil-display-v21-latin-700.woff')
      format('woff');
}

/* MuseoModerno */
@font-face {
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/museomoderno/museomoderno-v22-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/museomoderno/museomoderno-v22-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'MuseoModerno';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/museomoderno/museomoderno-v22-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/museomoderno/museomoderno-v22-latin-700.woff') format('woff');
}

/* Grandstander */
@font-face {
  font-family: 'Grandstander';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/grandstander/grandstander-v11-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/grandstander/grandstander-v11-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Grandstander';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/grandstander/grandstander-v11-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/grandstander/grandstander-v11-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Grandstander';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/grandstander/grandstander-v11-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/grandstander/grandstander-v11-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Grandstander';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/grandstander/grandstander-v11-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/grandstander/grandstander-v11-latin-700italic.woff') format('woff');
}

/* Catamaran */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/catamaran/catamaran-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/catamaran/catamaran-v17-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/catamaran/catamaran-v17-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/catamaran/catamaran-v17-latin-700.woff') format('woff');
}

/* Style Script */
@font-face {
  font-family: 'Style Script';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/style-script/style-script-v7-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/style-script/style-script-v7-latin-regular.woff') format('woff');
}

/* Open Sans */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/open-sans/open-sans-v34-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/open-sans/open-sans-v34-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/open-sans/open-sans-v34-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/open-sans/open-sans-v34-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/open-sans/open-sans-v34-latin-800.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/open-sans/open-sans-v34-latin-800.woff') format('woff');
}

/* Poppins */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/poppins/poppins-v20-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/poppins/poppins-v20-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/poppins/poppins-v20-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/poppins/poppins-v20-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/poppins/poppins-v20-latin-600.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/poppins/poppins-v20-latin-600.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/poppins/poppins-v20-latin-600italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/poppins/poppins-v20-latin-600italic.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/poppins/poppins-v20-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/poppins/poppins-v20-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/poppins/poppins-v20-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/poppins/poppins-v20-latin-700italic.woff') format('woff');
}

/* Barlow */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 300;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/barlow/barlow-v12-latin-300.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/barlow/barlow-v12-latin-300.woff') format('woff');
}
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 300;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/barlow/barlow-v12-latin-300italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/barlow/barlow-v12-latin-300italic.woff') format('woff');
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/barlow/barlow-v12-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/barlow/barlow-v12-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/barlow/barlow-v12-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/barlow/barlow-v12-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/barlow/barlow-v12-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/barlow/barlow-v12-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/barlow/barlow-v12-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/barlow/barlow-v12-latin-700italic.woff') format('woff');
}

/* PT Sans */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/pt-sans/pt-sans-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/pt-sans/pt-sans-v17-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/pt-sans/pt-sans-v17-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/pt-sans/pt-sans-v17-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/pt-sans/pt-sans-v17-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/pt-sans/pt-sans-v17-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/pt-sans/pt-sans-v17-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/pt-sans/pt-sans-v17-latin-700italic.woff') format('woff');
}

/* Raleway */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/raleway/raleway-v28-latin-300.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/raleway/raleway-v28-latin-300.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/raleway/raleway-v28-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/raleway/raleway-v28-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/raleway/raleway-v28-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/raleway/raleway-v28-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/raleway/raleway-v28-latin-300italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/raleway/raleway-v28-latin-300italic.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/raleway/raleway-v28-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/raleway/raleway-v28-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/raleway/raleway-v28-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/raleway/raleway-v28-latin-700italic.woff') format('woff');
}

/* Abhaya Libre */
@font-face {
  font-family: 'Abhaya Libre';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/abhaya-libre/abhaya-libre-v13-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/abhaya-libre/abhaya-libre-v13-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Abhaya Libre';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/abhaya-libre/abhaya-libre-v13-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/abhaya-libre/abhaya-libre-v13-latin-700.woff') format('woff');
}

/* Abril Fatface */
@font-face {
  font-family: 'Abril Fatface';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/abril-fatface/abril-fatface-v19-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/abril-fatface/abril-fatface-v19-latin-regular.woff') format('woff');
}

/* Alegreya */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/alegreya/alegreya-v29-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/alegreya/alegreya-v29-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/alegreya/alegreya-v29-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/alegreya/alegreya-v29-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/alegreya/alegreya-v29-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/alegreya/alegreya-v29-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/alegreya/alegreya-v29-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/alegreya/alegreya-v29-latin-700italic.woff') format('woff');
}

/* Alice */
@font-face {
  font-family: 'Alice';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/alice/alice-v20-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/alice/alice-v20-latin-regular.woff') format('woff');
}

/* Amatic SC */
@font-face {
  font-family: 'Amatic SC';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/amatic-sc/amatic-sc-v24-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/amatic-sc/amatic-sc-v24-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Amatic SC';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/amatic-sc/amatic-sc-v24-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/amatic-sc/amatic-sc-v24-latin-700.woff') format('woff');
}

/* Anonymous Pro */
@font-face {
  font-family: 'Anonymous Pro';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/anonymous-pro/anonymous-pro-v21-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/anonymous-pro/anonymous-pro-v21-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Anonymous Pro';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/anonymous-pro/anonymous-pro-v21-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/anonymous-pro/anonymous-pro-v21-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Anonymous Pro';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/anonymous-pro/anonymous-pro-v21-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/anonymous-pro/anonymous-pro-v21-latin-700italic.woff') format('woff');
}
@font-face {
  font-family: 'Anonymous Pro';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/anonymous-pro/anonymous-pro-v21-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/anonymous-pro/anonymous-pro-v21-latin-700.woff') format('woff');
}

/* Anton */
@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/anton/anton-v23-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/anton/anton-v23-latin-regular.woff') format('woff');
}

/* Arapey */
@font-face {
  font-family: 'Arapey';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/arapey/arapey-v16-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/arapey/arapey-v16-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Arapey';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/arapey/arapey-v16-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/arapey/arapey-v16-latin-italic.woff') format('woff');
}

/* Archivo Black */
@font-face {
  font-family: 'Archivo Black';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/archivo-black/archivo-black-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/archivo-black/archivo-black-v17-latin-regular.woff') format('woff');
}

/* Arimo */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/arimo/arimo-v27-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/arimo/arimo-v27-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/arimo/arimo-v27-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/arimo/arimo-v27-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/arimo/arimo-v27-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/arimo/arimo-v27-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/arimo/arimo-v27-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/arimo/arimo-v27-latin-700italic.woff') format('woff');
}

/* Arvo */
@font-face {
  font-family: 'Arvo';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/arvo/arvo-v20-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/arvo/arvo-v20-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Arvo';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/arvo/arvo-v20-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/arvo/arvo-v20-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Arvo';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/arvo/arvo-v20-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/arvo/arvo-v20-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Arvo';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/arvo/arvo-v20-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/arvo/arvo-v20-latin-700italic.woff') format('woff');
}

/* B612 */
@font-face {
  font-family: 'B612';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/b612/b612-v12-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/b612/b612-v12-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'B612';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/b612/b612-v12-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/b612/b612-v12-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'B612';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/b612/b612-v12-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/b612/b612-v12-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'B612';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/b612/b612-v12-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/b612/b612-v12-latin-700italic.woff') format('woff');
}

/* Bebas Neue */
@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/bebas-neue/bebas-neue-v9-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/bebas-neue/bebas-neue-v9-latin-regular.woff') format('woff');
}

/* Belleza */
@font-face {
  font-family: 'Belleza';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/belleza/belleza-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/belleza/belleza-v17-latin-regular.woff') format('woff');
}

/* BioRhyme */
@font-face {
  font-family: 'BioRhyme';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/biorhyme/biorhyme-v12-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/biorhyme/biorhyme-v12-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'BioRhyme';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/biorhyme/biorhyme-v12-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/biorhyme/biorhyme-v12-latin-700.woff') format('woff');
}

/* Blinker */
@font-face {
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/blinker/blinker-v12-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/blinker/blinker-v12-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/blinker/blinker-v12-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/blinker/blinker-v12-latin-700.woff') format('woff');
}

/* Cairo */
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cairo/cairo-v20-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cairo/cairo-v20-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cairo/cairo-v20-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cairo/cairo-v20-latin-700.woff') format('woff');
}

/* Cardo */
@font-face {
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cardo/cardo-v19-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cardo/cardo-v19-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Cardo';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cardo/cardo-v19-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cardo/cardo-v19-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Cardo';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cardo/cardo-v19-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cardo/cardo-v19-latin-700.woff') format('woff');
}

/* Caveat */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/caveat/caveat-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/caveat/caveat-v17-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/caveat/caveat-v17-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/caveat/caveat-v17-latin-700.woff') format('woff');
}

/* Caveat Brush */
@font-face {
  font-family: 'Caveat Brush';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/caveat-brush/caveat-brush-v11-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/caveat-brush/caveat-brush-v11-latin-regular.woff') format('woff');
}

/* Comfortaa */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/comfortaa/comfortaa-v40-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/comfortaa/comfortaa-v40-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/comfortaa/comfortaa-v40-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/comfortaa/comfortaa-v40-latin-700.woff') format('woff');
}

/* Concert One */
@font-face {
  font-family: 'Concert One';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/concert-one/concert-one-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/concert-one/concert-one-v17-latin-regular.woff') format('woff');
}

/* Cormorant */
@font-face {
  font-family: 'Cormorant';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cormorant/cormorant-v18-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cormorant/cormorant-v18-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Cormorant';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cormorant/cormorant-v18-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cormorant/cormorant-v18-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Cormorant';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cormorant/cormorant-v18-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cormorant/cormorant-v18-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Cormorant';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cormorant/cormorant-v18-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cormorant/cormorant-v18-latin-700italic.woff') format('woff');
}

/* Cormorant Garamond */
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cormorant-garamond/cormorant-garamond-v16-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cormorant-garamond/cormorant-garamond-v16-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cormorant-garamond/cormorant-garamond-v16-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cormorant-garamond/cormorant-garamond-v16-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cormorant-garamond/cormorant-garamond-v16-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cormorant-garamond/cormorant-garamond-v16-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/cormorant-garamond/cormorant-garamond-v16-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/cormorant-garamond/cormorant-garamond-v16-latin-700italic.woff') format('woff');
}

/* Courier Prime */
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/courier-prime/courier-prime-v7-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/courier-prime/courier-prime-v7-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Courier Prime';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/courier-prime/courier-prime-v7-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/courier-prime/courier-prime-v7-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/courier-prime/courier-prime-v7-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/courier-prime/courier-prime-v7-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Courier Prime';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/courier-prime/courier-prime-v7-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/courier-prime/courier-prime-v7-latin-700italic.woff') format('woff');
}

/* Crimson Text */
@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/crimson-text/crimson-text-v19-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/crimson-text/crimson-text-v19-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Crimson Text';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/crimson-text/crimson-text-v19-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/crimson-text/crimson-text-v19-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Crimson Text';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/crimson-text/crimson-text-v19-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/crimson-text/crimson-text-v19-latin-700italic.woff') format('woff');
}
@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/crimson-text/crimson-text-v19-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/crimson-text/crimson-text-v19-latin-700.woff') format('woff');
}

/* Eczar */
@font-face {
  font-family: 'Eczar';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/eczar/eczar-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/eczar/eczar-v17-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Eczar';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/eczar/eczar-v17-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/eczar/eczar-v17-latin-700.woff') format('woff');
}

/* Exo 2 */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/exo-2/exo-2-v20-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/exo-2/exo-2-v20-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/exo-2/exo-2-v20-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/exo-2/exo-2-v20-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/exo-2/exo-2-v20-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/exo-2/exo-2-v20-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/exo-2/exo-2-v20-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/exo-2/exo-2-v20-latin-700italic.woff') format('woff');
}

/* Fira Sans */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/fira-sans/fira-sans-v16-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/fira-sans/fira-sans-v16-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/fira-sans/fira-sans-v16-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/fira-sans/fira-sans-v16-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/fira-sans/fira-sans-v16-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/fira-sans/fira-sans-v16-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/fira-sans/fira-sans-v16-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/fira-sans/fira-sans-v16-latin-700italic.woff') format('woff');
}

/* Fjalla One */
@font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/fjalla-one/fjalla-one-v13-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/fjalla-one/fjalla-one-v13-latin-regular.woff') format('woff');
}

/* Forum */
@font-face {
  font-family: 'Forum';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/forum/forum-v16-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/forum/forum-v16-latin-regular.woff') format('woff');
}

/* Frank Ruhl Libre */
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/frank-ruhl-libre/frank-ruhl-libre-v12-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/frank-ruhl-libre/frank-ruhl-libre-v12-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/frank-ruhl-libre/frank-ruhl-libre-v12-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/frank-ruhl-libre/frank-ruhl-libre-v12-latin-700.woff') format('woff');
}

/* IBM Plex Serif */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ibm-plex-serif/ibm-plex-serif-v15-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ibm-plex-serif/ibm-plex-serif-v15-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ibm-plex-serif/ibm-plex-serif-v15-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ibm-plex-serif/ibm-plex-serif-v15-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ibm-plex-serif/ibm-plex-serif-v15-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ibm-plex-serif/ibm-plex-serif-v15-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ibm-plex-serif/ibm-plex-serif-v15-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ibm-plex-serif/ibm-plex-serif-v15-latin-700italic.woff') format('woff');
}

/* Inter */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-500.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-500.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-600.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-600.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inter/inter-v12-latin-700.woff') format('woff');
}

/* Josefin Sans */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/josefin-sans/josefin-sans-v25-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/josefin-sans/josefin-sans-v25-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/josefin-sans/josefin-sans-v25-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/josefin-sans/josefin-sans-v25-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/josefin-sans/josefin-sans-v25-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/josefin-sans/josefin-sans-v25-latin-italic.woff') format('woff');
}

/* Karla */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/karla/karla-v23-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/karla/karla-v23-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/karla/karla-v23-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/karla/karla-v23-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/karla/karla-v23-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/karla/karla-v23-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/karla/karla-v23-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/karla/karla-v23-latin-700italic.woff') format('woff');
}

/* Lato */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lato/lato-v23-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lato/lato-v23-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lato/lato-v23-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lato/lato-v23-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lato/lato-v23-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lato/lato-v23-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lato/lato-v23-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lato/lato-v23-latin-700italic.woff') format('woff');
}

/* Lobster */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lobster/lobster-v28-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lobster/lobster-v28-latin-regular.woff') format('woff');
}

/* Lora */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lora/lora-v26-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lora/lora-v26-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lora/lora-v26-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lora/lora-v26-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lora/lora-v26-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lora/lora-v26-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lora/lora-v26-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lora/lora-v26-latin-700italic.woff') format('woff');
}

/* Merriweather */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/merriweather/merriweather-v30-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/merriweather/merriweather-v30-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/merriweather/merriweather-v30-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/merriweather/merriweather-v30-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/merriweather/merriweather-v30-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/merriweather/merriweather-v30-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/merriweather/merriweather-v30-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/merriweather/merriweather-v30-latin-700italic.woff') format('woff');
}

/* Montserrat */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/montserrat/montserrat-v25-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/montserrat/montserrat-v25-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/montserrat/montserrat-v25-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/montserrat/montserrat-v25-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/montserrat/montserrat-v25-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/montserrat/montserrat-v25-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/montserrat/montserrat-v25-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/montserrat/montserrat-v25-latin-700italic.woff') format('woff');
}

/* Nixie One */
@font-face {
  font-family: 'Nixie One';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/nixie-one/nixie-one-v16-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/nixie-one/nixie-one-v16-latin-regular.woff') format('woff');
}

/* Noto Sans */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/noto-sans/noto-sans-v27-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/noto-sans/noto-sans-v27-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/noto-sans/noto-sans-v27-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/noto-sans/noto-sans-v27-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/noto-sans/noto-sans-v27-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/noto-sans/noto-sans-v27-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/noto-sans/noto-sans-v27-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/noto-sans/noto-sans-v27-latin-700italic.woff') format('woff');
}

/* Noto Serif */
@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/noto-serif/noto-serif-v21-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/noto-serif/noto-serif-v21-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Noto Serif';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/noto-serif/noto-serif-v21-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/noto-serif/noto-serif-v21-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/noto-serif/noto-serif-v21-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/noto-serif/noto-serif-v21-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Noto Serif';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/noto-serif/noto-serif-v21-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/noto-serif/noto-serif-v21-latin-700italic.woff') format('woff');
}

/* Nunito */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/nunito/nunito-v25-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/nunito/nunito-v25-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/nunito/nunito-v25-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/nunito/nunito-v25-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/nunito/nunito-v25-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/nunito/nunito-v25-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/nunito/nunito-v25-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/nunito/nunito-v25-latin-700italic.woff') format('woff');
}

/* Old Standard TT */
@font-face {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/old-standard-tt/old-standard-tt-v18-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/old-standard-tt/old-standard-tt-v18-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Old Standard TT';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/old-standard-tt/old-standard-tt-v18-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/old-standard-tt/old-standard-tt-v18-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/old-standard-tt/old-standard-tt-v18-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/old-standard-tt/old-standard-tt-v18-latin-700.woff') format('woff');
}

/* Oswald */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/oswald/oswald-v49-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/oswald/oswald-v49-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/oswald/oswald-v49-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/oswald/oswald-v49-latin-700.woff') format('woff');
}

/* Oxygen */
@font-face {
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/oxygen/oxygen-v15-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/oxygen/oxygen-v15-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/oxygen/oxygen-v15-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/oxygen/oxygen-v15-latin-700.woff') format('woff');
}

/* Playfair Display */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/playfair-display/playfair-display-v30-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/playfair-display/playfair-display-v30-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/playfair-display/playfair-display-v30-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/playfair-display/playfair-display-v30-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/playfair-display/playfair-display-v30-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/playfair-display/playfair-display-v30-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Playfair Display';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/playfair-display/playfair-display-v30-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/playfair-display/playfair-display-v30-latin-700italic.woff') format('woff');
}

/* IBM Plex Sans */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-700italic.woff') format('woff');
}

@font-face {
  font-family: 'Plex';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Plex';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Plex';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Plex';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ibm-plex-sans/ibm-plex-sans-v14-latin-700italic.woff') format('woff');
}
/* PT Serif */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/pt-serif/pt-serif-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/pt-serif/pt-serif-v17-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/pt-serif/pt-serif-v17-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/pt-serif/pt-serif-v17-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/pt-serif/pt-serif-v17-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/pt-serif/pt-serif-v17-latin-700italic.woff') format('woff');
}
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/pt-serif/pt-serif-v17-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/pt-serif/pt-serif-v17-latin-700.woff') format('woff');
}

/* Quicksand */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/quicksand/quicksand-v30-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/quicksand/quicksand-v30-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/quicksand/quicksand-v30-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/quicksand/quicksand-v30-latin-700.woff') format('woff');
}

/* Rakkas */
@font-face {
  font-family: 'Rakkas';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/rakkas/rakkas-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/rakkas/rakkas-v17-latin-regular.woff') format('woff');
}

/* Roboto */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/roboto/roboto-v30-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/roboto/roboto-v30-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/roboto/roboto-v30-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/roboto/roboto-v30-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/roboto/roboto-v30-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/roboto/roboto-v30-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/roboto/roboto-v30-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/roboto/roboto-v30-latin-700italic.woff') format('woff');
}

/* Roboto Slab */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 100;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/roboto-slab/roboto-slab-v24-latin-100.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/roboto-slab/roboto-slab-v24-latin-100.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/roboto-slab/roboto-slab-v24-latin-300.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/roboto-slab/roboto-slab-v24-latin-300.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/roboto-slab/roboto-slab-v24-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/roboto-slab/roboto-slab-v24-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/roboto-slab/roboto-slab-v24-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/roboto-slab/roboto-slab-v24-latin-700.woff') format('woff');
}

/* Rubik */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/rubik/rubik-v21-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/rubik/rubik-v21-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/rubik/rubik-v21-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/rubik/rubik-v21-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/rubik/rubik-v21-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/rubik/rubik-v21-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/rubik/rubik-v21-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/rubik/rubik-v21-latin-700italic.woff') format('woff');
}

/* Sanchez */
@font-face {
  font-family: 'Sanchez';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/sanchez/sanchez-v13-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/sanchez/sanchez-v13-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Sanchez';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/sanchez/sanchez-v13-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/sanchez/sanchez-v13-latin-italic.woff') format('woff');
}

/* Shrikhand */
@font-face {
  font-family: 'Shrikhand';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/shrikhand/shrikhand-v11-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/shrikhand/shrikhand-v11-latin-regular.woff') format('woff');
}

/* Spectral */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/spectral/spectral-v13-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/spectral/spectral-v13-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Spectral';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/spectral/spectral-v13-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/spectral/spectral-v13-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/spectral/spectral-v13-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/spectral/spectral-v13-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Spectral';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/spectral/spectral-v13-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/spectral/spectral-v13-latin-700italic.woff') format('woff');
}

/* Tenor Sans */
@font-face {
  font-family: 'Tenor Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/tenor-sans/tenor-sans-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/tenor-sans/tenor-sans-v17-latin-regular.woff') format('woff');
}

/* Titillium Web */
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/titillium-web/titillium-web-v15-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/titillium-web/titillium-web-v15-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/titillium-web/titillium-web-v15-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/titillium-web/titillium-web-v15-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/titillium-web/titillium-web-v15-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/titillium-web/titillium-web-v15-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/titillium-web/titillium-web-v15-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/titillium-web/titillium-web-v15-latin-700italic.woff') format('woff');
}

/* Ubuntu */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ubuntu/ubuntu-v20-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ubuntu/ubuntu-v20-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ubuntu/ubuntu-v20-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ubuntu/ubuntu-v20-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ubuntu/ubuntu-v20-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ubuntu/ubuntu-v20-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/ubuntu/ubuntu-v20-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/ubuntu/ubuntu-v20-latin-700italic.woff') format('woff');
}

/* Varela */
@font-face {
  font-family: 'Varela';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/varela/varela-v16-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/varela/varela-v16-latin-regular.woff') format('woff');
}

/* Vollkorn */
@font-face {
  font-family: 'Vollkorn';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/vollkorn/vollkorn-v21-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/vollkorn/vollkorn-v21-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Vollkorn';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/vollkorn/vollkorn-v21-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/vollkorn/vollkorn-v21-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Vollkorn';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/vollkorn/vollkorn-v21-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/vollkorn/vollkorn-v21-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Vollkorn';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/vollkorn/vollkorn-v21-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/vollkorn/vollkorn-v21-latin-700italic.woff') format('woff');
}

/* Work Sans */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/work-sans/work-sans-v18-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/work-sans/work-sans-v18-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/work-sans/work-sans-v18-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/work-sans/work-sans-v18-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/work-sans/work-sans-v18-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/work-sans/work-sans-v18-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/work-sans/work-sans-v18-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/work-sans/work-sans-v18-latin-700italic.woff') format('woff');
}

/* Yatra One */
@font-face {
  font-family: 'Yatra One';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/yatra-one/yatra-one-v14-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/yatra-one/yatra-one-v14-latin-regular.woff') format('woff');
}

/* Yeseva One */
@font-face {
  font-family: 'Yeseva One';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/yeseva-one/yeseva-one-v20-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/yeseva-one/yeseva-one-v20-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'icomoon';
  src: url('https://fonts.ablefy.io/woff/icomoon/icomoon.eot?ltercc');
  src:
    url('https://fonts.ablefy.io/woff/icomoon/icomoon.eot?ltercc#iefix') format('embedded-opentype'),
    url('https://fonts.ablefy.io/woff/icomoon/icomoon.ttf?ltercc') format('truetype'),
    url('https://fonts.ablefy.io/woff/icomoon/icomoon.woff?ltercc') format('woff'),
    url('https://fonts.ablefy.io/woff/icomoon/icomoon.svg?ltercc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cabin';
  src: url('https://fonts.ablefy.io/woff/cabin/Cabin.otf');
  font-display: swap;
}
@font-face {
  font-family: 'Didot';
  src: url('https://fonts.ablefy.io/woff/didot/Didot.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Libre Baskerville';
  src: url('https://fonts.ablefy.io/ttf/LibreBaskerville-Regular.ttf');
  font-display: swap;
}

/* ABeeZee */
@font-face {
  font-family: 'ABeeZee';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/abeezee/abeezee-v22-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/abeezee/abeezee-v22-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'ABeeZee';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/abeezee/abeezee-v22-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/abeezee/abeezee-v22-latin-italic.woff') format('woff');
}

/* Amaranth */
@font-face {
  font-family: 'Amaranth';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/amaranth/amaranth-v18-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/amaranth/amaranth-v18-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Amaranth';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/amaranth/amaranth-v18-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/amaranth/amaranth-v18-latin-italic.woff') format('woff');
}

/* Dancing Script */
@font-face {
  font-family: 'Dancing Script';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/dancing-script/dancing-script-v24-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/dancing-script/dancing-script-v24-latin-regular.woff') format('woff');
}

/* Exo */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/exo/exo-v20-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/exo/exo-v20-latin-regular.woff') format('woff');
}

/* Inder */
@font-face {
  font-family: 'Inder';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/inder/inder-v14-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/inder/inder-v14-latin-regular.woff') format('woff');
}

/* Indie Flower */
@font-face {
  font-family: 'Indie Flower';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/indie-flower/indie-flower-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/indie-flower/indie-flower-v17-latin-regular.woff') format('woff');
}

/* Lilita One */
@font-face {
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lilita-one/lilita-one-v13-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lilita-one/lilita-one-v13-latin-regular.woff') format('woff');
}

/* Lobster Two */
@font-face {
  font-family: 'Lobster Two';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/lobster-two/lobster-two-v18-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/lobster-two/lobster-two-v18-latin-regular.woff') format('woff');
}

/* Merienda */
@font-face {
  font-family: 'Merienda';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/merienda/merienda-v14-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/merienda/merienda-v14-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Merienda';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/merienda/merienda-v14-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/merienda/merienda-v14-latin-700.woff') format('woff');
}
/* Pacifico */
@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/pacifico/pacifico-v22-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/pacifico/pacifico-v22-latin-regular.woff') format('woff');
}

/* Patrick Hand */
@font-face {
  font-family: 'Patrick Hand';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/patrick-hand/patrick-hand-v19-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/patrick-hand/patrick-hand-v19-latin-regular.woff') format('woff');
}

/* Playball */
@font-face {
  font-family: 'Playball';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/playball/playball-v16-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/playball/playball-v16-latin-regular.woff') format('woff');
}

/* Righteous */
@font-face {
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/righteous/righteous-v13-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/righteous/righteous-v13-latin-regular.woff') format('woff');
}

/* Tauri */
@font-face {
  font-family: 'Tauri';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/tauri/tauri-v16-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/tauri/tauri-v16-latin-regular.woff') format('woff');
}

/* PT Sans Narrow */
@font-face {
  font-family: 'PT Sans Narrow';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/pt-sans-narrow/pt-sans-narrow-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/pt-sans-narrow/pt-sans-narrow-v17-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'PT Sans Narrow';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/pt-sans-narrow/pt-sans-narrow-v17-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/pt-sans-narrow/pt-sans-narrow-v17-latin-700.woff') format('woff');
}

/* Yanone Kaffeesatz */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/yanone-kaffeesatz/yanone-kaffeesatz-v24-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/yanone-kaffeesatz/yanone-kaffeesatz-v24-latin-regular.woff') format('woff');
}

/* Varta */
@font-face {
  font-family: 'Varta';
  font-style: normal;
  font-weight: 300;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/varta/varta-v17-latin-300.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/varta/varta-v17-latin-300.woff') format('woff');
}
@font-face {
  font-family: 'Varta';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/varta/varta-v17-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/varta/varta-v17-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Varta';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/varta/varta-v17-latin-500.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/varta/varta-v17-latin-500.woff') format('woff');
}
@font-face {
  font-family: 'Varta';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/varta/varta-v17-latin-600.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/varta/varta-v17-latin-600.woff') format('woff');
}
@font-face {
  font-family: 'Varta';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/varta/varta-v17-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/varta/varta-v17-latin-700.woff') format('woff');
}

/* Mulish */
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/mulish/mulish-v12-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/mulish/mulish-v12-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/mulish/mulish-v12-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/mulish/mulish-v12-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/mulish/mulish-v12-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/mulish/mulish-v12-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/mulish/mulish-v12-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/mulish/mulish-v12-latin-700italic.woff') format('woff');
}

/* Figtree */
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/figtree/figtree-v2-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/figtree/figtree-v2-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/figtree/figtree-v2-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/figtree/figtree-v2-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/figtree/figtree-v2-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/figtree/figtree-v2-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/figtree/figtree-v2-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/figtree/figtree-v2-latin-700italic.woff') format('woff');
}

/* Jost */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/jost/jost-v14-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/jost/jost-v14-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/jost/jost-v14-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/jost/jost-v14-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/jost/jost-v14-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/jost/jost-v14-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Jost';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/jost/jost-v14-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/jost/jost-v14-latin-700italic.woff') format('woff');
}

/* Libre Franklin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/libre-franklin/libre-franklin-v13-latin-regular.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/libre-franklin/libre-franklin-v13-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/libre-franklin/libre-franklin-v13-latin-700.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/libre-franklin/libre-franklin-v13-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Libre Franklin';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/libre-franklin/libre-franklin-v13-latin-italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/libre-franklin/libre-franklin-v13-latin-italic.woff') format('woff');
}
@font-face {
  font-family: 'Libre Franklin';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('https://fonts.ablefy.io/woff/libre-franklin/libre-franklin-v13-latin-700italic.woff2') format('woff2'),
    url('https://fonts.ablefy.io/woff/libre-franklin/libre-franklin-v13-latin-700italic.woff') format('woff');
}

/* Source Serif 4 */
@font-face {
  font-family: 'SourceSerif4';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('https://fonts.ablefy.io/ttf/SourceSerif4-Regular.ttf');
}
@font-face {
  font-family: 'SourceSerif4';
  font-style: italic;
  font-weight: 400;
  src: local(''), url('https://fonts.ablefy.io/ttf/SourceSerif4-Italic.ttf');
}
// TODO: - remove font
@font-face {
  font-family: 'ArchivoLight';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/Archivo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArchivoLight';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/ArchivoItalic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArchivoReg';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/Archivo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArchivoReg';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/ArchivoItalic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArchivoMed';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/Archivo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArchivoMed';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/ArchivoItalic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArchivoBlack';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/Archivo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'ArchivoBlack';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/ArchivoItalic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/LatoItalic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/Lato.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/MontserratItalic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/Montserrat.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat Reg';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/MontserratItalic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat Reg';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/Montserrat.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/MulishItalic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/Mulish.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/NunitoSansItalic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/NunitoSans.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Serif 4';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/SourceSerif4Italic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/SourceSerif4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/WorkSansItalic.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.ablefy.io/woff/WorkSans.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto Slab Thin';
  font-style: normal;
  font-weight: 100;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/RobotoSlab-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Slab Light';
  font-style: normal;
  font-weight: 300;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/RobotoSlab-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'SourceSans3';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/SourceSans3.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Black';
  font-style: normal;
  font-weight: 900;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Black';
  font-style: italic;
  font-weight: 900;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/Montserrat-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Light';
  font-style: normal;
  font-weight: 300;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Light';
  font-style: italic;
  font-weight: 300;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/Montserrat-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Med';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Med';
  font-style: italic;
  font-weight: 500;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/Montserrat-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Medium';
  font-style: italic;
  font-weight: 500;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Extra Bold';
  font-style: normal;
  font-weight: 800;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat Extra Bold';
  font-style: italic;
  font-weight: 800;
  src:
    local(''),
    url('https://fonts.ablefy.io/ttf/Montserrat-ExtraBoldItalic.ttf') format('truetype');
}
