@import 'src/ui-kit/styles/colors';

$blue-grey: #344d62;
$green: #65c95d;

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-animation {
  -webkit-animation: fadein 1s ease-in;
  -moz-animation: fadein 1s ease-in;
  animation: fadein 1s ease-in;
}

.label-error {
  position: absolute;
  z-index: 2;
  margin: 47px 0px 0 -41px;
  font-style: normal;
  color: $elo-red;
  opacity: 0.5;
}

.fix-lg-field {
  margin-right: 1px;
}

.credit-card-logo {
  width: 40px;
  position: absolute;
  right: 20px;
  top: 5px;
}

.new-credit-card-logo {
  top: 34px;
}

.product-cont {
  .border-bottom {
    border-bottom: 1px solid $elo-grey-light;
  }
  .product-header {
    position: relative;
    z-index: 2;
    background-color: $elo-white;
    border: solid 1px $elo-grey-40;
    border-radius: 4px;

    .pay-info {
      color: $elo-blue;
    }

    .name {
      font-family: Roboto;
      color: $elo-black-semi;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.6px;
    }
    .product-name-show {
      overflow-x: auto;
      @media (max-width: 568px) {
        font-size: 28px;
      }
    }
    .product-price,
    .buy-button,
    .crossed-price {
      display: inline-block;
      vertical-align: middle;
    }
    .current-price,
    .starts-from {
      display: inline-block;
    }
    .product-price {
      .crossed-price,
      .current-price {
        line-height: 34px;
      }
      .current-price {
        font-family: Roboto;
        color: $elo-black;
        font-size: 36px;
        font-weight: 700;
        .starts-from {
          font-size: 14px;
          line-height: 14px;
        }
        div.crossed-price {
          font-size: 14px;
        }
        .type {
          font-size: 20px;
        }
      }
      &.sold-out {
        position: relative;
        min-width: 200px;
        .current-price {
          opacity: 0.2;
        }
      }
      .sold {
        position: absolute;
        font-size: 32px;
        font-weight: bold;
        color: $elo-red-60;
        right: 0;
        top: 0;
        left: 0;
        margin: auto;
        transform: rotate(-15deg);
      }
    }
    .shop-seller {
      a {
        text-decoration: none;
        .avatar,
        .username {
          display: inline-block;
          vertical-align: middle;
        }
        .avatar {
          img {
            border-radius: 50%;
            width: 35px;
            height: 35px;
            box-shadow: 1px 3px 10px 0.3px rgba($elo-black, 0.2);
          }
        }
        .username {
          font-family: Roboto;
          color: $blue-grey;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
  }
  .product-body {
    background: $elo-white;

    .zero-side-padding {
      padding-left: 0;
      padding-right: 0;
    }

    .crossed-text.event-date {
      &:after {
        transform: rotate(-5deg);
      }
    }

    .tabs {
      .nav-tabs {
        li {
          a {
            &:focus,
            &:hover {
              border: none;
              background-color: transparent;
            }
          }
          &.active {
            a {
              border: none;
              border-bottom: 3px $elo-blue solid;
            }
          }
        }
      }
    }

    .goods-cont {
      padding: 15px;
    }
  }
  .user-info {
    .avatar,
    .user-name {
      display: inline-block;
      vertical-align: middle;
    }
    .avatar {
      img {
        height: 55px;
        width: 55px;
        border: 1.5px solid $elo-white;
        border-radius: 50%;
        box-shadow: 1px 3px 10px 0.3px rgba($elo-black, 0.2);
      }
    }
    .user-name {
      a {
        text-decoration: none;
        font-weight: 700;
      }
      i,
      span,
      .is_verified {
        display: inline-block;
        vertical-align: middle;
      }
      .is_verified {
        .fas {
          color: $green;
        }
      }
    }
  }
  .main-content {
    border-right: 1px solid $elo-grey-light;
    .description-cont {
      iframe {
        max-width: 100%;
      }
      .description {
        overflow: auto;
      }
    }
  }
  .sidebar {
    background: linear-gradient(to bottom, rgba($elo-grey-40, 1) 0%, rgba($elo-grey-40, 0) 50px);
    border-left: 1px solid $elo-grey-light;
    @media (min-width: 971px) {
      margin-left: -1px;
    }
    .title {
      font-family: Roboto;
      color: $elo-black;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 10px;
    }
    .tickets {
      padding: 25px 15px 0px;

      .list-group {
        width: 100%;
      }

      .sold-out {
        .event-price,
        .event-date {
          opacity: 0.3;
        }
        .sold {
          position: absolute;
          font-weight: bold;
          color: $elo-red-60;
          right: 15px;
          top: 4px;
        }
      }
    }
    .payment-methods {
      padding: 25px 15px;
      .payment-imgs {
        display: table !important;
        img {
          display: iniline-block;
          background: $elo-white;
          height: 30px;
          margin: 5px 20px 5px 0;
          &.paypal,
          &.bank_wire {
            height: 35px;
          }
          &.mastercard {
            height: 25px;
          }
        }
      }
    }
    .seller-section {
      background-color: $elo-grey-light-20;
      padding: 25px 15px;
    }
    .share-area {
      padding: 25px 0;
      text-align: center;
      .share-btn {
        width: 80%;
        font-family: Roboto;
        color: $elo-black-semi;
        outline: none;
        font-weight: 900;

        &:focus {
          outline: none;
        }

        .fas {
          font-size: 20px;
          margin: 1px -3px -1px;
        }
      }
      .collapseShareLinks {
        text-align: center;
        font-size: 40px;
      }
      .collapse-with-arrow {
        position: relative;
        border-top: 1px solid rgba($elo-grey, 0.3);
        background-color: $elo-grey-light-10;
        .header {
          padding: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;

          &,
          .close {
            margin-top: 5px;
            border: none;
            color: $elo-grey;
            font-size: 12px;
            font-weight: 400;

            &:focus {
              outline: none;
            }
          }
        }
      }
      .collapse-with-arrow:after,
      .collapse-with-arrow:before {
        bottom: 100%;
        left: calc(50% + 6px);
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      .collapse-with-arrow:after {
        border-color: transparent;
        border-bottom-color: $elo-grey-light-10;
        border-width: 10px;
        margin-left: -10px;
      }
      .collapse-with-arrow:before {
        border-color: transparent;
        border-bottom-color: rgba($elo-grey, 0.3);
        border-width: 11px;
        margin-left: -11px;
      }
    }
  }
}
.seller-products {
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 30px;

  .head {
    position: relative;
    .show-all {
      @media (min-width: 769px) {
        position: absolute;
        top: 5px;
        right: 5px;
      }
      text-align: right;
    }
  }
  .products {
    #products-table {
      .slider {
        margin-bottom: 0;
        .items {
          button {
            &.slick-prev {
              left: -13px;
            }
            &.slick-next {
              right: -13px;
            }
            &:before {
              color: $elo-black;
            }
          }
          .product {
            margin: 10px !important;
            .covers {
              .item {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                img {
                  display: none;
                }
                &.item.fix-img-show {
                  img {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.covers {
  .carousel {
    .carousel-control-prev,
    .carousel-control-next {
      top: 45%;
      width: 40px;
      height: 55px;
      opacity: 0.8;
      font-weight: bold;
      .fas {
        font-size: 50px;
        line-height: 50px;
      }
    }

    .carousel-control-prev {
      left: 14px;
      background-image: linear-gradient(to left, rgba($elo-black, 0.0001) 0%, rgba($elo-black, 0.5) 100%);
      background-repeat: repeat-x;
    }
    .carousel-control-next {
      right: 14px;
      background-image: linear-gradient(to right, rgba($elo-black, 0.0001) 0%, rgba($elo-black, 0.5) 100%);
      background-repeat: repeat-x;
    }

    .carousel-item.active {
      text-align: center;
    }
  }
  .no-cover {
    background-color: $elo-grey-light;
    text-align: center;
    &.with-border {
      border: 1px solid $elo-grey-40;
      border-radius: 4px;
    }
    i {
      color: $elo-grey-40;
      font-size: 50px;
      padding: 100px;
    }
  }
}
