@import 'src/ui-kit/styles/colors';

.product-waiting {
  background-color: $elo-white;
  text-align: center;
  .info-section {
    padding: 30px;
    border-top: 1px solid $elo-grey-light-20;
    border-bottom: 1px solid $elo-grey-light-20;
    .bordered {
      border: 2px solid $elo-grey-40;
      border-radius: 5px;
      padding: 0 10px;
      background-color: $elo-grey-light-10;
    }
  }
  @media (max-width: 576px) {
    .success-icon {
      text-align: left !important;
    }
  }
}

.product-waiting,
.payment-success {
  position: relative;
  .go-to-cabinet-btn {
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 1;
    @media (max-width: 576px) {
      right: 0;
    }
  }
}
.payment-success {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: $elo-white;
  box-shadow: 0 1px 5px 0 rgba($elo-black, 0.05);
  border: solid 1px $elo-grey-40;

  &--without-header {
    margin-top: 10px;
  }

  .main-col {
    padding-bottom: 15px;
  }
  @media (min-width: 767px) {
    .main-col {
      padding: 0 30px 30px;
    }
  }
  @media (max-width: 767px) {
    .go-to-cabinet-btn {
      position: initial;
      margin: 20px 15px 0;
    }
  }
  .header {
    padding: 20px 0;
    img {
      width: 84px;
    }
    span {
      font-size: 36px;
      font-weight: bold;
      letter-spacing: -0.9px;
      color: $elo-black-semi;
      vertical-align: middle;
    }
  }
  .plain-info {
    b {
      padding-right: 5px;
    }
  }
  .order-info {
    font-size: 16px;
    letter-spacing: -0.4px;
    color: $elo-blue-60;
    padding-top: 20px;
  }
  .products-list {
    padding: 20px;
    margin-top: 10px;
    background-color: $elo-grey-light-10;
    border: solid 1px $elo-grey-40;
    .bundle-heading-section {
      padding-bottom: 20px;
      display: flex;
      align-items: center;
      .name {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.4px;
        color: $elo-black-semi;
        word-break: break-all;
      }
      .product-cover {
        width: 80px;
      }
    }
    .list-section {
      &.with-bundle {
        border: solid 1px $elo-grey-40;
        margin-bottom: 30px;
        .item-section {
          margin-bottom: 0;
          padding: 15px;
          margin-top: 0;
          &:last-child {
            border-bottom: none !important;
          }
        }
      }
      .item-section {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $elo-grey-40;
        .item-head {
          .mob-layout {
            @media (max-width: 576px) {
              margin-bottom: 10px;
            }
          }
          .info {
            padding: 5px 10px;
            border-radius: 8px;
          }
          .product-item {
            .media-body,
            .media-right {
              vertical-align: middle;
              .name {
                font-size: 16px;
                font-weight: 600;
                letter-spacing: -0.4px;
                color: $elo-black-semi;
                word-break: break-all;
              }
              @media (min-width: 767px) {
                .info {
                  width: 300px;
                }
              }
            }
            .product-cover {
              width: 60px;
            }
          }
          .btn-align {
            text-align: right;
            @media (max-width: 767px) {
              text-align: center;
            }
          }

          .license-key {
            word-break: break-word;
          }
        }
        .zip-hint {
          text-align: right;
          max-width: 400px;
          float: right;
          display: none;

          @media (max-width: 576px) {
            display: block;
          }
        }
        .goods-list {
          background-color: $elo-white;
          border: solid 1px $elo-grey-40;
          margin-top: 15px;
          padding: 0 15px;
          .good-item {
            padding: 15px 0;
            color: $elo-black-semi;
            &:not(:last-child) {
              border-bottom: solid 1px $elo-grey-40;
            }
            .icon {
              i {
                font-size: 36px;
              }
              img {
                width: 30px;
              }
            }
            .name {
              font-size: 16px;
              padding-left: 18px;
            }
            .size {
              font-weight: bold;
              border-right: solid 1px $elo-grey-40;
              padding-right: 15px;
              margin-right: 15px;
            }
            .name,
            .icon {
              display: inline-block;
              vertical-align: middle;
            }
            .link,
            .size {
              float: right;
              line-height: 2.6;
              i {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    .price-section {
      .price-info {
        .total {
          float: right;
          font-size: 16px;
          color: $elo-black-semi;
          span {
            font-size: 36px;
            font-weight: 600;
          }
          .gift {
            color: $elo-grey-dark;
            font-family: var(--elo-font-sans-serif), serif;
            i {
              margin-right: 5px;
              color: $elo-grey;
            }
          }
        }
      }
    }
  }
  .provider,
  .partner {
    margin-top: 30px;
    border: solid 1px $elo-grey-40;
    padding: 15px;
    span {
      font-weight: 500;
      color: $elo-black-semi;
    }

    @media (max-width: 767px) {
      margin-top: 15px;
    }
  }
  .provider {
    background-color: rgba($elo-orange, 0.05);
    color: $elo-orange;
  }
  .partner {
    background-color: rgba($elo-green, 0.15);
    color: $elo-green;
    img {
      height: 40px;
      float: left;
      margin-right: 10px;
    }
  }
  .socials {
    padding: 20px;
    border-top: solid 1px $elo-grey-40;
    margin-top: 10px;
    text-align: center;
    span {
      display: inline-block;
      margin-left: 5px;
      font-size: 14px;
      i {
        font-size: 16px;
      }
    }
  }
  .additional-info {
    border-top: solid 1px $elo-grey-40;
    margin: 0 15px;
    padding: 15px 0;
  }
  &.success {
    .success-body {
      .success-message .success-icon img {
        width: 155px;
      }
      .digital-links {
        .file-name {
          word-break: break-all;
        }
        padding: 0 10px;
        @media (min-width: 769px) {
          padding: 30px;
        }
        border-top: 1px solid $elo-grey-light-20;
        border-bottom: 1px solid $elo-grey-light-20;
        .bordered {
          border: 2px solid $elo-grey-40;
          border-radius: 5px;
          padding: 0 10px;
          background-color: $elo-grey-light-10;
        }
        table {
          width: 100%;
          &.bank-details {
            tr {
              td {
                vertical-align: baseline;
              }
            }
          }
          tr {
            td {
              vertical-align: middle;
              padding: 10px 5px;
              .fas {
                font-size: 30px;
              }
            }
          }
        }
      }
    }
  }
}
