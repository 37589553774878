@import './colors';
@import './fonts';
@import '../../elo-ui/styles/colors/colors';
.text-field {
  .password-icon {
    display: inline;
    margin-left: -30px;
    cursor: pointer;
    align-self: center;
    color: $elo-grey-40;
    font-size: var(--elo-font-size-lg);

    &:hover {
      color: $elo-grey-dark;
    }
  }

  .prefix-icon {
    font-size: var(--elo-font-size-lg);
    padding-bottom: 5px;
  }

  &__control {
    box-sizing: border-box;
    padding: 13px 18px 13px;
    line-height: 16px;
    border: 1px solid var(--field-border-color);
    border-radius: 4px;
    background-color: var(--field-bg-color);

    &:focus:not(&--error) {
      border: 1px solid var(--field-border-color-focus);
    }

    &--lowercased {
      text-transform: lowercase;
    }

    &--error,
    &:invalid {
      border: 1px solid var(--field-border-color-error);
    }

    &--with-prefix {
      border-radius: 0 4px 4px 0;
      padding: 12px 38px;

      &-normal-field {
        border-radius: 4px;
      }
    }

    &-wrapper {
      position: relative;
      width: 100%;
      display: flex;

      div {
        input {
          color: $elo-black-semi;
        }
      }

      .password-icon {
        display: inline;
        margin-left: -30px;
        cursor: pointer;
        align-self: center;
        color: $elo-grey-40;

        &:hover {
          color: $elo-grey-dark;
        }
      }
      .domain-text {
        position: absolute;
        top: 12px;
        right: 20px;
        color: $elo-grey;
        font-size: 14px;
        font-family: var(--elo-font-sans-serif);
        font-weight: 500;
      }
    }

    // Override Bootstrap styles
    &::placeholder {
      color: var(--color-primary-dg-300) !important;
      font-size: 14px !important;
      padding-top: 0 !important;
    }

    &--dark-value::placeholder {
      color: $elo-black-semi !important;
    }

    // specific selector only for Chrome 29+
    @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
      //font-size: revert;
      font-size: 14px;
    }
  }

  &__prefix {
    border: 1px solid $elo-grey-40;
    border-radius: 4px 0 0 4px;
    min-width: 40px;
    color: $elo-grey;
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $elo-grey-light-10;

    &--inside-field {
      position: absolute;
      height: 100%;
      background-color: transparent;
      border: unset;
    }
  }

  &--disabled {
    .field__control {
      background-color: var(--field-bg-color-disabled);
    }
    pointer-events: none;
  }

  &--opacity-1 {
    opacity: 1;
  }

  &--bold {
    .field__control {
      font-family: var(--elo-font-sans-serif);
      font-weight: 500;
    }
  }

  &--padding-right-left-10 {
    .field__control {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  &--required {
    .field__label,
    .checkbox-field__label {
      span:first-child::after {
        content: '*';
        margin: -1px 2px;
        font-size: 15px;
        color: var(--field-required-font-color);
      }
    }
  }

  &__additional-text {
    font-family: var(--elo-font-sans-serif);
    font-weight: 400;
    font-size: 13px;
    color: $elo-grey;
  }

  &__checkbox.checkbox-field.field {
    .field__label,
    .checkbox-field__label {
      span:first-child::after {
        display: none;
      }
    }

    .field__label {
      margin-bottom: 0;
      margin-top: 5px;
    }

    .checkbox-field__control {
      padding-left: 25px;
    }

    width: auto;
    margin-top: -3px;
  }
}

// IPad Air=820, IPad Mini=768
@media (max-width: 820px) {
  .text-field {
    &__control {
      &:focus:not(&--error) {
        border: none;
        border-bottom: 1px solid var(--field-border-color-focus);
      }

      &--error,
      &:invalid {
        border-bottom: 1px solid var(--field-border-color-error);
      }
    }
  }
}
