@import 'src/ui-kit/styles/colors';

.collapsible {
  padding: 30px;
  margin-bottom: 1px;

  &--active {
    margin-bottom: 30px;
  }

  &--no-margin {
    margin: 0;
  }

  &--margin-bottom-15 {
    margin-bottom: 15px;
  }

  &--disabled {
    .collapsible__header {
      .collapsible__title,
      .collapsible__arrow {
        color: $elo-grey;
      }
    }
    margin-bottom: 30px;
  }

  &__status-icon {
    font-size: var(--elo-font-size-lg);
    position: absolute;

    &.fa-check-circle {
      color: $elo-green;
      right: 60px;
      margin-top: -2px;
      font-size: 20px;
    }

    &.fa-exclamation-circle {
      color: $elo-red;
      right: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    line-height: 1;

    .collapsible__arrow {
      color: var(--collapse-button-color);
      font-size: 20px;
      margin-right: 8px;
      cursor: pointer;

      &:hover {
        color: var(--collapse-button-color-hover);
      }

      &.active {
        color: var(--collapse-button-color-active);
      }
    }

    .collapsible__title {
      font-family: var(--elo-font-sans-serif);
      font-size: 18px;
      color: $elo-black-semi;
      line-height: 1;

      &--small-size {
        font-size: 13px;
      }
    }

    .collapsible__sub-title {
      margin-left: 5px;
      font-family: var(--elo-font-sans-serif);
      font-weight: 400;
      font-size: 13px;
      color: $elo-grey-dark;

      &--full-width {
        width: 100%;
      }
    }

    .popover-info {
      vertical-align: middle;
    }
  }

  &__header-custom-item {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__text-content {
    font-size: var(--elo-font-size-sm);
    color: $elo-black-semi;
    margin-bottom: 30px;
  }

  &__body {
    margin-top: 30px;

    &--without-top-margin {
      margin-top: 0;
    }

    &--margin-bottom-15 {
      margin-bottom: 15px;
    }
  }

  &--simple {
    padding: 0;

    .collapsible--disabled,
    .collapsible--active {
      margin-bottom: 15px;
    }

    .collapsible__body {
      margin-top: 15px;
    }

    .collapsible__header {
      .collapsible__title {
        font-family: var(--elo-font-sans-serif);
        font-size: 13px;
        color: $elo-grey-dark;
        line-height: 1;
      }

      .collapsible__arrow {
        font-size: 18px;
      }
    }
  }
}
