@import 'src/ui-kit/styles/colors';
@import '../../rootPath.js';

// shared variables - can be usefull for reusable components

$light-blue: #0095f5;
$white: #fff;
$violet: #2a2f5f;
$blue-gradient: linear-gradient(236deg, #0a94ee, #236bc6 53%, #3946a3);
$deep-blue: #3946a3;
$black: #000;
$black-box-shadow: 0 10px 15px 0 rgba($black, 0.3);

$blue: #0a94ee;
$blue-hover: #26a6fa;
$blue-active: #027fd0;

// Social
$dark-red: #b11500;
$globe: #448aff;
$facebook: #2a5dbd;
$google-plus: #e81c00;
$twitter: #41cfee;
$linkedin: #007bb6;
$reddit: #ff4006;
$youtube: $dark-red;
$pinterest: $dark-red;
$instagram: $dark-red;
$tumblr: $dark-red;

// 50 SHADOWS OF GRAY :)
$gray: #6f6f6f;
$light-gray: #d9d9d9;
$super-light-gray: #f3f3f3;
$deep-gray: #575757;

// navigation variables
$nav-bg: $white;
$nav-text-color: $light-blue;

// page footer variables
$footer-bg: $violet;
$footer-text-color: $white;

// page sections variables
$page-section-bg-blue: $blue-gradient;
$page-section-title-white: $white;

$page-section-bg-white: $white;
$page-section-title-gray: $deep-gray;

$page-section-bg-gray: $super-light-gray;
$page-section-title-gray: $deep-gray;

// main page vars
$list-item-name: $light-blue;
$price-badge-bg: $deep-blue;

// root page styles wrapper
.namotto-labs {
  // shared fonts styles
  font-family:
    Nunito Sans,
    sans-serif;
  font-size: 14px;

  // fake cover for extend
  .fake-cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: $super-light-gray;
    background-image: url($rootPath + 'src/images/no_image.svg');
  }

  // navigation styles
  .shop-nav {
    background: $nav-bg;
    box-shadow: 0 1px 10px 0 rgba($black, 0.07);
    margin-bottom: 0;

    line-height: 1.14;
    color: $nav-text-color;

    .styled-logo {
      max-width: 200px;

      @media (max-width: 768px) {
        max-width: 120px;
      }
    }

    .styled-toggler {
      color: $nav-text-color;
      border: none;
    }

    .styled-nav {
      .nav-item {
        align-self: center;
        padding: 20px 30px;

        .nav-NavLink {
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
          &.activeLink {
            color: $gray;
          }
        }

        @media screen and (min-width: 992px) {
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }

  // footer styles
  .footer {
    padding: 30px 0 10px;

    background: $footer-bg;
    box-shadow: 0 -10px 15px 0 rgba($black, 0.1);

    color: $footer-text-color;

    .footer-content {
      margin-bottom: 30px;

      .styled-col {
        .title {
          margin-bottom: 20px;

          text-transform: uppercase;
          font-weight: bold;
          font-size: 16px;
          letter-spacing: -0.4px;
          opacity: 0.6;
        }

        .item {
          margin-bottom: 10px;
          font-size: 14px;

          // styles for links
          a {
            color: inherit;
          }

          // styles for paymethods
          .paymethod {
            margin-right: 20px;
            margin-bottom: 20px;
          }

          // styles for contacts
          .contact-icon {
            opacity: 0.6;
          }
          .contact {
            padding-left: 10px;
          }

          // styles for socials
          .social-icon {
            margin: 10px 25px 0 0;
            font-size: 24px;
            text-decoration: none;
            color: inherit;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .footer-bottom {
      font-family: var(--elo-font-sans-serif);
      font-size: 13px;
      justify-content: center;
      opacity: 0.6;
    }
  }

  // ===============================================================
  // shared styles per each page section
  // ===============================================================
  .page-section {
    padding: 100px 0;

    .section-header {
      margin-bottom: 50px;

      text-align: center;
      .section-title {
        margin-bottom: 10px;
        font-size: 40px;
        font-weight: bold;
        line-height: 0.9;

        @media (max-width: 768px) {
          font-size: 28px;
        }
      }

      .section-desc {
        font-size: 18px;
        line-height: 1.5;
        opacity: 0.9;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }

    &.blue-bg-section {
      background: $page-section-bg-blue;
      color: $page-section-title-white;
    }

    &.white-bg-section {
      background: $page-section-bg-white;
      color: $page-section-title-gray;
    }

    &.gray-bg-section {
      background: $page-section-bg-gray;
      color: $page-section-title-gray;
    }

    &.blue-photo-bg-section {
      color: $page-section-title-white;
      padding: 0;
      position: relative;
      background: $page-section-bg-blue;

      .bg-mask {
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        filter: opacity(10%);
        top: 0;
        left: 0;
      }
    }

    &.photo-bg-section {
      color: $page-section-title-white;
      padding: 0;
    }
  }

  // ===============================================================
  // main page styles
  // ===============================================================

  // header section
  .page-section {
    &.blue-bg-section.main-section {
      padding: 100px 0 50px;

      .section-header .section-title {
        text-transform: uppercase;
      }
    }

    .slider-wrap-sm {
      display: none;

      @media (max-width: 992px) {
        display: block;
      }

      .slick-list {
        .slide-wrap {
          padding: 10px 30px 50px;

          .slide-item {
            @extend .fake-cover;
            min-height: 300px;

            position: relative;
            box-shadow: $black-box-shadow;

            .slide-label {
              position: absolute;
              width: 80%;
              min-height: 150px;
              right: -10px;
              bottom: -10%;
              word-break: break-word;

              padding: 30px 25px;

              background: $light-blue;
              box-shadow: $black-box-shadow;

              font-size: 20px;
              font-weight: bold;
            }
          }
        }
      }

      .slider-arrow {
        color: $light-blue;
        position: absolute;
        font-size: 30px;
        cursor: pointer;
        top: 50%;
        margin-top: -10px;
        padding: 0;
        border: none;
        outline: none;

        &.arr-prev {
          left: 5px;
          z-index: 100;
        }
        &.arr-next {
          right: 5px;
        }
      }
    }

    .slider-wrap-md {
      display: none;
      padding: 30px 0;

      @media (min-width: 992px) {
        display: block;
      }

      .slide-item {
        padding: 0 15px;

        .slide {
          @extend .fake-cover;
          min-height: 300px;
          position: relative;
          box-shadow: $black-box-shadow;
          margin-bottom: 50px;

          @media (min-width: 1368px) {
            min-height: 500px;
          }

          .slide-label {
            position: absolute;
            width: 70%;
            min-height: 150px;
            right: -10px;
            bottom: -10%;
            word-break: break-word;

            padding: 30px 25px;

            background: $light-blue;
            box-shadow: $black-box-shadow;

            .name {
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 10px;

              .link {
                color: $white;
                text-decoration: none;
              }
            }

            .desc {
              color: rgba($white, 0.6);
              margin-bottom: 10px;
            }

            .details {
              text-align: right;

              .link {
                color: $white;
                text-decoration: none;
              }
            }
          }
        }
      }

      .slick-dots {
        li {
          button::before {
            color: $white;
            font-size: 12px;
          }

          &.active {
            color: $white;
          }
        }
      }

      .slider-arrow {
        color: $white;
        position: absolute;
        font-size: 36px;
        font-weight: bold;
        cursor: pointer;
        top: 40%;
        margin-top: -10px;
        padding: 0;
        border: none;
        outline: none;

        &.arr-prev {
          left: 30%;
          z-index: 100;
        }
        &.arr-next {
          right: 30%;
        }
      }
    }
  }

  // products list
  .page-section {
    &.products-section {
      &.items-list {
        padding: 0;
      }

      &.products-list-head {
        padding-bottom: 0;
      }

      &.products-list-head {
        padding-bottom: 0;
      }

      .section-content {
        .products-list {
          .two-col-list {
            display: block;
            text-align: center;
            padding: 10px;

            .list-item {
              text-align: left;
              margin-bottom: 30px;
              box-shadow: 0 6px 20px 0 rgba($black, 0.15);

              text-decoration: none;
              color: inherit;

              @media (min-width: 1201px) {
                display: inline-block;
                width: 520px;
                margin-right: 60px;
                &:nth-child(2n) {
                  margin-right: 0;
                }
              }

              @media (max-width: 1200px) {
                display: block;
              }

              .row {
                padding: 0 !important;

                .item-cover {
                  @extend .fake-cover;
                  min-height: 210px;

                  padding: 0 !important;
                  position: relative;

                  .price {
                    position: absolute;
                    top: 20%;
                    left: -10px;
                    padding: 5px 20px;
                    background: $price-badge-bg;
                    color: $white;
                  }
                }

                .item-info {
                  padding: 30px 20px;

                  .type {
                    font-weight: bold;
                  }
                  .name {
                    font-size: 18px;
                    font-weight: bold;
                    color: $list-item-name;
                  }
                }
              }
            }
          }
        }
      }

      &.section-footer {
        padding: 0 10px;
        margin-bottom: 50px;

        .items-box {
          padding: 0 10px;
          justify-content: flex-end;

          @media (max-width: 768px) {
            justify-content: center;
          }

          .all-items-link {
            display: inline-flex;
            align-items: center;

            text-decoration: none;
            cursor: pointer;
            color: $blue;
            font-size: 18px;

            &:hover {
              color: $blue;
            }
            .btn-icon {
              background: $blue;
              padding: 4px 18px;
              border-radius: 50%;
              color: $white;
              font-size: 24px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  // testimonials
  .page-section {
    &.testimonials {
      .section-wrap {
        padding: 150px 0;

        .section-header {
          margin-bottom: 20px;
        }

        .section-content {
          .slider-wrap {
            padding: 20px 0;
            max-width: 750px;
            margin: 0 auto;

            .slick-list {
              .slide-item {
                text-align: center;
                padding: 30px;

                .testimonial-logo {
                  @extend .fake-cover;
                  width: 100px;
                  height: 100px;
                  border-radius: 50%;
                  margin-bottom: 30px;
                  margin: 0 auto 20px;

                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: center;
                }

                .name {
                  font-size: 24px;
                  font-weight: bold;
                }

                .user-info {
                  opacity: 0.8;
                  margin-bottom: 20px;
                }

                .feedback {
                  font-weight: bold;
                }
              }
            }

            .slick-dots {
              li {
                button::before {
                  color: $white;
                  font-size: 12px;
                }

                &.active {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  // features
  .page-section {
    .section-content {
      &.features-list {
        .list-item {
          margin-bottom: 100px;

          &:last-child {
            margin-bottom: 0;
          }
          .align-vertical {
            align-items: center;
          }
          .desc {
            padding: 50px 0;

            .title {
              font-size: 30px;
              font-weight: bold;
            }
            .text {
              font-size: 16px;
            }
          }

          .feature-pic {
            @extend .fake-cover;
            max-height: 380px;
            min-height: 250px;

            @media (min-width: 768px) and (max-width: 992px) {
              height: 250px;
            }
            box-shadow: $black-box-shadow;
          }
        }
      }
    }
  }

  // subscribe-section
  .page-section {
    &.subscribe-section {
      background-repeat: no-repeat;
      background-size: cover;

      .section-wrap {
        padding: 200px 0;

        .section-header {
          max-width: 700px;
          margin: 0 auto;
        }

        .section-content {
          max-width: 700px;
          margin: 0 auto;

          &.subscribe-form {
            .form-contents {
              text-align: center;
              padding: 0 10px;

              #customized-form._form_97 {
                ._form._form_97._inline-form._dark {
                  ._form-content {
                    text-align: center;
                    padding: 0 10px;
                    ._form_element {
                      ._field-wrapper {
                        input {
                          display: inline-block !important;
                          margin-bottom: 15px !important;
                          width: 100% !important;

                          @media (max-width: 768px) {
                            display: block !important;
                            width: 100% !important;
                          }

                          padding: 15px 25px !important;
                          border-radius: 4px !important;
                          outline: none !important;
                          border: none !important;
                          box-shadow: $black-box-shadow !important;
                          color: $black !important;
                        }
                      }
                    }

                    ._button-wrapper {
                      button {
                        display: block !important;
                        width: 100% !important;
                        margin-left: 0 !important;

                        cursor: pointer !important;
                        padding: 18px 30px !important;
                        border-radius: 4px !important;
                        box-shadow: $black-box-shadow !important;
                        background: $light-blue !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // ===============================================================
  // products page styles
  // ===============================================================

  // categories section
  .page-section.products-forms {
    padding: 50px 0;

    .section-header {
      margin-bottom: 30px;

      .section-title {
        text-align: left;
      }
    }

    .section-content .tabs-list {
      font-size: 14px;
      font-weight: bold;
      color: $light-blue;

      @media (min-width: 769px) {
        border-bottom: 1px solid $light-gray;

        .list-item {
          display: inline-block;
          padding: 10px 30px;
          cursor: pointer;

          &.active {
            border: 1px solid $light-gray;
            border-radius: 4px 4px 0 0;
            color: $gray;
            position: relative;

            &:after {
              position: absolute;
              content: '';
              height: 5px;
              width: 100%;
              bottom: -3px;
              left: 0;
              background: $white;
            }
          }
        }
      }

      @media (max-width: 768px) {
        .list-item {
          padding: 10px;
          cursor: pointer;

          &.active {
            color: $gray;
          }
        }
      }
    }
  }

  .page-section.products.products-by-categories-list {
    padding: 0;
    margin-bottom: 50px;
  }

  // ===============================================================
  // products page styles
  // ===============================================================

  // product header
  .page-section.product {
    padding: 50px 0;

    .buy-button-2 {
      border-radius: 4px;
      background-color: $blue;
      color: $white;
      font-size: 18px;
      &:hover {
        background-color: $blue-hover;
      }
      &:active {
        background-color: $blue-active;
      }
    }
    .section-content {
      .info-block {
        .product-cover {
          @extend .fake-cover;
          margin-bottom: 20px;

          box-shadow: 0 10px 20px 0 rgba($black, 0.1);
        }

        .product-covers-wrap {
          .slick-list {
            .slide-wrap {
              padding: 0 20px;

              .slide-item {
                @extend .fake-cover;
                min-height: 450px;
                box-shadow: $black-box-shadow;
              }
            }

            @media (min-width: 768px) {
              min-height: 450px;
            }

            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            box-shadow: 0 10px 20px 0 rgba($black, 0.1);
          }
        }

        .description {
          margin-top: 30px;
          .name {
            margin-bottom: 20px;
            font-size: 30px;
            font-weight: bold;
            color: $deep-gray;
          }
        }
      }

      .product-pricing {
        padding: 50px 10px 10px;
        text-align: center;

        .price {
          font-size: 55px;
          font-weight: bold;

          .currency {
            font-size: 30px;
            vertical-align: super;
            font-weight: normal;
          }
        }
        .price-add {
          margin-bottom: 30px;
        }
        .buy-btn {
          display: inline-block;
          margin-bottom: 20px;
          padding: 10px 30px;
          border-radius: 4px;
          background: $light-blue;
          box-shadow: 0 10px 15px 0 rgba($black, 0.1);
          font-size: 18px;
          text-decoration: none;
          color: $white;
          cursor: pointer;
        }
        .paymethods {
          padding: 10px;
          margin-bottom: 10px;

          .paymethod {
            &.paypal {
              width: 70px;
              height: 40px;
            }
            &:not(.paypal) {
              width: 40px;
              height: 30px;
            }
            margin: 5px;
          }
        }
        .share-section {
          .share-btn-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-bottom: 10px;

            .share-btn {
              color: $light-gray;
              font-size: 30px;
            }
          }
          .sharings {
            text-align: center;
            font-size: 36px;

            .share-card {
              border: 1px solid $light-gray;
              border-radius: 4px;
              background: $white;
              padding: 10px;

              .share-item {
                margin: 5px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  // sharings colors
  .fa-colored {
    .fa.fa-globe {
      color: $globe;
    }
    .fa-facebook-square {
      color: $facebook;
    }
    .fa-google-plus-square {
      color: $google-plus;
    }
    .fa-twitter-square {
      color: $twitter;
    }
    .fa-linkedin {
      color: $linkedin;
    }
    .fa-tumblr-square {
      color: $tumblr;
    }
    .fa-reddit-square {
      color: $reddit;
    }
    .fa-pinterest-square {
      color: $pinterest;
    }
    .fa-instagram {
      color: $instagram;
    }
    .fa-youtube-square {
      color: $youtube;
    }
  }

  // short description
  .page-section {
    &.product-description-section {
      padding: 30px 0;

      .section-header {
        margin-bottom: 0;
        text-align: left;

        .section-title {
          font-size: 30px;
        }
        .section-desc {
          font-size: 14px;
          padding-bottom: 30px;
          border-bottom: 1px solid $light-gray;
        }
      }
    }
  }

  // lesons-tree
  .page-section {
    &.course-contents {
      padding: 30px 0 100px;

      .section-header {
        text-align: left;
        margin-bottom: 20px;

        .section-title {
          font-size: 30px;
        }
        .section-desc {
          font-size: 14px;
        }
      }
    }
  }

  // ===============================================================
  // about page styles
  // ===============================================================

  // header
  .page-section.about-us {
    .section-header {
      max-width: 680px;
      padding: 20px;
      margin: 0 auto;

      .section-title {
        margin-bottom: 30px;
      }
    }
  }

  // advantages
  .page-section {
    .section-content {
      &.advantages-list {
        .list-item {
          margin-bottom: 100px;

          &:last-child {
            margin-bottom: 0;
          }
          .align-vertical {
            align-items: center;
          }

          .desc {
            padding: 50px 0;

            .title {
              font-size: 30px;
              font-weight: bold;
            }
            .text {
              font-size: 16px;
            }
          }

          .advantage-pic {
            @extend .fake-cover;
            max-height: 380px;
            min-height: 250px;

            @media (min-width: 768px) and (max-width: 992px) {
              height: 250px;
            }
          }
        }
      }
    }
  }

  // offers
  .page-section {
    .section-content {
      .offers-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .list-item {
          width: 40%;
          margin-bottom: 100px;

          @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 30px;
          }

          .title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  // ===============================================================
  // about page styles
  // ===============================================================

  // contacts list
  .page-section {
    .section-content {
      .contacts-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .list-item {
          @media (max-width: 992px) {
            width: 40%;
            &:nth-child(2n) {
              border: none;
            }
          }

          @media (max-width: 768px) {
            width: 100%;
            border: none;
          }

          width: 20%;
          margin-bottom: 20px;
          margin-right: 5%;
          padding: 0 20px;
          border-right: 1px solid $light-gray;

          &:last-child {
            border: none;
            margin-right: 0;
          }
        }
      }
    }
  }

  // contact form
  .page-section {
    &.contact-us-section {
      .section-header {
        @media (min-width: 768px) {
          max-width: 60%;
          margin: 0 auto 50px;
        }
      }
    }

    .section-content {
      .contact-us-form {
        @media (max-width: 768px) {
          padding: 10px;
        }
        .input-row-item,
        .message-content {
          background: $light-gray;
          border: none;
          padding: 15px 25px;

          &:focus {
            outline: 1px solid $gray;
          }
        }

        .input-row-item {
          margin-bottom: 10px;
          width: 32%;
          margin-right: 2%;
          @media (max-width: 768px) {
            width: 100%;
            margin-right: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }

        .message-content {
          resize: none;
          width: 100%;
        }

        .send-btn-wrap {
          justify-content: flex-end;
          padding: 10px 0;

          @media (max-width: 768px) {
            justify-content: center;
          }

          .send-btn {
            border: 1px solid $gray;
            border-radius: 4px;
            padding: 15px 40px;
            margin: 20px 0;
            color: $gray;
            cursor: pointer;
          }
        }
      }
    }
  }
}

// pagination styles
.simple-pagin-wrap {
  padding: 20px;
  margin: 0 auto 20px;

  font-size: 16px;
  list-style: none;
  text-align: center;

  .pagin-item {
    padding: 10px;
    display: inline-block;
    cursor: pointer;

    &.disabled {
      .link-item {
        color: $gray;
      }
    }

    .link-item {
      text-decoration: none;
      color: $light-blue;

      &.active {
        color: $gray;
      }
      &.prev {
        padding-left: 10px;
      }
      &.next {
        padding-right: 10px;
      }
    }
  }
}
