@import 'src/ui-kit/styles/colors';

$dark-orange: #785d24;

.crossed-price,
.crossed-text {
  display: inline-block;
  position: relative;
  color: $dark-orange;
  &::after {
    border-bottom: 2px solid $elo-red-60;
    content: '';
    left: 0;
    line-height: 1em;
    margin-top: calc(0.125em / 2 * -1);
    position: absolute;
    right: 0;
    top: 50%;
  }
}

.crossed-text {
  display: block;
}

// temporary moved here
.top-notifics {
  background-color: var(--notification-warning-bg-color);
  font-size: var(--elo-font-size-sm);
  padding: 0 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  width: 100%;

  &.select-plan {
    background-color: var(--notification-success-bg-color);
    a {
      color: var(--notification-success-font-color);
    }
  }

  &.pay-for-plan {
    background-color: var(--notification-error-bg-color);

    a {
      color: var(--notification-error-font-color);
      line-height: var(--elo-font-size-md);
      display: flex;
      align-items: center;

      .fa-exclamation-circle {
        font-size: 20px;
      }
    }
  }

  &.privacy-policy {
    background-color: var(--notification-warning-bg-color);

    a {
      color: var(--notification-warning-font-color);
      line-height: var(--elo-font-size-md);
      display: flex;
      align-items: center;

      .fa-exclamation-circle {
        font-size: 20px;
      }
    }
  }

  a {
    text-decoration: none;
    color: $elo-white;
    padding: 10px 0;
    display: block;
    i {
      margin-right: 10px;
    }
  }
}
